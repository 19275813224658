import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  useHistory,
} from "react-router-dom"
import { MultiSelect } from 'react-multi-select-component'
import {
  notificationContentTypeOptions,
} from './Users'
import ConfirmationPopup from './ConfirmationPopup'
import ErrorPopup from './ErrorPopup'
import {
  listGroupSettings,
} from './graphql/queries'
import {
  createGroup,
  createGroupSetting,
  deleteGroup,
  deleteGroupSetting,
  updateGroup,
  updateGroupSetting,
} from './graphql/mutations'
import {
  getErrorMessage,
  multiSelectI18n,
} from './util/common'
import {
  isEmail,
  isURL,
} from './util/validator'

function Groups(props) {
  const [sorted, setSorted] = React.useState([])
  const [isChanged, setChanged] = React.useState(false)
  const [isBlocked, setBlocked] = React.useState(false)
  const [groupSettings, setGroupSettings] = React.useState({})
  const [groupSettingsFromLocal, setGroupSettingsFromLocal] = React.useState(false)
  const [isGroupSettingsChanged, setIsGroupSettingsChanged] = React.useState({})
  const [updateMessage, setUpdateMessage] = React.useState('')
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const ref_new_name = React.useRef(null)
  const ref_new_notifiedEmails = React.useRef(null)
  const ref_new_notifiedChatUrl = React.useRef(null)
  const refs_groups = React.useMemo(() => {
    return props.groups.reduce((obj, key) => ({...obj, [key.id]: {
      name: React.createRef(),
      notifiedEmails: React.createRef(),
      notifiedChatUrl: React.createRef(),
      notificationContentType: React.createRef(),
    }}), {})
  }, [props.groups])
  const [newNotificationContentType, setNewNotificationContentType] = React.useState([])
  const ref_to_remove = React.useRef(null)
  const history = useHistory()

  function groupSorter(a, b) {
    if (a.name === b.name) {
      return 0
    }
    return a.name > b.name ? 1 : -1
  }

  // 複雑なことをやっているが、新規と変更に対する後述の仕様を達成するための処理
  const [sorterState, sorterAction] = React.useReducer((state, action) => {
    if (action) {
      return true
    } else if (state) {
      const sortee = [...props.groups]
      sortee.sort(groupSorter)
      setSorted(sortee.map(group => group.id))
    } else if (sorted.length > 0) {
      // 変更では再訪問するまで位置は変わらない
      const newGroupIds = props.groups.map(group => group.id)
      const revised = sorted.filter(id => newGroupIds.includes(id))
      // 新規は再訪問するまで上に積んでいく
      setSorted(newGroupIds.filter(groupId => !revised.includes(groupId)).concat(revised))
    } else {
      setSorted(props.groups.map(group => group.id))
    }
    return false
  }, false)

  React.useEffect(() => {
    sorterAction(true)
  }, [])

  React.useEffect(() => {
    if (props.groups.length > 0) {
      sorterAction(false)
    }
  }, [props.groups, sorterState])

  // 未変更の修正がある場合のページ遷移するかどうかの確認
  // react-router v5にはあるがv6に（まだ）ない機能を使用しているので、当面react-routerをアップデートできない
  // history.blockが発動する度に「A history supports only one prompt at a time」という警告が出るが解決方法は不明
  // また、useHistoryが<Router>内でしか使えないのでこちらは各ページで処理を行っている
  const unblock = React.useMemo(() => history.block(isBlocked ? '更新を反映する前にページを離れようとしています。よろしいですか？' : true), [isBlocked, history])
  React.useEffect(() => () => unblock(), [unblock])

  // こちらは未変更の修正がある場合のページ再読み込みあるいはタブを閉じるかの確認
  React.useEffect(() => {
    window.onbeforeunload = isBlocked ? (e => e.returnValue = true) : null
  }, [isBlocked])

  React.useEffect(() => {
    function getGroupSettings(items, nextToken) {
      API.graphql(graphqlOperation(listGroupSettings, {nextToken: nextToken})).then(res => {
        const data = res.data.listGroupSettings
        items = items.concat(data.items)
        if (data.nextToken) {
          getGroupSettings(items, data.nextToken)
        } else {
          setGroupSettingsFromLocal(true)
          setGroupSettings(items.reduce((obj, key) => ({...obj, [key.groupId]: key}), {}))
        }
      }).catch(err => catchError(err, 'グループ設定情報取得時にエラー'))
    }
    if (!groupSettingsFromLocal) {
      getGroupSettings([])
    }
  }, [refs_groups, groupSettingsFromLocal])

  React.useEffect(() => {
    Object.values(groupSettings).forEach(groupSetting => {
      if (refs_groups[groupSetting.groupId]) {
        refs_groups[groupSetting.groupId].notificationContentType.current = groupSetting.notificationContentType ?
          notificationContentTypeOptions.filter(o => groupSetting.notificationContentType.includes(o.value)) : []
      }
    })
    Object.keys(refs_groups).forEach(groupId => {
      if (refs_groups[groupId].notificationContentType.current == null) {
        refs_groups[groupId].notificationContentType.current = []
      }
    })
    setIsGroupSettingsChanged(groupSettings)
  }, [refs_groups, groupSettings])

  function isEmails(emailsStr, messages) {
    const emails = emailsStr.split(/ *, */).filter(a => a.length > 0)
    for (const email of emails) {
      if (!isEmail(email)) {
        messages.push(`デフォルト通知先メールアドレス ${email} は正しいメールアドレスではありません。`)
      }
    }
  }

  function update() {
    try {
      _update()
    } catch (err) {
      catchError(err, 'グループ更新処理でエラー')
    }
  }

  function _update() {
    setUpdateMessage('')
    const [targetGroups, nextGroups, targetGroupSettings, newGroupSettings, nextGroupSettings] = getUpdatingGroups()
    if (!targetGroups) {
      return
    }
    setChanged(false)
    setBlocked(false)
    targetGroups.forEach(async (group) => {
      await API.graphql(graphqlOperation(updateGroup, {input: {id: group.id, name: group.name}})).catch(err => catchError(err, 'グループ更新時にエラー'))
    })
    targetGroupSettings.forEach(async (groupSetting) => {
      await API.graphql(graphqlOperation(updateGroupSetting, {input: {id: groupSetting.id, notifiedEmails: groupSetting.notifiedEmails, notifiedChatUrl: groupSetting.notifiedChatUrl, notificationContentType: groupSetting.notificationContentType}})).catch(err => catchError(err, 'グループ設定更新時にエラー'))
    })
    newGroupSettings.forEach(async (groupSetting) => {
      await API.graphql(graphqlOperation(createGroupSetting, {input: {groupId: groupSetting.groupId, notifiedEmails: groupSetting.notifiedEmails, notifiedChatUrl: groupSetting.notifiedChatUrl, notificationContentType: groupSetting.notificationContentType}})).catch(err => catchError(err, 'グループ更新におけるグループ設定追加時にエラー'))
    })
    props.setGroups(nextGroups)
    setGroupSettings(nextGroupSettings)
    setUpdateMessage('更新が完了しました')
  }

  function getUpdatingGroups() {
    const targetGroups = []
    const nextGroups = []
    const targetGroupSettings = []
    const newGroupSettings = []
    const nextGroupSettings = []
    const currentGroupNames = props.groups.map(group => group.name)
    const messages = []
    props.groups.forEach(group => {
      const updatingGroup = {...group}
      const updatingGroupSetting = groupSettings[group.id] ? {...groupSettings[group.id]} : {groupId: group.id}
      const newName = refs_groups[group.id].name.current.value
      if (newName !== group.name) {
        if (currentGroupNames.includes(newName)) {
          messages.push(`グループ名 ${newName} は既に使用されています。`)
        } else {
          updatingGroup.name = newName
          targetGroups.push(updatingGroup)
        }
      }
      const newNotifiedEmails = refs_groups[group.id].notifiedEmails.current.value
      if (newNotifiedEmails.length > 0 && (!updatingGroupSetting.hasOwnProperty('id') || updatingGroupSetting.notifiedEmails !== newNotifiedEmails)) {
        isEmails(newNotifiedEmails, messages)
      }
      const newNotifiedChatUrl = refs_groups[group.id].notifiedChatUrl.current.value
      if (newNotifiedChatUrl.length > 0 && (!updatingGroupSetting.hasOwnProperty('id') || updatingGroupSetting.notifiedChatUrl !== newNotifiedChatUrl) && !isURL(newNotifiedChatUrl)) {
        messages.push(`デフォルト通知先Google Chat Webhook URL ${newNotifiedChatUrl} は正しいURLではありません。`)
      }
      const newNotificationContentType = refs_groups[group.id].notificationContentType.current.map(t => t.value)
      if (!updatingGroupSetting.hasOwnProperty('id')) {
        updatingGroupSetting.notifiedEmails = newNotifiedEmails
        updatingGroupSetting.notifiedChatUrl = newNotifiedChatUrl
        updatingGroupSetting.notificationContentType = newNotificationContentType
        newGroupSettings.push(updatingGroupSetting)
      } else if (updatingGroupSetting.notifiedEmails !== newNotifiedEmails || updatingGroupSetting.notifiedChatUrl !== newNotifiedChatUrl || JSON.stringify(updatingGroupSetting.notificationContentType) !== JSON.stringify(newNotificationContentType)) {
        updatingGroupSetting.notifiedEmails = newNotifiedEmails
        updatingGroupSetting.notifiedChatUrl = newNotifiedChatUrl
        updatingGroupSetting.notificationContentType = newNotificationContentType
        targetGroupSettings.push(updatingGroupSetting)
      }
      nextGroups.push(updatingGroup)
      nextGroupSettings[group.id] = updatingGroupSetting
    })
    if (messages.length > 0) {
      setErrorMessage(messages.join('\n'))
      return []
    }
    return [targetGroups, nextGroups, targetGroupSettings, newGroupSettings, nextGroupSettings]
  }

  function remove(id, name) {
    ref_to_remove.current = id
    setConfirmationMessage(`グループ ${name} を削除して良いですか？`)
  }

  function execToRemove() {
    // ユーザーあるいはアンケートが所属しているグループは削除できないので、ここでユーザーやアンケートの削除処理は不要
    const id = ref_to_remove.current
    setConfirmationMessage('')
    props.setGroups(props.groups.filter(group => group.id !== id))
    API.graphql(graphqlOperation(deleteGroup, {input: {id: id}})).catch(err => catchError(err, 'グループ削除時にエラー'))
    if (groupSettings[id]) {
      API.graphql(graphqlOperation(deleteGroupSetting, {input: {id: groupSettings[id].id}})).catch(err => catchError(err, 'グループ設定削除時にエラー'))
      const { [groupSettings[id].groupId]: {}, ...newGroupSettings } = groupSettings
      setGroupSettings(newGroupSettings)
    }
  }

  function add() {
    try {
      _add()
    } catch (err) {
      catchError(err, 'グループ登録処理でエラー')
    }
  }

  function _add() {
    const messages = []
    const newName = ref_new_name.current.value
    if (!newName) {
      return
    } else if (props.groups.find(group => group.name === newName)) {
      messages.push(`グループ名 ${newName} は既に使用されています。`)
    }
    const emails = ref_new_notifiedEmails.current.value
    isEmails(emails, messages)
    const chatUrl = ref_new_notifiedChatUrl.current.value
    if (chatUrl.length > 0 && !isURL(chatUrl)) {
      messages.push(`デフォルト通知先Google Chat Webhook URL ${chatUrl} は正しいURLではありません。`)
    }
    if (messages.length > 0) {
      setErrorMessage(messages.join('\n'))
      return
    }
    if (!isChanged) {
      setBlocked(false)
    }
    API.graphql(graphqlOperation(createGroup, {input: {name: newName}})).then(res => {
      const addedGroup = res.data.createGroup
      props.setGroups([...props.groups, addedGroup])
      API.graphql(graphqlOperation(createGroupSetting, {input: {groupId: addedGroup.id, notifiedEmails: emails, notifiedChatUrl: chatUrl, notificationContentType: newNotificationContentType.map(t => t.value)}})).then(res2 => {
        const addedGroupSetting = res2.data.createGroupSetting
        setGroupSettings({...groupSettings, [addedGroup.id]: addedGroupSetting})
        ref_new_name.current.value = ''
        ref_new_notifiedEmails.current.value = ''
        ref_new_notifiedChatUrl.current.value = ''
        setNewNotificationContentType([])
      }).catch(err => catchError(err, 'グループ設定登録時にエラー'))
    }).catch(err => catchError(err, 'グループ登録時にエラー'))
  }

  const lineAdded = React.useCallback(() => {
    setBlocked(true)
    setUpdateMessage('')
  }, [])

  const lineModified = React.useCallback(() => {
    setChanged(true)
    lineAdded()
  }, [lineAdded])

  const changeSelectedNewNotificationContentType = event => {
    setNewNotificationContentType(event)
    lineAdded()
  }

  function closeConfirmationPopup() {
    setConfirmationMessage('')
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  // メモ化された関数の中ではuseStateは使用できないので独立した関数とする
  const GroupLineContentTypes = props => {
    const [selectedNotificationContentType, setSelectedNotificationContentType] = React.useState(null)
    if (selectedNotificationContentType == null) {
      if (refs_groups[props.id].notificationContentType.current == null) {
        return null
      } else {
        setSelectedNotificationContentType(refs_groups[props.id].notificationContentType.current)
      }
    }
    const changeSelectedContentTypes = event => {
      refs_groups[props.id].notificationContentType.current = event
      setSelectedNotificationContentType(event)
      lineModified()
    }
    return (
      <MultiSelect overrideStrings={multiSelectI18n} options={notificationContentTypeOptions} value={selectedNotificationContentType} onChange={changeSelectedContentTypes} />
    )
  }

  // onChangeイベントでフォーカスを失わないようにするためにはuseCallbackを使ってメモ化する必要がある
  // 依存する変数（第2引数の[]に指定する変数）もuseCallbackやuseMemoでメモ化する
  const GroupLine = React.useCallback(props => {
    const refs_group = refs_groups[props.id]
    if (!refs_group) {
      return null
    }
    const group = props.groups.filter(group => group.id === props.id)[0]
    const name = refs_group.name && refs_group.name.current && refs_group.name.current.value ? refs_group.name.current.value : group.name
    const notifiedEmails = refs_group.notifiedEmails && refs_group.notifiedEmails.current && refs_group.notifiedEmails.current.value ? refs_group.notifiedEmails.current.value : (groupSettings[props.id] ? groupSettings[props.id].notifiedEmails : '')
    const notifiedChatUrl = refs_group.notifiedChatUrl && refs_group.notifiedChatUrl.current && refs_group.notifiedChatUrl.current.value ? refs_group.notifiedChatUrl.current.value : (groupSettings[props.id] ? groupSettings[props.id].notifiedChatUrl : '')
    return (
      <tr>
        <td>
          {group.users.items.length === 0 && props.enquetesCount === 0 && <button className="admin-line-button" onClick={e => remove(props.id, group.name, e)}>削除</button>}
        </td>
        <td><input type="text" className="admin-table-input" defaultValue={name} onChange={lineModified} ref={refs_groups[props.id].name} /></td>
        <td><input type="text" className="admin-table-input" defaultValue={notifiedEmails} onChange={lineModified} ref={refs_groups[props.id].notifiedEmails} /></td>
        <td><input type="url" className="admin-table-input" defaultValue={notifiedChatUrl} onChange={lineModified} ref={refs_groups[props.id].notifiedChatUrl} /></td>
        <td>
          <GroupLineContentTypes id={props.id} />
        </td>
      </tr>
    )
  }, [lineModified, refs_groups, groupSettings, isGroupSettingsChanged])

  return (
    <div>
      <h1>グループ管理</h1>
      <p><button className="admin-update" disabled={!isChanged} onClick={update}>変更反映</button> {updateMessage}</p>
      <div className="admin-table-outer">
        <table className="admin-table">
          <tbody>
          <tr>
            <th></th>
            <th>名称</th>
            <th>デフォルト通知先メールアドレス</th>
            <th>デフォルト通知先Google Chat Webhook URL</th>
            <th>デフォルト通知内容</th>
          </tr>
          <tr>
            <td className="admin-move"><button className="admin-line-button" onClick={add}>追加</button></td>
            <td><input type="text" className="admin-table-input" ref={ref_new_name} onChange={lineAdded} /></td>
            <td><input type="text" className="admin-table-input" ref={ref_new_notifiedEmails} onChange={lineAdded} /></td>
            <td><input type="url" className="admin-table-input" ref={ref_new_notifiedChatUrl} onChange={lineAdded} /></td>
            <td>
              <MultiSelect overrideStrings={multiSelectI18n} options={notificationContentTypeOptions} value={newNotificationContentType} onChange={changeSelectedNewNotificationContentType} />
            </td>
          </tr>
          {sorted.map(groupId =>
            <GroupLine key={groupId} id={groupId} groups={props.groups} enquetesCount={props.enquetes.filter(e => e.groupId === groupId).length} />
          )}
          </tbody>
        </table>
      </div>
      <ConfirmationPopup message={confirmationMessage} exec={execToRemove} closePopup={closeConfirmationPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
    </div>
  )
}

export default Groups
