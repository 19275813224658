import React from 'react'
import {
  Auth,
} from 'aws-amplify'
import {
  getErrorMessage,
} from './util/common'

function ChangePassword() {
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState()

  const ref_current = React.useRef(null)
  const ref_new = React.useRef(null)
  const ref_new_again = React.useRef(null)

  function submit(event) {
    try {
      _submit(event)
    } catch (err) {
      catchError(err, 'パスワード変更処理でエラー')
    }
  }

  function _submit(event) {
    event.preventDefault()
    setMessage('')
    setErrorMessage('')
    const new1 = ref_new.current.value
    const new2 = ref_new_again.current.value
    if (new1 !== new2) {
      setErrorMessage('エラー：新しいパスワードが一致していません。')
      return
    }
    const current = ref_current.current.value
    setLoading(true)
    Auth.currentAuthenticatedUser().then(user => {
      return Auth.changePassword(user, current, new1)
    }).then(() => {
      setMessage('パスワードが変更されました。')
      ref_current.current.value = ''
      ref_new.current.value = ''
      ref_new_again.current.value = ''
    }).catch(err => catchError(err, 'パスワード変更時にエラー')
    ).finally(() => setLoading(false))
  }

  function keypress(event) {
    if (event.which === 13) {
      submit(event)
    }
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  return (
    <div>
      <h1>パスワードの変更</h1>
      <p>パスワードは8文字以上である必要があります。</p>
      <form onSubmit={submit}>
        <table className="main-table">
          <tbody>
            <tr>
              <th>現在のパスワード</th>
              <td><input type="password" ref={ref_current} onKeyPress={keypress}></input></td>
            </tr>
            <tr>
              <th>新しいパスワード</th>
              <td><input type="password" ref={ref_new} onKeyPress={keypress}></input></td>
            </tr>
            <tr>
              <th>新しいパスワード（再入力）</th>
              <td><input type="password" ref={ref_new_again} onKeyPress={keypress}></input></td>
            </tr>
          </tbody>
        </table>
        <p><button type="submit" disabled={loading}>送信</button></p>
      </form>
      {message && <p>{message}</p>}
      {errorMessage && <p className="color-red">{errorMessage}</p>}
    </div>
  )
}

export default ChangePassword
