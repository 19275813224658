import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  Link,
  useHistory,
} from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import DateTime from 'react-datetime'
import "react-datetime/css/react-datetime.css"
import 'moment/locale/ja'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { MultiSelect } from 'react-multi-select-component'
import {
  getEnqueteSettingByEnquete,
} from './graphql/queries'
import {
  updateEnquete,
  createEnqueteEmailUser,
  deleteEnqueteEmailUser,
  createEnqueteSetting,
  updateEnqueteSetting,
} from './graphql/mutations'
import {
  listEmailUsers,
  listEmailSent,
} from './db/data'
import {
  isDatetime,
  isEmail,
  isURL,
} from './util/validator'
import ConfirmationPopup from './ConfirmationPopup'
import ErrorPopup from './ErrorPopup'
import {
  UserClass,
  SorterColumnNames,
  userSorter,
  notificationContentTypeOptions,
} from './Users'
import {
  OUR_DOMAIN,
} from './Settings'
import {
  isES,
  getErrorMessage,
  multiSelectI18n,
} from './util/common'

function Additions(props) {
  const [curUsers, setCurUsers] = React.useState([])
  const [existingUsers, setExistingUsers] = React.useState([])
  const [emailTo, setEmailTo] = React.useState([])
  const [emailSent, setEmailSent] = React.useState([])
  const [enqueteSetting, setEnqueteSetting] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState()

  const history = useHistory()

  const ref_to_confirm = React.useRef(null)

  const ref_notified_emails = React.useRef(null)
  const ref_notified_chat = React.useRef(null)
  const [notificationContentType, setNotificationContentType] = React.useState([])
  const ref_email_users = React.useRef([])
  const ref_email_to = React.useRef([])
  const ref_email_from = React.useRef(null)
  const ref_email_subject = React.useRef(null)
  const ref_email_body = React.useRef(null)
  const ref_email_send_at = React.useRef(null)

  React.useEffect(() => {
    if (!props.enquete.id) {
      return
    }
    const curEnqueteUsers = props.users.filter(
      user => new UserClass(user.class).isAnswerer() && user.groups.items.some(
        group => group.groupId === props.enquete.groupId
      )
    ).map(user => ({...user, hasAnswer: !!(props.enqueteAnswers && props.enqueteAnswers[user.id])})).sort(emailUserSorter)
    setCurUsers(curEnqueteUsers)
    setExistingUsers(curEnqueteUsers.filter(user => !user.deleted))
  }, [props.users, props.enquete, props.enqueteAnswers])

  React.useEffect(() => {
    if (!props.enquete.id) {
      return
    }

    async function get(enqueteId) {
      await listEmailUsers(enqueteId).then(emails => {
        ref_email_users.current = emails
        ref_email_to.current = emails.map(email => email.userId)
        setEmailTo(ref_email_to.current)
      }).catch(err => catchError(err, 'メール送信先取得時にエラー'))
      await listEmailSent(enqueteId).then(emails => {
        setEmailSent(emails)
      }).catch(err => catchError(err, 'メール送信時刻取得時にエラー'))
      await API.graphql(graphqlOperation(getEnqueteSettingByEnquete, {enqueteId: enqueteId})).then(res => {
        const items = res.data.getEnqueteSettingByEnquete.items
        if (items.length === 1) {
          setNotificationContentType(notificationContentTypeOptions.filter(o => items[0].notificationContentType.includes(o.value)))
          setEnqueteSetting(items[0])
        }
      }).catch(err => catchError(err, 'アンケート設定情報取得時にエラー'))
    }
    get(props.enquete.id)
  }, [props.enquete])

  function isEmails(emailsStr, messages) {
    const emails = emailsStr.split(/ *, */).filter(a => a.length > 0)
    for (const email of emails) {
      if (!isEmail(email)) {
        messages.push(`通知先メールアドレス ${email} は正しいメールアドレスではありません。`)
      }
    }
  }

  async function saveNotificationInfo() {
    const messages = []
    const emails = ref_notified_emails.current.value
    isEmails(emails, messages)
    const chatURL = ref_notified_chat.current.value
    if (chatURL.length > 0 && !isURL(chatURL)) {
      messages.push(`通知先Google Chat Webhook URL ${chatURL} は正しいURLではありません。`)
    }
    if (messages.length > 0) {
      setErrorMessage(messages.join('\n'))
      return
    }
    const notificationContentTypeValues = notificationContentType.map(t => t.value)
    setLoading(true)
    if (enqueteSetting.hasOwnProperty('enqueteId')) {
      await API.graphql(graphqlOperation(updateEnqueteSetting, {input: {
        id: enqueteSetting.id,
        notifiedEmails: emails,
        notifiedChatUrl: chatURL,
        notificationContentType: notificationContentTypeValues,
      }})).then(res => setEnqueteSetting(res.data.updateEnqueteSetting)).catch(err => catchError(err, 'アンケート情報更新時にエラー'))
    } else {
      await API.graphql(graphqlOperation(createEnqueteSetting, {input: {
        enqueteId: props.enquete.id,
        notifiedEmails: emails,
        notifiedChatUrl: chatURL,
        notificationContentType: notificationContentTypeValues,
      }})).then(res => setEnqueteSetting(res.data.createEnqueteSetting)).catch(err => catchError(err, 'アンケート情報登録時にエラー'))
    }
    setLoading(false)
  }

  function emailUserSorter(a, b) {
    if (a.hasAnswer && !b.hasAnswer) {
      return 1
    }
    if (!a.hasAnswer && b.hasAnswer) {
      return -1
    }
    return userSorter(a, b)
  }

  async function downloadStatus() {
    await _downloadStatus().catch(err => catchError(err, '配信管理ダウンロード処理でエラー'))
  }

  async function _downloadStatus() {
    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')
    worksheet.columns = [
      {key: 'title', header: 'アンケートタイトル'},
      {key: 'sorter1', header: SorterColumnNames.sorter1},
      {key: 'sorter2', header: SorterColumnNames.sorter2},
      {key: 'sorter3', header: SorterColumnNames.sorter3},
      {key: 'email', header: 'メールアドレス'},
      {key: 'deleted', header: '削除済'},
      {key: 'reservationStatus', header: '【送信予約】状況'},
      {key: 'reservationAt', header: '【送信予約】配信予定日時'},
      {key: 'sendingStatus', header: '【配信】状況'},
      {key: 'sendingCreatedAt', header: '【配信】初回配信日時'},
      {key: 'sendingUpdatedAt', header: '【配信】最新配信日時'},
      {key: 'answerStatus', header: '【回答】状況'},
      {key: 'answerCreatedAt', header: '【回答】初回回答送信日時'},
      {key: 'answerUpdatedAt', header: '【回答】最新回答送信日時'},
    ]
    worksheet.addRows(getStatusCsvBody())
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), await workbook.csv.writeBuffer()],
      {type: "text/csv;charset=utf-8"})
    saveAs(blob, `${props.enquete.group.name}_${props.enquete.title}_配信管理.csv`)
  }

  function getStatusCsvBody() {
    const receiverIds = ref_email_users.current.map(receiver => receiver.userId)
    return curUsers.map(user => {
      const reservation = receiverIds.includes(user.id) && props.enquete.emailSendAt
      const sentDatetimes = emailSent.filter(sent => sent.userId === user.id)[0]
      const answeredDatetimes = props.enqueteAnsweredDatetimes && props.enqueteAnsweredDatetimes[user.id] ? props.enqueteAnsweredDatetimes[user.id] : null
      return {
        title: props.enquete.title,
        sorter1: user.sorter1,
        sorter2: user.sorter2,
        sorter3: user.sorter3,
        email: user.email,
        deleted: user.deleted ? '削除済' : '',
        reservationStatus: reservation ? '予約中' : '',
        reservationAt: reservation ? moment(props.enquete.emailSendAt).format('YYYY/MM/DD HH:mm:ss') : '',
        sendingStatus: sentDatetimes ? '配信済' : '未配信',
        sendingCreatedAt: sentDatetimes ? moment(sentDatetimes.createdAt).format('YYYY/MM/DD HH:mm:ss') : '',
        sendingUpdatedAt: sentDatetimes ? moment(sentDatetimes.updatedAt).format('YYYY/MM/DD HH:mm:ss') : '',
        answerStatus: answeredDatetimes && user.hasAnswer ? '回答済' : '未回答',
        answerCreatedAt: answeredDatetimes ? moment(answeredDatetimes.createdAt).format('YYYY/MM/DD HH:mm:ss') : '',
        answerUpdatedAt: answeredDatetimes ? moment(answeredDatetimes.updatedAt).format('YYYY/MM/DD HH:mm:ss') : '',
      }
    })
  }

  function checkAllMailTo() {
    try {
      ref_email_to.current = existingUsers.map(user => user.id)
      setEmailTo(ref_email_to.current)
    } catch (err) {
      catchError(err, '送信先全選択処理でエラー')
    }
  }

  function checkAllNotAnsweredMailTo() {
    try {
      const curEmailTo = [...ref_email_to.current]
      ref_email_to.current = existingUsers.filter(user => !user.hasAnswer || curEmailTo.includes(user.id)).map(user => user.id)
      setEmailTo(ref_email_to.current)
    } catch (err) {
      catchError(err, '送信先未回答者全選択処理でエラー')
    }
  }

  function uncheckAllMailTo() {
    try {
      ref_email_to.current = []
      setEmailTo(ref_email_to.current)
    } catch (err) {
      catchError(err, '送信先全選択解除処理でエラー')
    }
  }

  function mailToOnChange(value, event) {
    if (event.target.checked) {
      const values = [...ref_email_to.current, value]
      ref_email_to.current = existingUsers.map(user => user.id).filter(u => values.includes(u))
    } else if (ref_email_to.current.includes(value) && !event.target.checked) {
      ref_email_to.current = ref_email_to.current.filter(u => u !== value)
    }
  }

  function mailSendAtOnClose(value) {
    if (!isDatetime(value)) {
      setEmailTo(ref_email_to.current)
      setErrorMessage('正しくない日時が入力されました。')
      return
    }
    ref_email_send_at.current = value
  }

  function validateEmailTo() {
    let message = ''
    if (ref_email_to.current.length === 0) {
      message += `メール送信先は1つ以上選択する必要があります。\n`
    }
    return message
  }

  function validateEmailInfo() {
    let message = ''
    const emailFrom = ref_email_from.current.value
    if (!emailFrom) {
      message += `メール送信元メールアドレスは入力する必要があります。\n`
    } else if (!isEmail(emailFrom)) {
      message += `メール送信元メールアドレス ${emailFrom} は正しいメールアドレスではありません。\n`
    } else if (!emailFrom.endsWith(OUR_DOMAIN)) {
      message += `メール送信元メールアドレス ${emailFrom} は${OUR_DOMAIN}で終わる必要があります。\n`
    }
    const emailSubject = ref_email_subject.current.value
    if (!emailSubject) {
      message += `メールタイトルは入力する必要があります。\n`
    }
    return message
  }

  function calculateLeastAnswerDeadline(receivers) {
    if (!props.userDeadlines[props.enquete.id] || receivers.length === 0) {
      return props.enquete.answerDeadline
    }
    let leastAnswerDeadline = null
    for (const receiver of receivers) {
      if (props.userDeadlines[props.enquete.id][receiver.id]) {
        if (!leastAnswerDeadline || leastAnswerDeadline > props.userDeadlines[props.enquete.id][receiver.id]) {
          leastAnswerDeadline = props.userDeadlines[props.enquete.id][receiver.id].answerDeadline
        }
      } else if (!leastAnswerDeadline || leastAnswerDeadline > props.enquete.answerDeadline) {
        leastAnswerDeadline = props.enquete.answerDeadline
      }
    }
    return leastAnswerDeadline
  }

  function sendEnqueteNow() {
    try {
      _sendEnqueteNow()
    } catch (err) {
      catchError(err, 'アンケートメール送信処理でエラー')
    }
  }

  function _sendEnqueteNow() {
    setErrorMessage('')
    setEmailTo(ref_email_to.current)
    let message = validateEmailTo()
    message += validateEmailInfo()
    const now = moment()
    if (now < moment(props.enquete.publish)) {
      message += `公開日時を過ぎている必要があります。\n`
    }
    const receivers = existingUsers.filter(user => ref_email_to.current.includes(user.id))
    const leastAnswerDeadline = calculateLeastAnswerDeadline(receivers)
    if (now >= moment(leastAnswerDeadline)) {
      message += `回答期限日時に達していない必要があります。\n`
    }
    if (message) {
      setErrorMessage(message)
      return
    }
    ref_to_confirm.current = execToSendEnqueteNow
    setConfirmationMessage(`公開日時: ${moment(props.enquete.publish).format('YYYY/MM/DD HH:mm')}\n回答期限日時: ${moment(props.enquete.answerDeadline).format('YYYY/MM/DD HH:mm')}\n閲覧期限日時: ${moment(props.enquete.viewDeadline).format('YYYY/MM/DD HH:mm')}${props.enquete.answerDeadline !== leastAnswerDeadline ? `\n選択された回答者の最小の回答期限: ${moment(leastAnswerDeadline).format('YYYY/MM/DD HH:mm')}` : ''}\n\n以下の${receivers.length}名の回答者にすぐに送信します。\n${receivers.map(user => user.email).join('\n')}`)
  }

  async function updateEmailSendTo() {
    const updatedEmailUsers = [...ref_email_users.current]
    const emailUserIds = ref_email_users.current.map(user => user.userId)
    const addedEmailTo = ref_email_to.current.filter(userId => !emailUserIds.includes(userId))
    const deletedEmailTo = ref_email_users.current.filter(user => !ref_email_to.current.includes(user.userId)).map(user => user.id)
    for (const userId of addedEmailTo) {
      await API.graphql(graphqlOperation(createEnqueteEmailUser, {input: {
        enqueteId: props.enquete.id,
        userId: userId,
      }})).then(res => updatedEmailUsers.push(res.data.createEnqueteEmailUser)).catch(err => catchError(err, 'メール送信先追加（更新）時にエラー'))
    }
    for (const id of deletedEmailTo) {
      await API.graphql(graphqlOperation(deleteEnqueteEmailUser, {input: {
        id: id,
      }})).then(() => updatedEmailUsers.splice(updatedEmailUsers.findIndex(email => email.id === id), 1)).catch(err => catchError(err, 'メール送信先削除（更新）時にエラー'))
    }
    ref_email_users.current = [...updatedEmailUsers]
  }

  async function deleteEmailSendTo() {
    for (const user of ref_email_users.current) {
      await API.graphql(graphqlOperation(deleteEnqueteEmailUser, {input: {
        id: user.id,
      }})).then().catch(err => catchError(err, 'メール送信先削除時にエラー'))
    }
  }

  async function updateEmailInfo(includesSendAt) {
    const updated = {...props.enquete}
    updated.emailFrom = ref_email_from.current.value
    updated.emailSubject = ref_email_subject.current.value
    updated.emailBody = ref_email_body.current.value
    if (includesSendAt) {
      updated.emailSendAt = ref_email_send_at.current
    }
    if (props.enquete.emailFrom !== updated.emailFrom ||
        props.enquete.emailSubject !== updated.emailSubject ||
        props.enquete.emailBody !== updated.emailBody ||
        props.enquete.emailSendAt !== updated.emailSendAt) {
      await API.graphql(graphqlOperation(updateEnquete, {input: {
        id: props.enquete.id,
        emailFrom: updated.emailFrom,
        emailSubject: updated.emailSubject,
        emailBody: updated.emailBody,
        emailSendAt: updated.emailSendAt,
      }})).then(res => updateStateOfEnquetes(res.data.updateEnquete)).catch(err => catchError(err, 'アンケートメール情報更新時にエラー'))
    }
  }

  async function cleanSendAt() {
    await API.del('enquetesfrom2021h1gateway', `/mail/enquete/${props.enquete.id}`).catch(err => catchError(err, 'アンケートメール送信スケジュールクリア時にエラー'))
    await API.graphql(graphqlOperation(updateEnquete, {input: {
      id: props.enquete.id,
      emailSendAt: null,
    }})).then(res => updateStateOfEnquetes(res.data.updateEnquete)).catch(err => catchError(err, 'アンケートメール送信時刻DBクリア時にエラー'))
  }

  function updateStateOfEnquetes(updatedEnquete) {
    const updatedEnquetes = [...props.enquetes]
    updatedEnquetes[props.enquetes.indexOf(props.enquete)] = updatedEnquete
    props.setEnquetes(updatedEnquetes)
    props.setEnquete(updatedEnquete)
  }

  async function execToSendEnqueteNow() {
    setLoading(true)
    setConfirmationMessage('')
    await deleteEmailSendTo().catch(err => catchError(err, 'アンケートメール送信先クリア時にエラー'))
    await updateEmailInfo(false).catch(err => catchError(err, 'アンケートメール送信情報更新時にエラー'))
    await API.put('enquetesfrom2021h1gateway', `/mail/enquete/${props.enquete.id}`, {body: {esMode: isES(), emailsTo: ref_email_to.current}}).catch(err => catchError(err, 'アンケートメール送信時にエラー'))
    ref_email_to.current = []
    ref_email_users.current = []
    setEmailTo([])
    setLoading(false)
  }

  function sendEnqueteInFuture() {
    try {
      _sendEnqueteInFuture()
    } catch (err) {
      catchError(err, 'アンケートメール送信予約処理でエラー')
    }
  }

  function _sendEnqueteInFuture() {
    setErrorMessage('')
    setEmailTo(ref_email_to.current)
    let message = validateEmailTo()
    message += validateEmailInfo()
    if (!ref_email_send_at.current) {
      ref_email_send_at.current = moment(props.enquete.publish)
    }
    // 操作しているPCの時刻から取得されるので正しい時刻であるとは限らないが、とりあえず許容する
    const baseDatetime = moment().add(2, 'minutes')
    if (ref_email_send_at.current < baseDatetime) {
      message += `メール送信予約日時は現在日時より2分以上先である必要があります。\n`
    }
    if (ref_email_send_at.current < moment(props.enquete.publish)) {
      message += `メール送信予約日時は公開日時より先である必要があります。\n`
    }
    const receivers = existingUsers.filter(user => ref_email_to.current.includes(user.id))
    const leastAnswerDeadline = calculateLeastAnswerDeadline(receivers)
    if (ref_email_send_at.current >= moment(leastAnswerDeadline)) {
      message += `メール送信予約日時は回答期限日時より前である必要があります。\n`
    }
    if (message) {
      setErrorMessage(message)
      return
    }
    ref_to_confirm.current = execToSendEnqueteInFuture
    setConfirmationMessage(`公開日時: ${moment(props.enquete.publish).format('YYYY/MM/DD HH:mm')}\n回答期限日時: ${moment(props.enquete.answerDeadline).format('YYYY/MM/DD HH:mm')}\n閲覧期限日時: ${moment(props.enquete.viewDeadline).format('YYYY/MM/DD HH:mm')}${props.enquete.answerDeadline !== leastAnswerDeadline ? `\n選択された回答者の最小の回答期限: ${moment(leastAnswerDeadline).format('YYYY/MM/DD HH:mm')}` : ''}\n\n以下の${receivers.length}名の回答者に${ref_email_send_at.current.format('YYYY/MM/DD HH:mm')}に送信します。\n\n${receivers.map(user => user.email).join('\n')}`)
  }

  async function execToSendEnqueteInFuture() {
    setLoading(true)
    setConfirmationMessage('')
    await updateEmailSendTo().catch(err => catchError(err, 'アンケートメール送信先更新時にエラー'))
    await updateEmailInfo(true).catch(err => catchError(err, 'アンケートメール送信情報更新時にエラー'))
    await API.post('enquetesfrom2021h1gateway', `/mail/enquete/${props.enquete.id}`, {body: {esMode: isES(), emailsTo: ref_email_to.current}}).catch(err => catchError(err, 'アンケートメール送信予約時にエラー'))
    setLoading(false)
  }

  async function saveEmailInfo() {
    setLoading(true)
    await updateEmailSendTo().catch(err => catchError(err, 'アンケートメール送信先更新時にエラー'))
    await updateEmailInfo(false).catch(err => catchError(err, 'アンケートメール送信情報更新時にエラー'))
    setEmailTo(ref_email_to.current)
    setLoading(false)
  }

  function clearEnquete() {
    props.setEnquete({})
    history.push('/')
  }

  function closeConfirmationPopup() {
    setConfirmationMessage('')
    setEmailTo(ref_email_to.current)
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
    setEmailTo(ref_email_to.current)
  }

  function AdditionsEmailTo(props) {
    // laterは現在使われていないので常にfalse
    const later = props.answeredDatetimes && props.answeredDatetimes.later ? true : false
    return (
      <tr>
        <td>
          <input type="checkbox" name={'email_to'} value={props.id} defaultChecked={emailTo.includes(props.id)} onChange={e => mailToOnChange(props.id, e)} />
        </td>
        <td>
          <label className={props.hasAnswer ? '' : 'color-red'} >{props.emailSent.length > 0 ? `(${later ? '今は未回答' : (props.hasAnswer ? '回答済' : '未回答')})` : ''}</label>
        </td>
        <td>
          <label>{`${props.sorter1 ? `${props.sorter1} ` : ''}${props.sorter2 ? `${props.sorter2} ` : ''}${props.sorter3 ? `${props.sorter3} ` : ''}[${props.email}]`}</label>
        </td>
      </tr>
    )
  }

  const changeSelectedContentTypes = event => {
    setNotificationContentType(event)
  }

  return (
    <div>
      <h1>{`アンケート：${props.enquete.title} の送信設定`}</h1>
      <p><Link to="/" onClick={clearEnquete}>アンケート一覧に戻る</Link></p>
      <p><Link to="/">アンケート詳細に戻る</Link></p>
      {props.questions.items && <p><Link to="/answer">アンケート設問イメージへ</Link></p>}
      <section>
        <h3>一般設定</h3>
        <p>
          <span className="bold">告知</span><br />
          <span className="admin-notice">{props.enquete.notice}</span>
        </p>
      </section>
      <section>
        <h3>期間設定</h3>
        <p>
          <span className="bold">公開日時</span><br />
          {moment(props.enquete.publish).format('YYYY/MM/DD HH:mm')}
        </p>
        <p>
          <span className="bold">回答期限日時</span><br />
          {moment(props.enquete.answerDeadline).format('YYYY/MM/DD HH:mm')}
        </p>
        <p>
          <span className="bold">閲覧期限日時</span><br />
          {moment(props.enquete.viewDeadline).format('YYYY/MM/DD HH:mm')}
        </p>
        <p>
          <span className="bold">閲覧者には閲覧期間外でも閲覧可能とする</span><br />
          {props.enquete.locked ? 'オン' : 'オフ'}
        </p>
      </section>
      <section>
        <h3>通知設定</h3>
        <p>
          <span className="bold">通知先メールアドレス</span><br />,区切りで複数指定できます<br />
          <input type="text" className={'admin-long-input'} defaultValue={enqueteSetting.notifiedEmails} ref={ref_notified_emails} />
        </p>
        <p>
          <span className="bold">通知先Google Chat Webhook URL</span><br />
          <input type="url" className={'admin-long-input'} defaultValue={enqueteSetting.notifiedChatUrl} ref={ref_notified_chat} />
        </p>
        <div>
          <span className="bold">通知内容</span><br />
          <MultiSelect className={'admin-long-input'} overrideStrings={multiSelectI18n} options={notificationContentTypeOptions} value={notificationContentType} onChange={changeSelectedContentTypes} />
        </div>
        <p>
          <button onClick={saveNotificationInfo} disabled={loading}>通知設定の保存</button>
        </p>
      </section>
      {props.questions && props.questions.arranged.length > 0 &&
      <section>
        <h3>メール送信</h3>
        <div>
          <span className="bold">メール送信先</span> <button onClick={downloadStatus} disabled={loading}>CSVダウンロード</button><br /><br />
          <button onClick={checkAllMailTo} disabled={loading}>全て選択</button>&nbsp;
          <button onClick={checkAllNotAnsweredMailTo} disabled={loading}>回答済以外を選択</button>&nbsp;
          <button onClick={uncheckAllMailTo} disabled={loading}>全ての選択をクリア</button>
          <table>
            <tbody>
              {existingUsers.map(user => <AdditionsEmailTo key={user.id} answeredDatetimes={props.enqueteAnsweredDatetimes ? props.enqueteAnsweredDatetimes[user.id] : null} emailSent={emailSent.filter(sent => sent.userId === user.id)} {...user} />)}
            </tbody>
          </table>
        </div>
        <p>
          <span className="bold">メール送信元メールアドレス</span><br />
          {OUR_DOMAIN}のメールアドレスのみ有効です<br />
          <input type="email" defaultValue={props.enquete.emailFrom ? props.enquete.emailFrom : 'marketing@simpline.co.jp'} ref={ref_email_from} />
        </p>
        <p>
          <span className="bold">メールタイトル</span><br />
          <input type="text" className={'admin-long-input'} defaultValue={props.enquete.emailSubject} ref={ref_email_subject} />
        </p>
        <p>
          <span className="bold">メール案内文</span><br />
          この文章はアンケートの前に置かれます<br />
          <TextareaAutosize style={{ width: "90%" }} defaultValue={props.enquete.emailBody} ref={ref_email_body} />
        </p>
        <p>
          <button onClick={saveEmailInfo} disabled={loading}>送信せずにここまでのメール送信設定の保存だけ行う</button>
        </p>
        {props.enquete.emailSendAt &&
        <div>
          <DateTime locale="ja" initialValue={moment(props.enquete.emailSendAt)} inputProps={{disabled: true}} />
          <button onClick={cleanSendAt} disabled={loading}>送信予約を解除</button>
        </div>
        }
        {!props.enquete.emailSendAt &&
        <div>
          <DateTime locale="ja" initialValue={moment(props.enquete.publish)} onClose={mailSendAtOnClose} />
          <button onClick={sendEnqueteInFuture} disabled={loading}>この日時に送信</button>
        </div>
        }
        <p>
          <button onClick={sendEnqueteNow} disabled={props.enquete.emailSendAt || loading}>すぐに送信</button>
        </p>
      </section>
      }
      <ConfirmationPopup message={confirmationMessage} exec={ref_to_confirm.current} closePopup={closeConfirmationPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
    </div>
  )
}

export default Additions
