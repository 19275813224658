export const getUserWithGroupsByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          items {
            id
            userId
            groupId
            group {
              name
            }
            createdAt
            updatedAt
          }
            nextToken
        }
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersWithGroups = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          items {
            id
            userId
            groupId
            group {
              name
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsWithUsers = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users {
          items {
            userId
          }
          nextToken
        }
        enquetes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
