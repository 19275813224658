import React from 'react'
import Modal from 'react-modal'

const modalStyle = {
  overlay: {
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,1,0,0.5)"
  },
  content: {
    whiteSpace: 'pre-line',
    position: "absolute",
    top: "5rem",
    left: "5rem",
    right: "5rem",
    bottom: "5rem",
    backgroundColor: '#afeeee',
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

function ConfirmationPopup(props) {
  function Process(props) {
    if (!props.message) {
      return false
    }
    const warnMatch = props.message.match(/<warn>(.+?)<\/warn>/s)
    if (warnMatch) {
      return (
        <span className="confirmation-warn">{warnMatch[1]}</span>
      )
    }
    return (
      <span>{props.message}</span>
    )
  }
  const okMessage = props.okMessage ? props.okMessage : 'OK'
  return (
    <Modal isOpen={!!props.message} onRequestClose={props.closePopup} ariaHideApp={false} style={modalStyle}>
      <div>
        {props.message.split(/(<warn>.+?<\/warn>)/s).map((m, index) => <Process key={index} message={m} />)}
      </div>
      <div>
        <button className="admin-popup-button" onClick={props.exec}>{okMessage}</button> <button className="admin-popup-button" onClick={props.closePopup}>止める</button>
      </div>
    </Modal>
  )
}

export default ConfirmationPopup
