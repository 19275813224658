/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      sorter1
      sorter2
      sorter3
      email
      class
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      sorter1
      sorter2
      sorter3
      email
      class
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      sorter1
      sorter2
      sorter3
      email
      class
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquetes {
        items {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquetes {
        items {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquetes {
        items {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMtMUserGroup = /* GraphQL */ `
  mutation CreateMtMUserGroup(
    $input: CreateMtMUserGroupInput!
    $condition: ModelMtMUserGroupConditionInput
  ) {
    createMtMUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMtMUserGroup = /* GraphQL */ `
  mutation UpdateMtMUserGroup(
    $input: UpdateMtMUserGroupInput!
    $condition: ModelMtMUserGroupConditionInput
  ) {
    updateMtMUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMtMUserGroup = /* GraphQL */ `
  mutation DeleteMtMUserGroup(
    $input: DeleteMtMUserGroupInput!
    $condition: ModelMtMUserGroupConditionInput
  ) {
    deleteMtMUserGroup(input: $input, condition: $condition) {
      id
      userId
      groupId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnquete = /* GraphQL */ `
  mutation CreateEnquete(
    $input: CreateEnqueteInput!
    $condition: ModelEnqueteConditionInput
  ) {
    createEnquete(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      questions {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      notice
      publish
      answerDeadline
      viewDeadline
      locked
      emailFrom
      emailSubject
      emailBody
      emailSendAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnquete = /* GraphQL */ `
  mutation UpdateEnquete(
    $input: UpdateEnqueteInput!
    $condition: ModelEnqueteConditionInput
  ) {
    updateEnquete(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      questions {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      notice
      publish
      answerDeadline
      viewDeadline
      locked
      emailFrom
      emailSubject
      emailBody
      emailSendAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnquete = /* GraphQL */ `
  mutation DeleteEnquete(
    $input: DeleteEnqueteInput!
    $condition: ModelEnqueteConditionInput
  ) {
    deleteEnquete(input: $input, condition: $condition) {
      id
      groupId
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      questions {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      notice
      publish
      answerDeadline
      viewDeadline
      locked
      emailFrom
      emailSubject
      emailBody
      emailSendAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteQuestion = /* GraphQL */ `
  mutation CreateEnqueteQuestion(
    $input: CreateEnqueteQuestionInput!
    $condition: ModelEnqueteQuestionConditionInput
  ) {
    createEnqueteQuestion(input: $input, condition: $condition) {
      id
      enqueteId
      parentId
      children {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      overview
      description
      type
      necessity
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteQuestion = /* GraphQL */ `
  mutation UpdateEnqueteQuestion(
    $input: UpdateEnqueteQuestionInput!
    $condition: ModelEnqueteQuestionConditionInput
  ) {
    updateEnqueteQuestion(input: $input, condition: $condition) {
      id
      enqueteId
      parentId
      children {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      overview
      description
      type
      necessity
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteQuestion = /* GraphQL */ `
  mutation DeleteEnqueteQuestion(
    $input: DeleteEnqueteQuestionInput!
    $condition: ModelEnqueteQuestionConditionInput
  ) {
    deleteEnqueteQuestion(input: $input, condition: $condition) {
      id
      enqueteId
      parentId
      children {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      overview
      description
      type
      necessity
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteAnswer = /* GraphQL */ `
  mutation CreateEnqueteAnswer(
    $input: CreateEnqueteAnswerInput!
    $condition: ModelEnqueteAnswerConditionInput
  ) {
    createEnqueteAnswer(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      enqueteId
      enqueteQuestionId
      enqueteQuestion {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      answer
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateEnqueteAnswer = /* GraphQL */ `
  mutation UpdateEnqueteAnswer(
    $input: UpdateEnqueteAnswerInput!
    $condition: ModelEnqueteAnswerConditionInput
  ) {
    updateEnqueteAnswer(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      enqueteId
      enqueteQuestionId
      enqueteQuestion {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      answer
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteEnqueteAnswer = /* GraphQL */ `
  mutation DeleteEnqueteAnswer(
    $input: DeleteEnqueteAnswerInput!
    $condition: ModelEnqueteAnswerConditionInput
  ) {
    deleteEnqueteAnswer(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      enqueteId
      enqueteQuestionId
      enqueteQuestion {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      answer
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createEnqueteUserDeadline = /* GraphQL */ `
  mutation CreateEnqueteUserDeadline(
    $input: CreateEnqueteUserDeadlineInput!
    $condition: ModelEnqueteUserDeadlineConditionInput
  ) {
    createEnqueteUserDeadline(input: $input, condition: $condition) {
      id
      enqueteId
      enquete {
        id
        groupId
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        questions {
          nextToken
          __typename
        }
        title
        notice
        publish
        answerDeadline
        viewDeadline
        locked
        emailFrom
        emailSubject
        emailBody
        emailSendAt
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      answerDeadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteUserDeadline = /* GraphQL */ `
  mutation UpdateEnqueteUserDeadline(
    $input: UpdateEnqueteUserDeadlineInput!
    $condition: ModelEnqueteUserDeadlineConditionInput
  ) {
    updateEnqueteUserDeadline(input: $input, condition: $condition) {
      id
      enqueteId
      enquete {
        id
        groupId
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        questions {
          nextToken
          __typename
        }
        title
        notice
        publish
        answerDeadline
        viewDeadline
        locked
        emailFrom
        emailSubject
        emailBody
        emailSendAt
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      answerDeadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteUserDeadline = /* GraphQL */ `
  mutation DeleteEnqueteUserDeadline(
    $input: DeleteEnqueteUserDeadlineInput!
    $condition: ModelEnqueteUserDeadlineConditionInput
  ) {
    deleteEnqueteUserDeadline(input: $input, condition: $condition) {
      id
      enqueteId
      enquete {
        id
        groupId
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        questions {
          nextToken
          __typename
        }
        title
        notice
        publish
        answerDeadline
        viewDeadline
        locked
        emailFrom
        emailSubject
        emailBody
        emailSendAt
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      answerDeadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteUserAnswered = /* GraphQL */ `
  mutation CreateEnqueteUserAnswered(
    $input: CreateEnqueteUserAnsweredInput!
    $condition: ModelEnqueteUserAnsweredConditionInput
  ) {
    createEnqueteUserAnswered(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      later
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteUserAnswered = /* GraphQL */ `
  mutation UpdateEnqueteUserAnswered(
    $input: UpdateEnqueteUserAnsweredInput!
    $condition: ModelEnqueteUserAnsweredConditionInput
  ) {
    updateEnqueteUserAnswered(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      later
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteUserAnswered = /* GraphQL */ `
  mutation DeleteEnqueteUserAnswered(
    $input: DeleteEnqueteUserAnsweredInput!
    $condition: ModelEnqueteUserAnsweredConditionInput
  ) {
    deleteEnqueteUserAnswered(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      later
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteEmailUser = /* GraphQL */ `
  mutation CreateEnqueteEmailUser(
    $input: CreateEnqueteEmailUserInput!
    $condition: ModelEnqueteEmailUserConditionInput
  ) {
    createEnqueteEmailUser(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteEmailUser = /* GraphQL */ `
  mutation UpdateEnqueteEmailUser(
    $input: UpdateEnqueteEmailUserInput!
    $condition: ModelEnqueteEmailUserConditionInput
  ) {
    updateEnqueteEmailUser(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteEmailUser = /* GraphQL */ `
  mutation DeleteEnqueteEmailUser(
    $input: DeleteEnqueteEmailUserInput!
    $condition: ModelEnqueteEmailUserConditionInput
  ) {
    deleteEnqueteEmailUser(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteEmailSent = /* GraphQL */ `
  mutation CreateEnqueteEmailSent(
    $input: CreateEnqueteEmailSentInput!
    $condition: ModelEnqueteEmailSentConditionInput
  ) {
    createEnqueteEmailSent(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteEmailSent = /* GraphQL */ `
  mutation UpdateEnqueteEmailSent(
    $input: UpdateEnqueteEmailSentInput!
    $condition: ModelEnqueteEmailSentConditionInput
  ) {
    updateEnqueteEmailSent(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteEmailSent = /* GraphQL */ `
  mutation DeleteEnqueteEmailSent(
    $input: DeleteEnqueteEmailSentInput!
    $condition: ModelEnqueteEmailSentConditionInput
  ) {
    deleteEnqueteEmailSent(input: $input, condition: $condition) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGeneralSetting = /* GraphQL */ `
  mutation CreateGeneralSetting(
    $input: CreateGeneralSettingInput!
    $condition: ModelGeneralSettingConditionInput
  ) {
    createGeneralSetting(input: $input, condition: $condition) {
      id
      notificationEmail
      notifiedEmails
      notifiedChatUrl
      notificationSubject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGeneralSetting = /* GraphQL */ `
  mutation UpdateGeneralSetting(
    $input: UpdateGeneralSettingInput!
    $condition: ModelGeneralSettingConditionInput
  ) {
    updateGeneralSetting(input: $input, condition: $condition) {
      id
      notificationEmail
      notifiedEmails
      notifiedChatUrl
      notificationSubject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGeneralSetting = /* GraphQL */ `
  mutation DeleteGeneralSetting(
    $input: DeleteGeneralSettingInput!
    $condition: ModelGeneralSettingConditionInput
  ) {
    deleteGeneralSetting(input: $input, condition: $condition) {
      id
      notificationEmail
      notifiedEmails
      notifiedChatUrl
      notificationSubject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroupSetting = /* GraphQL */ `
  mutation CreateGroupSetting(
    $input: CreateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    createGroupSetting(input: $input, condition: $condition) {
      id
      groupId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroupSetting = /* GraphQL */ `
  mutation UpdateGroupSetting(
    $input: UpdateGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    updateGroupSetting(input: $input, condition: $condition) {
      id
      groupId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroupSetting = /* GraphQL */ `
  mutation DeleteGroupSetting(
    $input: DeleteGroupSettingInput!
    $condition: ModelGroupSettingConditionInput
  ) {
    deleteGroupSetting(input: $input, condition: $condition) {
      id
      groupId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEnqueteSetting = /* GraphQL */ `
  mutation CreateEnqueteSetting(
    $input: CreateEnqueteSettingInput!
    $condition: ModelEnqueteSettingConditionInput
  ) {
    createEnqueteSetting(input: $input, condition: $condition) {
      id
      enqueteId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEnqueteSetting = /* GraphQL */ `
  mutation UpdateEnqueteSetting(
    $input: UpdateEnqueteSettingInput!
    $condition: ModelEnqueteSettingConditionInput
  ) {
    updateEnqueteSetting(input: $input, condition: $condition) {
      id
      enqueteId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEnqueteSetting = /* GraphQL */ `
  mutation DeleteEnqueteSetting(
    $input: DeleteEnqueteSettingInput!
    $condition: ModelEnqueteSettingConditionInput
  ) {
    deleteEnqueteSetting(input: $input, condition: $condition) {
      id
      enqueteId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
