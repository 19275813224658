import './App.css'
import Logo from './images/logo.png'
import Answer from './Answer'
import Enquetes, { enqueteSorter } from './Enquetes'
import Additions from './Additions'
import Users, { UserClass } from './Users'
import Groups from './Groups'
import Settings from './Settings'
import ChangePassword from './ChangePassword'
import Detail from './Detail'
import ErrorPopup from './ErrorPopup'
import { Buffer } from 'buffer'
import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom"
import {
  AmplifyProvider,
  Authenticator,
  translations,
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import {
  API,
  Auth,
  graphqlOperation,
  Hub,
  I18n,
} from 'aws-amplify'
import {
  listEnquetes,
  listGeneralSettings,
} from './graphql/queries'
import {
  getUserWithGroupsByEmail,
  listGroupsWithUsers,
  listUsersWithGroups,
} from './graphql/user_defined'
import {
  createUser,
} from './graphql/mutations'
import {
  getAnsweredDatetimesByEnquete,
  getArrangedAnswers,
  getArrangedQuestions,
  getUserDeadlines,
  getUserDeadlinesByUser,
} from './db/data'
import {
  isES,
  getErrorMessage,
} from './util/common'
import '@aws-amplify/ui-react/styles.css'

I18n.putVocabularies(translations)
I18n.setLanguage('ja')

const adminUser = {sorter1: '.', class: UserClass.FirstUserClass}
const unauthorizedUser = {sorter1: ''}

export const UndoContext = React.createContext()

function App() {
  const [isSignedIn, setSignedIn] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState()
  const [users, setUsers] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [enquetes, setEnquetes] = React.useState([])
  const [enquete, setEnquete] = React.useState({})
  const [settings, setSettings] = React.useState(null)
  const [undo, setUndo] = React.useState()
  const [redo, setRedo] = React.useState()
  const [questions, setQuestions] = React.useState([])
  const [answers, setAnswers] = React.useState({})
  const [answeredDatetimes, setAnsweredDatetimes] = React.useState({})
  const [userDeadlines, setUserDeadlines] = React.useState({})
  const undoContextValue = {setUndo, setRedo}
  const [keyForQuestions, setKeyForQuestions] = React.useState('')
  const [keyForAnswers, setKeyForAnswers] = React.useState('')
  const [keyForAnsweredDatetimes, setKeyForAnsweredDatetimes] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [authMessage, setAuthMessage] = React.useState('')
  const questionsHistory = React.useMemo(() => ({undo: [], redo: []}), [])

  // @aws-amplify/ui-reactを2.16.0以降にアップデートすると、親コンポーネントのstateを更新すると
  // 子コンポーネントのuseEffectに記述した廃棄処理と生成処理が実行されるようになり、結果として
  // 生成処理内で親コンポーネントのstateを更新している場合無限ループが発生する
  // ここ以降のinitXxx関数は対策として無限ループにならないような制御を入れたものである
  const initQandA = async (enqueteId, users) => {
    if (keyForQuestions === enqueteId && keyForAnswers === enqueteId) {
      return
    }
    async function getQandA(enqueteId, users) {
      setKeyForQuestions(enqueteId)
      await getArrangedQuestions(enqueteId).then(setQuestions).catch(err => catchError(err, '質問取得時にエラー'))
      setKeyForAnswers(enqueteId)
      // ここでanswersにenqueteIdがキーとして必ずセットされるので
      // これがあるかどうかで処理が完了したかどうかを判定することができる
      if (users.length > 0) {
        await getArrangedAnswers(users.map(user => user.id), [enqueteId]).then(res => {
          setAnswers(res[enqueteId] ? res : {[enqueteId]: {}})
        }).catch(err => catchError(err, '回答取得時にエラー'))
      } else {
        setAnswers({[enqueteId]: {}})
      }
    }
    await getQandA(enqueteId, users).catch(err => catchError(err, '質問回答取得処理でエラー'))
  }

  const initAnsweredDatetimes = enqueteId => {
    if (keyForAnsweredDatetimes === enqueteId) {
      return
    }
    async function getAnsweredDatetimes() {
      await getAnsweredDatetimesByEnquete(enqueteId).then(setAnsweredDatetimes).catch(err => catchError(err, '回答時刻取得時にエラー'))
    }
    setKeyForAnsweredDatetimes(enqueteId)
    getAnsweredDatetimes(enqueteId)
  }

  React.useEffect(() => {
    if (isES()) {
      window.document.title = '【社内用】ｓｉｍｐｌｉｎｅ株式会社 アンケート'
    }
  }, [])

  const getUsers = React.useCallback((items, nextToken) => {
    API.graphql(graphqlOperation(listUsersWithGroups, {nextToken: nextToken})).then(res => {
      const data = res.data.listUsers
      items = items.concat(data.items)
      if (data.nextToken) {
        getUsers(items, data.nextToken)
      } else {
        setUsers(items)
      }
    }).catch(err => catchError(err, 'ユーザー情報取得時にエラー'))
  }, [])

  const getGroups = React.useCallback((items, nextToken) => {
    API.graphql(graphqlOperation(listGroupsWithUsers, {nextToken: nextToken})).then(res => {
      const data = res.data.listGroups
      items = items.concat(data.items)
      if (data.nextToken) {
        getGroups(items, data.nextToken)
      } else {
        setGroups(items)
      }
    }).catch(err => catchError(err, 'グループ情報取得時にエラー'))
  }, [])

  const getUserInfo = React.useCallback(email => {
    function getOtherInfo(curUserInfo) {
      const curUserClass = new UserClass(curUserInfo)
      setUserInfo(curUserInfo)
      getUsersAndGroups(curUserInfo, curUserClass)
      // そこまで厳密でもないので現時刻でなく取得時刻で良いだろう
      getEnquetes(curUserInfo, curUserClass, new Date(), [])
      getDeadlines(curUserClass)
      getGeneralSettings(curUserClass)
    }

    function getUsersAndGroups(curUserInfo, curUserClass) {
      // 管理者と集計者
      // ここで取得される情報はユーザー管理だけでなくアンケートの回答取得にも使用される
      if (curUserClass.canAggregate()) {
        getUsers([])
      }
      // 管理者とグループに所属しない集計者
      // 元々はグループ管理を行う人（＝管理者）がログインしているときだけgetGroupが呼ばれる想定だったが、
      // グループに所属しない集計者がグループ情報を持つのに、ここで呼ばないと辛い
      if (curUserInfo.groups.items.length === 0) {
        getGroups([])
      }
    }

    function getEnquetes(curUserInfo, curUserClass, now, items, nextToken) {
      getUserDeadlinesByUser(curUserInfo.id).then(userDeadlines => {
        function getUserDeadline(enquete) {
          return userDeadlines[enquete.id] && userDeadlines[enquete.id][curUserInfo.id] ? userDeadlines[enquete.id][curUserInfo.id].answerDeadline : enquete.answerDeadline
        }

        function enqueteAnswererSorter(a, b) {
          const userDeadlineA = getUserDeadline(a)
          const userDeadlineB = getUserDeadline(b)
          if (userDeadlineA === userDeadlineB) {
            return 0
          }
          return userDeadlineA > userDeadlineB ? 1 : -1
        }

        API.graphql(graphqlOperation(listEnquetes, {nextToken: nextToken})).then(res => {
          const data = res.data.listEnquetes
          items = items.concat(data.items.filter(enquete => {
            // 管理者とグループに所属しない集計者はすべてのアンケートが見える
            // グループに所属する集計者は所属グループのアンケートが見える
            // 閲覧者は所属グループの公開済みで閲覧期限内か期間外閲覧可能のアンケートが見える
            // 回答者は所属グループの公開済みで閲覧期限あるいはユーザー個別の回答期限のどちらか遅い方までのアンケートが見える
            const userDeadline = getUserDeadline(enquete)
            const viewDeadline = userDeadline > enquete.viewDeadline ? userDeadline : enquete.viewDeadline
            if (curUserClass.belongsToGroup(enquete.groupId) &&
                (curUserClass.canAggregate() ||
                (curUserClass.isViewer() && ((Date.parse(enquete.publish) <= now && Date.parse(viewDeadline) > now) || enquete.locked)) ||
                (curUserClass.isAnswerer() && Date.parse(enquete.publish) <= now && Date.parse(viewDeadline) > now))) {
              return true
            }
            return false
          }))
          if (data.nextToken) {
            getEnquetes(curUserInfo, curUserClass, now, items, data.nextToken)
          } else {
            if (curUserClass.isAnswerer()) {
              // 回答者以外は他の場所でソートする
              items.sort(enqueteAnswererSorter)
            }
            setEnquetes(items)
            if (items.length > 0) {
              if (window.location.pathname && window.location.pathname.startsWith('/enquete/')) {
                const targetId = window.location.pathname.replace('/enquete/', '')
                const targets = items.filter(enquete => enquete.id === targetId)
                if (targets.length === 1) {
                  setEnquete(targets[0])
                }
              } else if (curUserClass.isAnswerer()) {
                setEnquete(items[0])
              }
            }
          }
        }).catch(err => catchError(err, 'アンケート情報取得時にエラー'))
      }).catch(err => catchError(err, '個人回答期限取得時にエラー'))
    }

    function getDeadlines(curUserClass) {
      if (!curUserClass.canAggregate()) {
        return
      }
      getUserDeadlines().then(setUserDeadlines).catch(err => catchError(err, '回答期限取得時にエラー'))
    }

    function getGeneralSettings(curUserClass) {
      if (!curUserClass.isAdmin()) {
        return
      }
      API.graphql(graphqlOperation(listGeneralSettings, {limit: 1})).then(res => {
        if (res.data.listGeneralSettings.items.length > 0) {
          setSettings(res.data.listGeneralSettings.items[0])
        }
      }).catch(err => catchError(err, '全般設定取得時にエラー'))
    }

    API.graphql(graphqlOperation(getUserWithGroupsByEmail, {email: email})).then(res => {
      const curUserItems = res.data.getUserByEmail.items
      if (curUserItems.length > 0) {
        getOtherInfo(curUserItems[0])
      } else {
        // 最初のユーザーが最初にログインした時の処理
        API.graphql(graphqlOperation(createUser, {input: {...adminUser, email: email}})).then(res => {
          getOtherInfo(res.data.createUser)
        }).catch(err => catchError(err, '管理者ユーザー作成時にエラー'))
      }
    }).catch(err => catchError(err, 'ユーザーグループ取得時にエラー'))
  }, [getUsers, getGroups])

  function answerLinkOnClick(enquete) {
    setEnquete(enquete)
  }

  Hub.listen("auth", data => {
    // ログインしたらここがイベント発火地点
    if (data.payload.event === 'signIn') {
      if (!userInfo) {
        // !userInfoがtrueになるのはuserInfoが初期値、つまりまだsetUserInfoが呼ばれていないとき
        // セッションが切れたとき、リロードしたときなど
        setSignedIn(true)
      }
    } else if (data.payload.event && data.payload.event !== 'tokenRefresh') {
      setSignedIn(false)
      setUsers([])
      setGroups([])
      setEnquetes([])
      setEnquete({})
      setSettings(null)
      setQuestions([])
      setAnswers({})
      setAnsweredDatetimes({})
      setUserDeadlines({})
      setKeyForQuestions('')
      setKeyForAnswers('')
      setKeyForAnsweredDatetimes('')
    }
  })

  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(res => {
      const email = res.attributes.email
      if (email) {
        getUserInfo(email)
      } else {
        setUserInfo(unauthorizedUser)
      }
    }).catch(() => {
      setUserInfo(unauthorizedUser)
    })
  }, [isSignedIn, getUserInfo])

  const userClass = React.useMemo(() => new UserClass(userInfo), [userInfo])

  const enqueteUsers = React.useMemo(() => {
    if (!userClass.canAggregate() || !enquete.groupId) {
      return []
    }
    return users.filter(
      user => new UserClass(user.class).isAnswerer() && user.groups.items.some(
        group => group.groupId === enquete.groupId
      )
    )
  }, [userClass, users, enquete], [])

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paramA = urlParams.get('a')
    const paramB = urlParams.get('b')
    const paramC = urlParams.get('c')
    if (paramA && paramB && paramC) {
      setAuthMessage('認証中です...')
      Auth.signIn(Buffer.from(BigInt('0x' + paramC).toString(16), 'hex').toString()).then(async (user) => {
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          await Auth.sendCustomChallengeAnswer(user, user.challengeName, {a: paramA, b: paramB})
        }
        window.location.href = window.location.pathname
        setAuthMessage('')
      }).catch(() => {
        window.location.href = window.location.pathname
        setAuthMessage('')
      })
    }
  }, [])

  if (authMessage) {
    return <p>{authMessage}</p>
  }

  const components = {
    Footer() {
      return (
        <div className="login-footer">
          <p className="bold">
            アンケートを受信されたメールアドレスでサインインしてください。<br />
            前回もご回答いただいた方は同じパスワードでサインインしていただけます。
          </p>
          <p>
            <span className="bold">◆初めての方やパスワードをお忘れの場合◆</span><br />
            ①「パスワードを忘れましたか？」をクリック<br />
            ②アンケートを受信されたメールアドレスをご入力の上、「Send code」をクリック<br />
            ③「Your verification code」という件名のメールを受信<br />
            ④メール記載のコードを「コード」欄にご入力の上、新しいパスワードを「送信」<br />
            ⑤設定した新しいパスワードでサインイン
          </p>
        </div>
      )
    },
    SignIn: {
      Header() {
        return (
          <div className="login-title sign-in">{isES() ? '【社内用】ｓｉｍｐｌｉｎｅ株式会社 アンケート' : 'ｓｉｍｐｌｉｎｅ株式会社 アンケート'}</div>
        )
      },
    },
    ResetPassword: {
      Header() {
        return (
          <div className="login-title">パスワードをリセット</div>
        )
      },
    },
    ConfirmResetPassword: {
      Header() {
        return (
          <div className="login-title">パスワードをリセット</div>
        )
      },
    },
  }
  const formFields = {
    signIn: {
      username: {
        label: false,
        placeholder: 'メールアドレス',
      },
      password: {
        label: false,
        placeholder: 'パスワード',
      },
    },
    resetPassword: {
      username: {
        label: false,
        placeholder: 'メールアドレスを入力',
      },
    },
  }

  const appHeaderClassName = isES() ? 'App-header App-header-es' : 'App-header'

  const enqueteAnswers = answers[enquete.id] ? answers[enquete.id] : null
  const enqueteAnsweredDatetimes = answeredDatetimes[enquete.id] ? answeredDatetimes[enquete.id] : null

  const sortingEnquetes = [...enquetes]
  sortingEnquetes.sort(enqueteSorter)

  return (
    <AmplifyProvider>
      <Authenticator loginMechanisms={['email']} hideSignUp={true} components={components} formFields={formFields}>
        {({ signOut }) => (
        <Router>
          {userInfo &&
          <div className={appHeaderClassName}>
            <img src={Logo} className="App-logo" alt="" />{isES() ? '【社内用】ｓｉｍｐｌｉｎｅ株式会社 アンケート' : 'ｓｉｍｐｌｉｎｅ株式会社 アンケート'}
            <div className="App-change-password">{userInfo.sorter1} {userInfo.sorter3} {isES() ? 'さん' : '様'} {userClass.isAdmin() && '(管理者) '}{userClass.isAggregator() && '(集計者) '}{userClass.isViewer() && '(閲覧者) '}<Link to="/change_password">パスワード変更</Link> </div>
            <button className="sign-out" onClick={signOut}>サインアウト</button>
          </div>
          }
          {!userInfo &&
          <div className={appHeaderClassName}>
            <button className="sign-out" onClick={signOut}>サインアウト</button>
          </div>
          }
          {userInfo &&
          <section className="App-main">
            <div className="App-main-left">
              <div className="App-main-menu">
                <nav>
                  <ul>
                    {(userClass.isAnswerer() || userClass.isViewer()) &&
                      sortingEnquetes.map(enquete => {
                        return <li key={enquete.id}><Link to="/" onClick={() => answerLinkOnClick(enquete)} >{enquete.title}</Link></li>
                      })
                    }
                    {userClass.canAggregate() &&
                    <li>
                      <Link to="/">アンケート管理</Link>
                    </li>
                    }
                    {userClass.isAdmin() &&
                    <li>
                      <Link to="/users">ユーザー管理</Link>
                    </li>
                    }
                    {userClass.isAdmin() &&
                    <li>
                      <Link to="/groups">グループ管理</Link>
                    </li>
                    }
                    {userClass.isAdmin() &&
                    <li>
                      <Link to="/settings">全般設定</Link>
                    </li>
                    }
                  </ul>
                </nav>
                <Switch>
                  {enquete.id &&
                  <Route path="/edit">
                    <ul>
                      <li>
                        {undo &&
                        <Link to="#!" onClick={undo}>元に戻す</Link>
                        }
                        {!undo &&
                        <span>元に戻す</span>
                        }
                      </li>
                      <li>
                        {redo &&
                        <Link to="#!" onClick={redo}>やり直し</Link>
                        }
                        {!redo &&
                        <span>やり直し</span>
                        }
                      </li>
                    </ul>
                  </Route>
                  }
                </Switch>
              </div>
              {userClass.isAnswerer() &&
              <div>
                <ul>
                  <li><a href="https://www.simpline.co.jp/policy/" target="_blank">セキュリティポリシー</a></li>
                </ul>
                <div className="App-main-query">【本件に関するお問い合わせ先】<br />✉ marketing@simpline.co.jp</div>
              </div>
              }
            </div>
            <div className="App-main-right">
              <Switch>
                {userClass.isAnswerer() &&
                <Route path="/answer">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquetes={enquetes} enquete={enquete} setEnquete={setEnquete} userInfo={userInfo} />
                  </UndoContext.Provider>
                </Route>
                }
                {userClass.isAdmin() && enquete.id &&
                <Route path="/answer">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquete={enquete} userInfo={userInfo} setEnquete={setEnquete} questions={questions} enqueteAnswers={enqueteAnswers} questionsHistory={questionsHistory} />
                  </UndoContext.Provider>
                </Route>
                }
                {userClass.isStaff() && !userClass.isAdmin() && enquete.id &&
                <Route path="/answer">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquete={enquete} userInfo={userInfo} setEnquete={setEnquete} questions={questions} />
                  </UndoContext.Provider>
                </Route>
                }
                {userClass.isStaff() && !enquete.id &&
                <Route path="/answer">
                  <Enquetes enquetes={enquetes} setEnquetes={setEnquetes} enquete={enquete} setEnquete={setEnquete} userInfo={userInfo} users={users} groups={groups} userDeadlines={userDeadlines} setUserDeadlines={setUserDeadlines} />
                </Route>
                }
                {userClass.isAdmin() && enquete.id &&
                <Route path="/_special">
                  <Detail userInfo={userInfo} enquetes={enquetes} setEnquetes={setEnquetes} enquete={enquete} setEnquete={setEnquete} users={users} enqueteUsers={enqueteUsers} questions={questions} setQuestions={setQuestions} enqueteAnswers={enqueteAnswers} setAnswers={setAnswers} initQandA={initQandA} enqueteAnsweredDatetimes={enqueteAnsweredDatetimes} setAnsweredDatetimes={setAnsweredDatetimes} userDeadlines={userDeadlines} setUserDeadlines={setUserDeadlines} special={true} />
                </Route>
                }
                {userClass.canAggregate() && enquete.id &&
                <Route path="/enquete">
                  <Detail userInfo={userInfo} enquetes={enquetes} setEnquetes={setEnquetes} enquete={enquete} setEnquete={setEnquete} users={users} enqueteUsers={enqueteUsers} questions={questions} setQuestions={setQuestions} enqueteAnswers={enqueteAnswers} setAnswers={setAnswers} initQandA={initQandA} enqueteAnsweredDatetimes={enqueteAnsweredDatetimes} initAnsweredDatetimes={initAnsweredDatetimes} setAnsweredDatetimes={setAnsweredDatetimes} userDeadlines={userDeadlines} setUserDeadlines={setUserDeadlines} />
                </Route>
                }
                {(userClass.isViewer() || userClass.isAnswerer()) && enquete.id &&
                <Route path="/enquete">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquetes={enquetes} enquete={enquete} setEnquete={setEnquete} userInfo={userInfo} />
                  </UndoContext.Provider>
                </Route>
                }
                {userClass.isAdmin() && enquete.id &&
                <Route path="/edit">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquete={enquete} userInfo={userInfo} setEnquete={setEnquete} questions={questions} setQuestions={setQuestions} questionsHistory={questionsHistory} setKeyForQuestions={setKeyForQuestions} edit={true} />
                  </UndoContext.Provider>
                </Route>
                }
                {userClass.isAdmin() && !enquete.id &&
                <Route path="/edit" render={() => <Redirect to="/" />} />
                }
                {userClass.isAdmin() && enquete.id &&
                <Route path="/additions">
                  <Additions enquete={enquete} setEnquete={setEnquete} enquetes={enquetes} setEnquetes={setEnquetes} users={users} questions={questions} enqueteAnswers={enqueteAnswers} enqueteAnsweredDatetimes={enqueteAnsweredDatetimes} userDeadlines={userDeadlines} />
                </Route>
                }
                {userClass.isAdmin() && !enquete.id &&
                <Route path="/additions" render={() => <Redirect to="/" />} />
                }
                {userClass.isAdmin() &&
                <Route path="/users">
                  <Users userInfo={userInfo} users={users} groups={groups} getUsers={getUsers} getGroups={getGroups} />
                </Route>
                }
                {userClass.isAdmin() &&
                <Route path="/groups">
                  <Groups groups={groups} enquetes={enquetes} setGroups={setGroups} />
                </Route>
                }
                {userClass.isAdmin() &&
                <Route path="/settings">
                  <Settings settings={settings} setSettings={setSettings} />
                </Route>
                }
                <Route path="/change_password">
                  <ChangePassword />
                </Route>
                {userClass.isStaff() && !enquete.id &&
                <Route path="/">
                  <Enquetes enquetes={enquetes} setEnquetes={setEnquetes} enquete={enquete} setEnquete={setEnquete} userInfo={userInfo} groups={groups} userDeadlines={userDeadlines} setUserDeadlines={setUserDeadlines} />
                </Route>
                }
                {userClass.canAggregate() && enquete.id &&
                <Route path="/">
                  <Detail userInfo={userInfo} enquetes={enquetes} setEnquetes={setEnquetes} enquete={enquete} setEnquete={setEnquete} users={users} enqueteUsers={enqueteUsers} questions={questions} setQuestions={setQuestions} enqueteAnswers={enqueteAnswers} setAnswers={setAnswers} initQandA={initQandA} enqueteAnsweredDatetimes={enqueteAnsweredDatetimes} initAnsweredDatetimes={initAnsweredDatetimes} setAnsweredDatetimes={setAnsweredDatetimes} userDeadlines={userDeadlines} setUserDeadlines={setUserDeadlines} />
                </Route>
                }
                {userClass.isAnswerer() &&
                <Route path="/">
                  <UndoContext.Provider value={undoContextValue}>
                    <Answer enquetes={enquetes} enquete={enquete} setEnquete={setEnquete} userInfo={userInfo} />
                  </UndoContext.Provider>
                </Route>
                }
                {!userClass.canAggregate() && enquete.id &&
                <Route path="/" render={() => <Redirect to="/answer" />} />
                }
              </Switch>
            </div>
          </section>
          }
          <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
        </Router>
      )}
      </Authenticator>
    </AmplifyProvider>
  )
}

export default App
