import './App.css'
import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  Link,
} from "react-router-dom"
import DateTime from 'react-datetime'
import "react-datetime/css/react-datetime.css"
import 'moment/locale/ja'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import deepcopy from 'lodash/cloneDeep'
import { useDropzone } from 'react-dropzone'
import { usePapaParse } from 'react-papaparse'
import ConfirmationPopup from './ConfirmationPopup'
import ErrorPopup from './ErrorPopup'
import {
  deleteEnquete,
  createEnqueteQuestion,
  deleteEnqueteQuestion,
  createEnqueteUserDeadline,
  deleteEnqueteUserDeadline,
  updateEnqueteUserDeadline,
  createEnqueteUserAnswered,
  deleteEnqueteUserAnswered,
  updateEnqueteUserAnswered,
} from './graphql/mutations'
import {
  getArrangedQuestions,
  getQuestions,
  getUserDeadlines,
  registerAnswers,
} from './db/data'
import {
  getErrorMessage,
} from './util/common'
import {
  isDatetime,
} from './util/validator'
import {
  UserClass,
  SorterColumnNames,
  userSorter,
} from './Users'

// メールアドレスのカラムは6番目（0から数えると5）
// ダウンロードとアップロードの両ファイルのカラムは同じとしているので、アップロードのメールアドレスより前、1番目から5番目までのカラムの値は無視される
const EMAIL_COLUMN = 5

function Detail(props) {
  const [isUpdatingUserDeadlines, setUpdatingUserDeadlines] = React.useState(false)
  const [isImporting, setImporting] = React.useState(false)
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [copyMessage, setCopyMessage] = React.useState('')
  const [deleteMessage, setDeleteMessage] = React.useState('')
  // refの変更を強制的に描画したい時に使うもの
  const [toggle, changeToggle] = React.useState(false)

  const refs_user_deadline = React.useMemo(() => {
    const defaultDeadline = {'': {answerDeadline: React.createRef()}}
    return props.enqueteUsers.reduce((obj, key) => {
      const newObj = {...obj, [key.id]: {answerDeadline: React.createRef()}}
      if (props.userDeadlines[props.enquete.id] && props.userDeadlines[props.enquete.id][key.id]) {
        newObj[key.id].answerDeadline.current = moment(props.userDeadlines[props.enquete.id][key.id].answerDeadline)
      }
      return newObj
    }, defaultDeadline)
  }, [props.enquete, props.enqueteUsers, props.userDeadlines])

  const ref_to_copy = React.useRef(null)
  const ref_to_import = React.useRef({})
  const ref_to_import_ok = React.useRef('')
  const ref_to_confirm = React.useRef(null)
  const { readString } = usePapaParse()

  const userClass = React.useMemo(() => new UserClass(props.userInfo), [props.userInfo])
  const enabledUsers = props.enqueteUsers.filter(user => !user.deleted)

  // 下のuseEffect等の警告避け
  // こういう風にしないとuseEffect等の依存する変数（最終引数の配列）の中にprops自体を含めないとならなくなり、イベント発生が多くなりすぎる
  const initQandA = props.initQandA
  const initAnsweredDatetimes = props.initAnsweredDatetimes

  React.useEffect(() => {
    if (!userClass.canAggregate()) {
      return
    }
    async function getQandA(enqueteId, users) {
      await initQandA(enqueteId, users).catch(err => catchError(err, '質問回答取得処理（アンケート詳細）でエラー'))
    }
    getQandA(props.enquete.id, props.enqueteUsers)
  }, [userClass, props.enqueteUsers, props.enquete, initQandA])

  React.useEffect(() => {
    if (userClass.canAggregate()) {
      initAnsweredDatetimes(props.enquete.id)
    }
  }, [userClass, props.enquete, initAnsweredDatetimes])

  function copy() {
    ref_to_import_ok.current = ''
    ref_to_confirm.current = execToCopy
    setConfirmationMessage('コピーして良いですか？\n※コピーを実行すると現在このアンケートに設定されている質問はすべて消えます。')
  }

  async function execToCopy() {
    await _execToCopy().catch(err => catchError(err, 'アンケートコピー処理でエラー'))
  }

  async function _execToCopy() {
    setConfirmationMessage('')
    setCopyMessage('コピー中です')
    await deleteQuestions().catch(err => catchError(err, 'アンケート質問削除（コピー）時にエラー'))
    await getQuestions(ref_to_copy.current.value).then(async (res) => {
      const idMap = {}
      for (const question of res) {
        const oldId = question.id
        delete question.id
        delete question.children
        delete question.createdAt
        delete question.updatedAt
        delete question.__typename
        question.enqueteId = props.enquete.id
        if (question.parentId) {
          question.parentId = idMap[question.parentId]
        } else {
          delete question.parentId
        }
        if (!question.overview) {
          delete question.overview
        }
        await API.graphql(graphqlOperation(createEnqueteQuestion, {input: question})).then(res => {
          idMap[oldId] = res.data.createEnqueteQuestion.id
        }).catch(err => catchError(err, 'アンケート質問追加（コピー）時にエラー'))
      }
    }).catch(err => catchError(err, 'アンケート旧質問取得（コピー）時にエラー'))
    await getArrangedQuestions(props.enquete.id).then(props.setQuestions).catch(err => catchError(err, 'アンケート新質問取得（コピー）時にエラー'))
    setCopyMessage('コピーが完了しました')
  }

  async function deleteQuestions() {
    const oldQuestions = [...props.questions.items]
    for (const question of oldQuestions) {
      await API.graphql(graphqlOperation(deleteEnqueteQuestion, {input: {id: question.id}})).catch(err => catchError(err, 'アンケート質問削除時にエラー'))
    }
  }

  function remove() {
    ref_to_import_ok.current = ''
    ref_to_confirm.current = execToRemove
    setConfirmationMessage('削除して良いですか？')
  }

  async function execToRemove() {
    await _execToRemove().catch(err => catchError(err, 'アンケート削除処理でエラー'))
  }

  async function _execToRemove() {
    // 既に回答があるアンケートは削除できないので、ここで回答の削除処理は不要
    setConfirmationMessage('')
    setDeleteMessage('削除中です')
    if (props.userDeadlines[props.enquete.id]) {
      for (const deadlines of Object.values(props.userDeadlines[props.enquete.id])) {
        await API.graphql(graphqlOperation(deleteEnqueteUserDeadline, {input: {id: deadlines.id}})).catch(err => catchError(err, 'ユーザー回答期限削除（アンケート削除）時にエラー'))
      }
    }
    if (props.enqueteAnsweredDatetimes) {
      for (const datetimes of Object.values(props.enqueteAnsweredDatetimes)) {
        await API.graphql(graphqlOperation(deleteEnqueteUserAnswered, {input: {id: datetimes.id}})).catch(err => catchError(err, 'ユーザー回答日時削除（アンケート削除）時にエラー'))
      }
    }
    await deleteQuestions().catch(err => catchError(err, 'アンケート質問削除（アンケート削除）時にエラー'))
    API.graphql(graphqlOperation(deleteEnquete, {input: {id: props.enquete.id}})).catch(err => catchError(err, 'アンケート削除時にエラー'))
    props.setEnquetes(props.enquetes.filter(enquete => enquete.id !== props.enquete.id))
    setCopyMessage('')
    setDeleteMessage('')
    props.setEnquete({})
  }

  function userDatetimeOnClose(id, value) {
    if (!isDatetime(value)) {
      setErrorMessage('正しくない日時が入力されました。')
      return
    }
    refs_user_deadline[id ? id : ''].answerDeadline.current = moment(value)
    changeToggle(!toggle)
  }

  async function changeUserDatetime(userIds, isGroup) {
    await _changeUserDatetime(userIds, isGroup).catch(err => catchError(err, 'ユーザー回答期限変更処理でエラー'))
  }

  async function _changeUserDatetime(userIds, isGroup) {
    setUpdatingUserDeadlines(true)
    const isGlobal = typeof(userIds) !== 'string'
    const targetUserIds = isGlobal ? userIds : [userIds]
    const targetEnquetes = isGroup ? props.enquetes.filter(enquete => enquete.groupId === props.enquete.groupId) : [props.enquete]
    const targetEnqueteIds = targetEnquetes.map(enquete => enquete.id)
    for (const enqueteId of targetEnqueteIds) {
      for (const userId of targetUserIds) {
        await updateUserDatetime(enqueteId, userId, isGlobal).catch(err => catchError(err, 'ユーザー回答期限更新処理でエラー'))
      }
    }
    refs_user_deadline[''].answerDeadline.current = null
    await getUserDeadlines().then(res => {
      // @aws-amplify/ui-reactを2.16.0以降にアップデートすると、
      // 親コンポーネントのstateを更新する前に子コンポーネントのstateを更新しないと
      // メモリリークの警告が表示される
      setUpdatingUserDeadlines(false)
      props.setUserDeadlines(res)
    }).catch(err => catchError(err, 'ユーザー回答期限再取得時にエラー'))
  }

  async function updateUserDatetime(enqueteId, userId, isGlobal) {
    const newAnswerDeadlineRef = refs_user_deadline[isGlobal ? '' : userId].answerDeadline.current
    if (!newAnswerDeadlineRef) {
      return
    }
    const newAnswerDeadline = newAnswerDeadlineRef.toISOString(false)
    if (props.userDeadlines[enqueteId] && props.userDeadlines[enqueteId][userId]) {
      await API.graphql(graphqlOperation(updateEnqueteUserDeadline, {input: {
        id: props.userDeadlines[enqueteId][userId].id,
        answerDeadline: newAnswerDeadline,
      }})).catch(err => catchError(err, 'ユーザー回答期限更新時にエラー'))
    } else {
      await API.graphql(graphqlOperation(createEnqueteUserDeadline, {input: {
        enqueteId: enqueteId,
        userId: userId,
        answerDeadline: newAnswerDeadline,
      }})).catch(err => catchError(err, 'ユーザー回答期限登録（更新）時にエラー'))
    }
  }

  function listLinkOnClick(event) {
    event.preventDefault()
    props.setEnquete({})
  }

  async function downloadLinkOnClick(event) {
    await _downloadLinkOnClick(event).catch(err => catchError(err, 'アンケート回答ダウンロード処理でエラー'))
  }

  async function _downloadLinkOnClick(event) {
    event.preventDefault()
    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')
    const questionsToAnswer = props.questions.items.filter(question => question.overview)
    worksheet.columns = getCsvColumns(questionsToAnswer)
    worksheet.addRows(getCsvBody(questionsToAnswer))
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), await workbook.csv.writeBuffer()],
      {type: "text/csv;charset=utf-8"})
    saveAs(blob, `${props.enquete.group.name}_${props.enquete.title}_回答.csv`)
  }

  function getCsvColumns(questionsToAnswer) {
    return questionsToAnswer.reduce((obj, key) => {
      if (key.type === 'CHECKBOX') {
        const children = key.children.map(child => ({key: child.id, header: key.overview}))
        return [...obj, ...children]
      }
      return [...obj, {key: key.id, header: key.overview}]
    }, [
      {key: 'createdAt', header: '初回送信日時'},
      {key: 'updatedAt', header: '最新送信日時'},
      {key: 'sorter1', header: SorterColumnNames.sorter1},
      {key: 'sorter2', header: SorterColumnNames.sorter2},
      {key: 'sorter3', header: SorterColumnNames.sorter3},
      {key: 'email', header: 'メールアドレス'},
      {key: 'deleted', header: '削除済ユーザー'},
    ])
  }

  function getCsvBody(questionsToAnswer) {
    if (props.enqueteAnswers === null) {
      return []
    }
    return props.enqueteUsers.filter(user => props.enqueteAnswers[user.id]).sort(answeredUserSorter).map(
      user => questionsToAnswer.reduce((obj, key) => {
        if (props.enqueteAnswers[user.id][key.id]) {
          const answer = props.enqueteAnswers[user.id][key.id].answer
          if (key.type === 'CHECKBOX') {
            const answers = answer.split('\n')
            const cells = key.children.filter(child => answers.includes(child.description)).reduce(
              (obj2, key2) => ({...obj2, [key2.id]: key2.description}), {})
            return {...obj, ...cells}
          }
          obj[key.id] = answer
        }
        return obj
      }, {
        createdAt: props.enqueteAnsweredDatetimes[user.id] ? moment(props.enqueteAnsweredDatetimes[user.id].createdAt).format('YYYY/MM/DD HH:mm:ss') : '',
        updatedAt: props.enqueteAnsweredDatetimes[user.id] ? moment(props.enqueteAnsweredDatetimes[user.id].updatedAt).format('YYYY/MM/DD HH:mm:ss') : '',
        sorter1: user.sorter1,
        sorter2: user.sorter2,
        sorter3: userClass.isAdmin() ? user.sorter3 : '******',
        email: userClass.isAdmin() ? user.email : '******',
        deleted: user.deleted ? '削除済ユーザー' : '',
      })
    )
  }

  function processAnswerFile(file) {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      readString(reader.result.trim(), {
        worker: true,
        complete: results => {
          processAnswer(results)
        },
      })
    }
    reader.onerror = event => catchError(event, 'アンケート回答アップロード読み取り処理でエラー')
  }

  function processAnswer(csv) {
    try {
      _processAnswer(csv)
    } catch (err) {
      catchError(err, 'アンケート回答アップロード処理でエラー')
    }
  }

  function _processAnswer(csv) {
    const rowCount = csv.data.length
    const errors = []
    if (rowCount === 0) {
      setErrorMessage('ファイルが空です。')
      return
    }
    if (rowCount === 1) {
      errors.push('ファイルに回答行がありません。')
    }
    const headerRow = csv.data[0]
    const columnCount = headerRow.length
    const answerColumns = getAnswerColumns()
    const validColumns = validateImportingColumns(headerRow, columnCount, answerColumns, errors)
    if (validColumns.length === 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    const answeredUsers = validateImportingAnswers(csv, columnCount, answerColumns, validColumns, rowCount, errors)
    const answeredEmails = Object.keys(answeredUsers)
    const newAnswers = processImportingAnswers(answeredEmails, answeredUsers, errors)
    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    ref_to_import.current = newAnswers
    ref_to_confirm.current = execToImport
    let hasAlreadyAnswered = false
    const message = answeredEmails.map(email => {
      const isAlreadyAnswered = props.enqueteAnswers !== null && props.enqueteAnswers[answeredUsers[email].id]
      hasAlreadyAnswered = hasAlreadyAnswered || isAlreadyAnswered
      const hasNoAnswersForNecessity = answeredUsers[email].necessity.length > 0 ? '未回答の必須設問あり' : ''
      return `${email} : ${isAlreadyAnswered ? '既存の回答あり' : '新規の回答'} ${hasNoAnswersForNecessity}`
    }).join('\n')
    ref_to_import_ok.current = hasAlreadyAnswered ? '上書きする' : ''
    setConfirmationMessage(message)
  }

  function getAnswerColumns() {
    return props.questions.items.filter(question => question.overview).reduce((obj, key) => {
      if (key.type === 'CHECKBOX') {
        const children = key.children.map(child => ({
          id: key.id,
          overview: key.overview,
          question: true,
          value: child.description,
          necessity: key.necessity,
        }))
        return [...obj, ...children]
      }
      if (key.type === 'RADIO') {
        return [...obj, {
          id: key.id,
          overview: key.overview,
          question: true,
          value: key.children.map(child => child.description),
          necessity: key.necessity,
        }]
      }
      return [...obj, {id: key.id, overview: key.overview, question: true, necessity: key.necessity}]
    }, [
      {id: 'createdAt', overview: '初回送信日時'},
      {id: 'updatedAt', overview: '最新送信日時'},
      {id: 'sorter1', overview: SorterColumnNames.sorter1},
      {id: 'sorter2', overview: SorterColumnNames.sorter2},
      {id: 'sorter3', overview: SorterColumnNames.sorter3},
      {id: 'email', overview: 'メールアドレス'},
      {id: 'disabled', overview: '削除済ユーザー'},
    ])
  }

  function validateImportingColumns(headerRow, columnCount, answerColumns, errors) {
    const answerColumnLength = answerColumns.length
    const validColumns = []
    for (let i = 0; i < columnCount; i++) {
      if (i >= answerColumnLength) {
        errors.push(`設問の数より多いCSV内の${i+1}番目のカラム：${headerRow[i]} が存在します。`)
      } else if (`${headerRow[i]}` !== answerColumns[i].overview) {
        errors.push(`CSV内の${i+1}番目のカラム：${headerRow[i]} は ${answerColumns[i].overview} でなければなりません。`)
      } else {
        validColumns.push(i)
      }
    }
    for (let i = columnCount; i < answerColumnLength; i++) {
      errors.push(`設問：${answerColumns[i].overview} がCSVの${i+1}番目のカラムとして存在しません。`)
    }
    return validColumns
  }

  function validateImportingAnswers(csv, columnCount, answerColumns, validColumns, rowCount, errors) {
    const answererEmails = enabledUsers.map(user => user.email)
    const memberEmails = props.enqueteUsers.map(user => user.email)
    const userEmails = props.users.map(user => user.email)
    const answeredUsers = {}
    for (let i = 1; i < rowCount; i++) {
      const userRow = csv.data[i]
      const userRowLength = userRow.length
      if (userRowLength > columnCount) {
        errors.push(`${i+1}行目のカラム数が${userRowLength}です。${columnCount}以下でなければなりません。`)
      }
      let email = undefined
      let checkboxKey = undefined
      let checkboxOverview = undefined
      for (const j of validColumns) {
        const answer = userRow[j]
        if (checkboxKey && (!answerColumns[j].id || checkboxKey !== answerColumns[j].id)) {
          answeredUsers[email].necessity.push(checkboxOverview)
          checkboxKey = undefined
        }
        if (j === EMAIL_COLUMN) {
          if (!answer) {
            errors.push(`${i+1}行目のメールアドレスが空です。`)
            continue
          }
          email = answer
          if (!answererEmails.includes(email)) {
            if (memberEmails.includes(email)) {
              errors.push(`${email} は削除されています。`)
            } else if (userEmails.includes(email)) {
              errors.push(`${email} はこのアンケートの回答者ではありません。`)
            } else {
              errors.push(`${email} は登録されていません。`)
            }
            email = undefined
            continue
          }
          if (!answeredUsers[email]) {
            answeredUsers[email] = {}
          }
          if (answeredUsers[email].count) {
            answeredUsers[email].count += 1
          } else {
            answeredUsers[email].count = 1
          }
          answeredUsers[email].necessity = []
        } else if (j === EMAIL_COLUMN + 1) {
          if (answer) {
            errors.push(`${i+1}行目の削除済ユーザーは空でなければなりません。`)
          }
        } else if (email) {
          if (!answeredUsers[email].answers) {
            answeredUsers[email].answers = {}
          }
          if (answer) {
            if (!answerColumns[j].question) {
              continue
            }
            if (typeof(answerColumns[j].value) === 'string') {
              if (answerColumns[j].value !== answer) {
                errors.push(`${i+1}行目の ${answerColumns[j].overview} に対する回答 ${answer} は正しいチェックボックスの値ではありません。`)
              } else {
                if (!answeredUsers[email].answers[answerColumns[j].id]) {
                  answeredUsers[email].answers[answerColumns[j].id] = []
                }
                answeredUsers[email].answers[answerColumns[j].id].push(answer)
              }
            } else if (typeof(answerColumns[j].value) === 'object') {
              if (!answerColumns[j].value.includes(answer)) {
                errors.push(`${i+1}行目の ${answerColumns[j].overview} に対する回答 ${answer} はラジオボタンの選択肢に含まれていません。`)
              } else {
                answeredUsers[email].answers[answerColumns[j].id] = answer
              }
            } else {
              answeredUsers[email].answers[answerColumns[j].id] = answer
            }
            checkboxKey = undefined
          } else if (answerColumns[j].necessity) {
            if (typeof(answerColumns[j].value) === 'string') {
              if (!answeredUsers[email].answers[answerColumns[j].id]) {
                checkboxKey = answerColumns[j].id
                checkboxOverview = answerColumns[j].overview
              }
            } else {
              answeredUsers[email].necessity.push(answerColumns[j].overview)
            }
          }
        }
      }
      if (checkboxKey) {
        answeredUsers[email].necessity.push(checkboxOverview)
      }
    }
    return answeredUsers
  }

  function processImportingAnswers(answeredEmails, answeredUsers, errors) {
    const newAnswers = {}
    answeredEmails.forEach(email => {
      if (answeredUsers[email].count > 1) {
        errors.push(`${email} の回答が${answeredUsers[email].count}行あります。`)
      }
      const userId = props.enqueteUsers.filter(user => user.email === email)[0].id
      answeredUsers[email].id = userId
      newAnswers[userId] = Object.keys(answeredUsers[email].answers).map(questionId => {
        const answer = answeredUsers[email].answers[questionId]
        return {question: questionId, answer: typeof(answer) === 'object' ? answer.join('\n') : answer}
      })
    })
    return newAnswers
  }

  async function execToImport() {
    await _execToImport().catch(err => catchError(err, 'アンケート回答取り込み処理でエラー'))
  }

  async function _execToImport() {
    setImporting(true)
    setConfirmationMessage('')
    const curAnswers = {[props.enquete.id]: props.enqueteAnswers !== null ? deepcopy(props.enqueteAnswers) : {}}
    const newAnswers = ref_to_import.current
    const curAnsweredDatetimes = {[props.enquete.id]: deepcopy(props.enqueteAnsweredDatetimes)}
    const now = moment().toISOString(false)
    for (const userId of Object.keys(newAnswers)) {
      await registerAnswers(props.enquete.id, userId, curAnswers, newAnswers[userId]).catch(err => catchError(err, 'アンケート回答登録（取り込み）時にエラー'))
      if (newAnswers[userId].length === 0) {
        delete curAnswers[props.enquete.id][userId]
      }
      if (props.enqueteAnsweredDatetimes[userId] && props.enqueteAnsweredDatetimes[userId].later) {
        // 現在laterは使用されていないのでここには来ない
        await API.graphql(graphqlOperation(updateEnqueteUserAnswered, {input: {id: props.enqueteAnsweredDatetimes[userId].id, later: false, createdAt: now, updatedAt: now}})).then(res => {
          curAnsweredDatetimes[props.enquete.id][userId] = res.data.updateEnqueteUserAnswered
        }).catch(err => catchError(err, 'ユーザー回答日時初回更新（取り込み）時にエラー'))
      } else if (props.enqueteAnsweredDatetimes[userId]) {
        await API.graphql(graphqlOperation(updateEnqueteUserAnswered, {input: {id: props.enqueteAnsweredDatetimes[userId].id, updatedAt: now}})).then(res => {
          curAnsweredDatetimes[props.enquete.id][userId] = res.data.updateEnqueteUserAnswered
        }).catch(err => catchError(err, 'ユーザー回答日時更新（取り込み）時にエラー'))
      } else {
        await API.graphql(graphqlOperation(createEnqueteUserAnswered, {input: {enqueteId: props.enquete.id, userId: userId, later: false, createdAt: now, updatedAt: now}})).then(res => {
          curAnsweredDatetimes[props.enquete.id][userId] = res.data.createEnqueteUserAnswered
        }).catch(err => catchError(err, 'ユーザー回答日時登録（取り込み）時にエラー'))
      }
    }
    setImporting(false)
    props.setAnswers(curAnswers)
    props.setAnsweredDatetimes(curAnsweredDatetimes)
  }

  function answeredUserSorter(a, b) {
    if (props.enqueteAnsweredDatetimes) {
      const aDatetimes = props.enqueteAnsweredDatetimes[a.id]
      const bDatetimes = props.enqueteAnsweredDatetimes[b.id]
      if (aDatetimes && !bDatetimes) {
        return -1
      }
      if (!aDatetimes && bDatetimes) {
        return 1
      }
      if (aDatetimes && bDatetimes && aDatetimes.updatedAt !== bDatetimes.updatedAt) {
        return aDatetimes.updatedAt > bDatetimes.updatedAt ? -1 : 1
      }
    }
    return userSorter(a, b)
  }

  function closeConfirmationPopup() {
    setConfirmationMessage('')
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  function AnswerFileUpload() {
    // 以下の変数名は変えられなさそうなので名前空間を分けるために独立した関数の中に置く
    const onDrop = React.useCallback(acceptedFiles => acceptedFiles.map(processAnswerFile), []);
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, maxFiles: 1, disabled: isImporting })
    return (
      <div>
        <p className="bold">回答ファイルのアップロード</p>
        <div {...getRootProps({className: 'answer-file-upload'})}>
          <input {...getInputProps()} />
          <button type="button" onClick={open} disabled={isImporting}>回答ファイル選択</button>
          {isImporting ? <span> 取り込み中です</span> : (isDragActive ? <span> </span> : <span> ここに回答ファイルをドロップできます</span>)}
        </div>
      </div>
    )
  }

  function EnquetesSelect(props) {
    return (
      <select defaultValue={ref_to_copy.current ? ref_to_copy.current.value : null} ref={ref_to_copy}>
        {props.enquetes.filter(enquete => enquete.id !== props.enquete.id).map(enquete => <option key={enquete.id} value={enquete.id}>{enquete.title}</option>) }
      </select>
    )
  }

  function EnqueteNotAnsweredUser(props) {
    const initialValueOfDeadline = refs_user_deadline[props.user.id] && refs_user_deadline[props.user.id].answerDeadline.current ?
      refs_user_deadline[props.user.id].answerDeadline.current : moment(props.enquete.answerDeadline)
    const isNotChangedUserDeadline = refs_user_deadline[props.user.id] && refs_user_deadline[props.user.id].answerDeadline.current ?
      false : !(props.userDeadlines[props.enquete.id] && props.userDeadlines[props.enquete.id][props.user.id])
    return (
      <tr>
        <td>{props.user.sorter1}</td>
        <td>{props.user.sorter2}</td>
        {userClass.isAdmin() &&
        <td>{props.user.sorter3}</td>
        }
        {userClass.isAdmin() &&
        <td>{props.user.email}</td>
        }
        {userClass.isAdmin() &&
        <td><DateTime locale="ja" initialValue={initialValueOfDeadline} onClose={value => userDatetimeOnClose(props.user.id, value)} /></td>
        }
        {userClass.isAdmin() &&
        <td>
          <button disabled={isUpdatingUserDeadlines || isNotChangedUserDeadline} onClick={() => changeUserDatetime(props.user.id, false)}>このアンケートだけ</button>
          <button disabled={isUpdatingUserDeadlines || isNotChangedUserDeadline} onClick={() => changeUserDatetime(props.user.id, true)}>グループ内のアンケートも</button>
        </td>
        }
      </tr>
    )
  }

  function EnqueteUserAnswers(props) {
    const className = `admin-answers-table${props.user.deleted ? ' admin-answers-deleted-table' : ''}`
    return (
      <table className={className}>
        <tbody>
          <tr>
            <th>最新送信日時</th>
            <td>{props.enqueteAnsweredDatetimes ? moment(props.enqueteAnsweredDatetimes.updatedAt).format('YYYY/MM/DD HH:mm:ss') : ''}</td>
          </tr>
          <tr>
            <th>{SorterColumnNames.sorter1}</th>
            <td>{props.user.sorter1}</td>
          </tr>
          <tr>
            <th>{SorterColumnNames.sorter2}</th>
            <td>{props.user.sorter2}</td>
          </tr>
          {userClass.isAdmin() &&
          <tr>
            <th>{SorterColumnNames.sorter3}</th>
            <td>{props.user.sorter3}</td>
          </tr>
          }
          {userClass.isAdmin() &&
          <tr>
            <th>メールアドレス</th>
            <td>{props.user.email}</td>
          </tr>
          }
          {props.questions.items && props.questions.items.filter(question => question.overview).map(question => {
            return (
              <tr key={`${props.user.id}${question.id}`}>
                <th>{question.overview}</th>
                <td>{props.enqueteAnswers !== null && props.enqueteAnswers[props.user.id] && props.enqueteAnswers[props.user.id][question.id] ? props.enqueteAnswers[props.user.id][question.id].answer : ''}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const answeredUsers = props.enqueteUsers.filter(user => props.enqueteAnswers !== null ? props.enqueteAnswers[user.id] : null).sort(answeredUserSorter)
  const answeredUserIds = answeredUsers.map(user => user.id)
  const answeredUserCount = answeredUserIds.length
  const notAnsweredUsers = enabledUsers.filter(user => !answeredUserIds.includes(user.id)).sort(userSorter)
  const notAnsweredUserIds = notAnsweredUsers.map(user => user.id)
  const answeredEnabledUsers = enabledUsers.filter(user => props.enqueteAnswers !== null ? props.enqueteAnswers[user.id] : null).sort(answeredUserSorter)
  const enqueteDeadline = moment(props.enquete.answerDeadline)
  const initialValueOfDeadline = refs_user_deadline[''].answerDeadline.current ? refs_user_deadline[''].answerDeadline.current : enqueteDeadline
  const isNotChangedUserDeadline = refs_user_deadline[''].answerDeadline.current ? false : !props.userDeadlines[props.enquete.id]
  return (
    <div>
      <h1>{`アンケート：${props.enquete.title} の詳細`}</h1>
      <p><a href="#!" onClick={listLinkOnClick}>一覧に戻る</a></p>
      {props.questions.items && <p><Link to="/answer">アンケート設問イメージへ</Link></p>}
      {userClass.isAdmin() && props.questions.items && props.questions.items.length > 0 && <p><Link to="/additions">アンケート送信設定へ</Link></p>}
      {props.questions.items && props.questions.items.length === 0 && <p>質問はまだありません。</p>}
      {userClass.isAdmin() &&
      <AnswerFileUpload />
      }
      <div>
        <p className="bold">未回答者の一覧</p>
        <p>アンケートのグループは{props.enquete.group.name}、回答期限は{enqueteDeadline.format('YYYY/MM/DD HH:mm')}です。</p>
        {userClass.isAggregator() &&
        <p>同じ案件名が複数行表示されている場合は、複数の担当者が未回答であることを示します。<br />同じ案件名で未回答者・回答者どちらもいらっしゃる場合は「未回答者の一覧」「回答一覧」どちらにも表示されます。</p>
        }
        {props.enqueteAnswers !== null && notAnsweredUsers.length > 0 && userClass.isAdmin() &&
        <div>
          <p>未回答者の回答期限を変更することができます。</p>
          <DateTime locale="ja" initialValue={initialValueOfDeadline} onClose={value => userDatetimeOnClose(null, value)} />
          <div>
            <button disabled={isUpdatingUserDeadlines || isNotChangedUserDeadline} onClick={() => changeUserDatetime(notAnsweredUserIds, false)}>未回答者全てのこのアンケートの回答期限を変更</button>
            <button disabled={isUpdatingUserDeadlines || isNotChangedUserDeadline} onClick={() => changeUserDatetime(notAnsweredUserIds, true)}>未回答者全てのグループ内のアンケート全ての回答期限を変更</button>
          </div>
          <p className="admin-enquete-updating-message">{isUpdatingUserDeadlines ? '更新中です' : ''}</p>
        </div>
        }
        {props.enqueteAnswers !== null && notAnsweredUsers.length === 0 && <p>未回答者はいません。</p>}
      </div>
      {props.enqueteAnswers !== null && notAnsweredUsers.length > 0 &&
      <table className="admin-not-answered-table">
        <tbody>
          <tr>
            <th>{SorterColumnNames.sorter1}</th>
            <th>{SorterColumnNames.sorter2}</th>
            {userClass.isAdmin() &&
            <th>{SorterColumnNames.sorter3}</th>
            }
            {userClass.isAdmin() &&
            <th>メールアドレス</th>
            }
            {userClass.isAdmin() &&
            <th>回答期限</th>
            }
            {userClass.isAdmin() &&
            <th>操作</th>
            }
          </tr>
          {notAnsweredUsers.map(user => <EnqueteNotAnsweredUser key={user.id} enquete={props.enquete} user={user} userDeadlines={props.userDeadlines} />)}
        </tbody>
      </table>
      }
      <div><p className="bold">回答一覧</p></div>
      {props.special && props.enqueteAnswers !== null && answeredEnabledUsers.length > 0 && userClass.isAdmin() &&
      <table className="admin-not-answered-table">
        <tbody>
          <tr>
            <th>{SorterColumnNames.sorter1}</th>
            <th>{SorterColumnNames.sorter2}</th>
            <th>{SorterColumnNames.sorter3}</th>
            <th>メールアドレス</th>
            <th>回答期限</th>
            <th>操作</th>
          </tr>
          {answeredEnabledUsers.map(user => <EnqueteNotAnsweredUser key={user.id} enquete={props.enquete} user={user} userDeadlines={props.userDeadlines} />)}
        </tbody>
      </table>
      }
      {answeredUserCount > 0 && <p><a href="#!" onClick={downloadLinkOnClick}>回答一覧のCSVダウンロード</a></p>}
      {answeredUsers.map(user => <EnqueteUserAnswers key={user.id} user={user} enqueteAnsweredDatetimes={props.enqueteAnsweredDatetimes ? props.enqueteAnsweredDatetimes[user.id] : null} questions={props.questions} enqueteAnswers={props.enqueteAnswers} />)}
      {props.enqueteUsers.length > 0 && props.enqueteAnswers === null &&
      <p>回答を読み込み中です。</p>
      }
      {(props.enqueteUsers.length === 0 || (props.enqueteAnswers !== null && answeredUserCount === 0)) && userClass.isAdmin() &&
      <p>回答はまだありません。この場合、質問の編集（設問イメージページで行うことができます）、他のアンケートからの質問のコピー、及びアンケートの削除が可能です。<br />
        <span className="color-red">※コピーを実行すると現在このアンケートに設定されている質問はすべて消えます。</span><br />
        <EnquetesSelect enquete={props.enquete} enquetes={props.enquetes} /><button disabled={!!copyMessage} onClick={copy}>質問のコピー</button> {copyMessage}<br /><br />
        <button disabled={!!deleteMessage} onClick={remove}>アンケートの削除</button> {deleteMessage}
      </p>
      }
      {(props.enqueteUsers.length === 0 || (props.enqueteAnswers !== null && answeredUserCount === 0)) && userClass.isAggregator() &&
      <p>回答はまだありません。</p>
      }
      <ConfirmationPopup message={confirmationMessage} exec={ref_to_confirm.current} okMessage={ref_to_import_ok.current} closePopup={closeConfirmationPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
    </div>
  )
}

export default Detail
