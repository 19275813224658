import React from 'react'
import Modal from 'react-modal'

const modalStyle = {
  overlay: {
    position: "fixed",
    zIndex: 3,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)"
  },
  content: {
    whiteSpace: 'pre-line',
    position: "absolute",
    top: "5rem",
    left: "5rem",
    right: "5rem",
    height: "5rem",
    backgroundColor: '#ff90cc',
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

function FatalPopup(props) {
  return (
    <Modal isOpen={!!props.error} onRequestClose={props.closePopup} shouldCloseOnOverlayClick={true} ariaHideApp={false} style={modalStyle}>
      <div>{props.error && props.error.toString()}</div>
    </Modal>
)
}

export default FatalPopup
