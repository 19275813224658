import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  Amplify,
} from 'aws-amplify'
import awsExports from "./aws-exports"
import FatalPopup from './FatalPopup'
Amplify.configure(awsExports)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {error: null, errorInfo: null}
  }
  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
  }
  render() {
    return (
      <>
        <FatalPopup closePopup={() => {this.setState({error: null, errorInfo: null})}} error={this.state.error} />
        {this.props.children}
      </>
    )
  }
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
