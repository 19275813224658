import './App.css'
import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  useHistory,
} from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import DateTime from 'react-datetime'
import "react-datetime/css/react-datetime.css"
import 'moment/locale/ja'
import moment from 'moment'
import deepcopy from 'lodash/cloneDeep'
import ConfirmationPopup from './ConfirmationPopup'
import ErrorPopup from './ErrorPopup'
import {
  createEnquete,
  updateEnquete,
  deleteEnqueteUserDeadline,
} from './graphql/mutations'
import {
  getErrorMessage,
} from './util/common'
import {
  isDatetime,
} from './util/validator'
import {
  UserClass,
} from './Users'

export function enqueteSorter(a, b) {
  if (a.answerDeadline === b.answerDeadline) {
    return 0
  }
  return a.answerDeadline < b.answerDeadline ? 1 : -1
}

function Enquetes(props) {
  const [sorted, setSorted] = React.useState([])
  const [isChanged, setChanged] = React.useState(false)
  const [isBlocked, setBlocked] = React.useState(false)
  const [updateMessage, setUpdateMessage] = React.useState('')
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const refs_enquetes = React.useMemo(() => {
    return props.enquetes.reduce((obj, key) => ({...obj, [key.id]: {
      group: React.createRef(),
      title: React.createRef(),
      notice: React.createRef(),
      publish: React.createRef(),
      answerDeadline: React.createRef(),
      viewDeadline: React.createRef(),
      locked: React.createRef(),
    }}), {})
  }, [props.enquetes])
  const now = moment()
  const aMonthLater = now.clone().add(1, 'month')
  const refs_new = React.useMemo(() => ({
    group: React.createRef(),
    title: React.createRef(),
    notice: React.createRef(),
    publish: React.createRef(),
    answerDeadline: React.createRef(),
    viewDeadline: React.createRef(),
    locked: React.createRef(),
  }), [])
  const ref_to_import_ok = React.useRef('')
  const ref_to_confirm = React.useRef(null)
  const history = useHistory()

  const userClass = React.useMemo(() => new UserClass(props.userInfo), [props.userInfo])

  // 複雑なことをやっているが、新規と変更に対する後述の仕様を達成するための処理
  const [sorterState, sorterAction] = React.useReducer((state, action) => {
    if (action) {
      return true
    } else if (state) {
      const sortee = [...props.enquetes]
      sortee.sort(enqueteSorter)
      setSorted(sortee.map(user => user.id))
    } else if (sorted.length > 0) {
      // 変更では再訪問するまで位置は変わらない
      const newEnqueteIds = props.enquetes.map(enquete => enquete.id)
      const revised = sorted.filter(id => newEnqueteIds.includes(id))
      // 新規は再訪問するまで上に積んでいく
      setSorted(newEnqueteIds.filter(id => !revised.includes(id)).concat(revised))
    } else {
      setSorted(props.enquetes.map(user => user.id))
    }
    return false
  }, false)

  React.useEffect(() => {
    sorterAction(true)
  }, [])

  React.useEffect(() => {
    if (props.enquetes.length > 0) {
      sorterAction(false)
    }
  }, [props.enquetes, sorterState])

  // 下のuseEffect等の警告避け
  // こういう風にしないとuseEffect等の依存する変数（最終引数の配列）の中にprops自体を含めないとならなくなり、イベント発生が多くなりすぎる
  const setEnquete = props.setEnquete

  // 未変更の修正がある場合のページ遷移するかどうかの確認
  // react-router v5にはあるがv6に（まだ）ない機能を使用しているので、当面react-routerをアップデートできない
  // history.blockが発動する度に「A history supports only one prompt at a time」という警告が出るが解決方法は不明
  // また、useHistoryが<Router>内でしか使えないのでこちらは各ページで処理を行っている
  const unblock = React.useMemo(() => history.block(isBlocked ? '更新を反映する前にページを離れようとしています。よろしいですか？' : true), [isBlocked, history])
  React.useEffect(() => () => unblock(), [unblock])

  // こちらは未変更の修正がある場合のページ再読み込みあるいはタブを閉じるかの確認
  React.useEffect(() => {
    window.onbeforeunload = isBlocked ? (e => e.returnValue = true) : null
  }, [isBlocked])

  function update() {
    try {
      _update()
    } catch (err) {
      catchError(err, 'アンケート更新処理でエラー')
    }
  }

  function _update() {
    setUpdateMessage('')
    const updatedEnquetes = []
    const updatedEnqueteRelations = {}
    const updatedUserDeadlines = deepcopy(props.userDeadlines)
    const errors = []
    props.enquetes.forEach(enquete => {
      const updatedEnquete = {...enquete}
      updatedEnquete.groupId = refs_enquetes[enquete.id].group.current.value
      updatedEnquete.title = refs_enquetes[enquete.id].title.current.value
      updatedEnquete.notice = refs_enquetes[enquete.id].notice.current.value
      updatedEnquete.publish = refs_enquetes[enquete.id].publish.current ? refs_enquetes[enquete.id].publish.current.toISOString(false) : enquete.publish
      updatedEnquete.answerDeadline = refs_enquetes[enquete.id].answerDeadline.current ? refs_enquetes[enquete.id].answerDeadline.current.toISOString(false) : enquete.answerDeadline
      updatedEnquete.viewDeadline = refs_enquetes[enquete.id].viewDeadline.current ? refs_enquetes[enquete.id].viewDeadline.current.toISOString(false) : enquete.viewDeadline
      updatedEnquete.locked = refs_enquetes[enquete.id].locked.current.checked
      if (updatedEnquete.publish >= updatedEnquete.answerDeadline) {
        errors.push(`${updatedEnquete.title} の回答期限日時は公開時刻より後である必要があります。`)
      }
      if (updatedEnquete.answerDeadline > updatedEnquete.viewDeadline) {
        updatedEnquete.viewDeadline = updatedEnquete.answerDeadline
      }
      updatedEnquetes.push(updatedEnquete)
      updatedEnqueteRelations[enquete.id] = updatedEnquete
    })
    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    setChanged(false)
    setBlocked(false)
    props.enquetes.forEach(enquete => {
      const updatedEnquete = updatedEnqueteRelations[enquete.id]
      if (enquete.groupId !== updatedEnquete.groupId || enquete.title !== updatedEnquete.title ||
          enquete.notice !== updatedEnquete.notice || enquete.publish !== updatedEnquete.publish ||
          enquete.answerDeadline !== updatedEnquete.answerDeadline ||
          enquete.viewDeadline !== updatedEnquete.viewDeadline ||
          enquete.locked !== updatedEnquete.locked) {
        API.graphql(graphqlOperation(updateEnquete, {input: {
          id: enquete.id,
          groupId: updatedEnquete.groupId,
          title: updatedEnquete.title,
          notice: updatedEnquete.notice,
          publish: updatedEnquete.publish,
          answerDeadline: updatedEnquete.answerDeadline,
          viewDeadline: updatedEnquete.viewDeadline,
          locked: updatedEnquete.locked,
        }})).catch(err => catchError(err, 'アンケート更新時にエラー'))
      }
      if (enquete.answerDeadline !== updatedEnquete.answerDeadline && updatedUserDeadlines[enquete.id]) {
        const enqueteAnswerDeadline = Date.parse(updatedEnquete.answerDeadline)
        const keysToDelete = []
        Object.keys(updatedUserDeadlines[enquete.id]).forEach(key => {
          const userAnswerDeadline = Date.parse(updatedUserDeadlines[enquete.id][key].answerDeadline)
          if (enqueteAnswerDeadline > userAnswerDeadline) {
            API.graphql(graphqlOperation(deleteEnqueteUserDeadline, {input: {id: updatedUserDeadlines[enquete.id][key].id}})).catch(err => catchError(err, 'ユーザー回答期限削除（アンケート更新）時にエラー'))
            keysToDelete.push(key)
          }
        })
        keysToDelete.forEach(key => {delete updatedUserDeadlines[enquete.id][key]})
      }
    })
    props.setEnquetes(updatedEnquetes)
    props.setUserDeadlines(updatedUserDeadlines)
    setUpdateMessage('更新が完了しました')
  }

  function add() {
    try {
      _add()
    } catch (err) {
      catchError(err, 'アンケート登録処理でエラー')
    }
  }

  function _add() {
    const errors = []
    const publish = refs_new.publish.current ? refs_new.publish.current.toISOString(false) : now.toISOString(false)
    const answerDeadline = refs_new.answerDeadline.current ? refs_new.answerDeadline.current.toISOString(false) : aMonthLater.toISOString(false)
    let viewDeadline = refs_new.viewDeadline.current ? refs_new.viewDeadline.current.toISOString(false) : aMonthLater.toISOString(false)
    if (!refs_new.title.current.value) {
      errors.push('タイトルを入力する必要があります。')
    }
    if (publish >= answerDeadline) {
      errors.push('回答期限日時は公開時刻より後である必要があります。')
    }
    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    if (answerDeadline > viewDeadline) {
      viewDeadline = answerDeadline
    }
    if (!isChanged) {
      setBlocked(false)
    }
    API.graphql(graphqlOperation(createEnquete, {input: {
      groupId: refs_new.group.current.value,
      title: refs_new.title.current.value,
      notice: refs_new.notice.current.value,
      publish: publish,
      answerDeadline: answerDeadline,
      viewDeadline: viewDeadline,
      locked: refs_new.locked.current.checked,
    }})).then(res => {
      const addedEnquete = res.data.createEnquete
      props.setEnquetes([...props.enquetes, addedEnquete])
      // groupは空で登録する選択肢はないので空にしない
      refs_new.title.current.value = ''
      refs_new.notice.current.value = ''
      refs_new.publish.current = now.clone()
      refs_new.answerDeadline.current = aMonthLater.clone()
      refs_new.viewDeadline.current = aMonthLater.clone()
      refs_new.locked.current.checked = false
    }).catch(err => catchError(err, 'アンケート登録時にエラー'))
  }

  const lineAdded = React.useCallback(() => {
    setBlocked(true)
    setUpdateMessage('')
  }, [])

  const lineModified = React.useCallback(() => {
    setChanged(true)
    lineAdded()
  }, [lineAdded])

  const datetimeOnClose = React.useCallback((id, item, value) => {
    if (!isDatetime(value)) {
      setErrorMessage('正しくない日時が入力されました。')
      return
    }
    if (id) {
      refs_enquetes[id][item].current = value
    } else {
      refs_new[item].current = value
    }
  }, [refs_enquetes, refs_new])

  function closeConfirmationPopup() {
    setConfirmationMessage('')
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  const detailLinkOnClick = React.useCallback((event, enquete) => {
    event.preventDefault()
    setEnquete(enquete)
    history.push('/')
  }, [setEnquete, history])

  // onChangeイベントでフォーカスを失わないようにするためにはuseCallbackを使ってメモ化する必要がある
  // 依存する変数（第2引数の[]に指定する変数）もuseCallbackやuseMemoでメモ化する
  const EnqueteLine = React.useCallback(props => {
    const enquete = props.enquetes.filter(enquete => enquete.id === props.enqueteId)[0]
    const refs_enquete = refs_enquetes[props.enqueteId]
    if (typeof(refs_enquete) === 'undefined') {
      return null
    }
    const enqueteGroup = refs_enquete.group.current ? refs_enquete.group.current.value : enquete.groupId
    const title = refs_enquete.title.current ? refs_enquete.title.current.value : enquete.title
    const notice = refs_enquete.notice.current ? refs_enquete.notice.current.value : enquete.notice
    const publish = refs_enquete.publish.current ? refs_enquete.publish.current : Date.parse(enquete.publish)
    const answerDeadline = refs_enquete.answerDeadline.current ? refs_enquete.answerDeadline.current : Date.parse(enquete.answerDeadline)
    const viewDeadline = refs_enquete.viewDeadline.current ? refs_enquete.viewDeadline.current : Date.parse(enquete.viewDeadline)
    const locked = refs_enquete.locked.current ? refs_enquete.locked.current.checked : !!enquete.locked
    return (
      <tr>
        <td><a href="/" onClick={event => detailLinkOnClick(event, enquete)}>詳細</a></td>
        {userClass.isAdmin() &&
        <td>
          <select defaultValue={enqueteGroup} onChange={lineModified} ref={refs_enquete.group}>
            {props.groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>) }
          </select>
        </td>
        }
        {userClass.isAggregator() &&
        <td>{props.groups.filter(group => group.id === enqueteGroup)[0].name}</td>
        }
        {userClass.isAdmin() &&
        <td><input type="text" defaultValue={title} onChange={lineModified} ref={refs_enquete.title} /></td>
        }
        {(userClass.isAggregator() || userClass.isViewer()) &&
        <td>{title}</td>
        }
        {userClass.isAdmin() &&
        <td><TextareaAutosize defaultValue={notice} onChange={lineModified} ref={refs_enquete.notice} /></td>
        }
        {userClass.isAdmin() &&
        <td><DateTime locale="ja" initialValue={publish} onClose={value => datetimeOnClose(props.enqueteId, 'publish', value)} onChange={lineModified} /></td>
        }
        {userClass.isAggregator() &&
        <td>{moment(publish).format('YYYY/MM/DD HH:mm')}</td>
        }
        {userClass.isAdmin() &&
        <td><DateTime locale="ja" initialValue={answerDeadline} onClose={value => datetimeOnClose(props.enqueteId, 'answerDeadline', value)} onChange={lineModified} /></td>
        }
        {userClass.isAggregator() &&
        <td>{moment(answerDeadline).format('YYYY/MM/DD HH:mm')}</td>
        }
        {userClass.isAdmin() &&
        <td><DateTime locale="ja" initialValue={viewDeadline} onClose={value => datetimeOnClose(props.enqueteId, 'viewDeadline', value)} onChange={lineModified} /></td>
        }
        {userClass.isAggregator() &&
        <td>{moment(viewDeadline).format('YYYY/MM/DD HH:mm')}</td>
        }
        {userClass.isAdmin() &&
        <td><input type="checkbox" defaultChecked={locked} onChange={lineModified} ref={refs_enquete.locked} /></td>
        }
      </tr>
    )
  }, [detailLinkOnClick, lineModified, datetimeOnClose, userClass, refs_enquetes])

  const groups = props.groups.length > 0 ? props.groups : props.userInfo.groups.items.map(group => ({id: group.groupId, name: group.group.name}))
  return (
    <div>
      {userClass.canAggregate() &&
      <h1>アンケート管理{props.enquete.title ? `：${props.enquete.title}` : ''}</h1>
      }
      {userClass.isViewer() &&
      <h1>アンケート一覧{props.enquete.title ? `：${props.enquete.title}` : ''}</h1>
      }
      <div>
        {userClass.isAdmin() &&
        <p><button className="admin-update" disabled={!isChanged} onClick={update}>変更反映</button> {updateMessage}</p>
        }
        <div className="admin-table-outer">
          <table className="admin-table">
            <tbody>
            <tr>
              <th />
              {userClass.canAggregate() &&
              <th>グループ</th>
              }
              <th>タイトル</th>
              {userClass.isAdmin() &&
              <th>告知</th>
              }
              {userClass.canAggregate() &&
              <th>公開日時</th>
              }
              {userClass.canAggregate() &&
              <th>回答期限日時</th>
              }
              {userClass.canAggregate() &&
              <th>閲覧期限日時</th>
              }
              {userClass.isAdmin() &&
              <th>期間外閲覧</th>
              }
            </tr>
            {userClass.isAdmin() &&
            <tr>
              <td className="admin-move"><button className="admin-line-button" onClick={add}>追加</button></td>
              <td>
                <select onChange={lineAdded} ref={refs_new.group}>
                  { groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>) }
                </select>
              </td>
              <td><input type="text" onChange={lineAdded} ref={refs_new.title} /></td>
              <td><TextareaAutosize onChange={lineAdded} ref={refs_new.notice} /></td>
              <td><DateTime locale="ja" initialValue={now.clone()} onClose={value => datetimeOnClose(null, 'publish', value)} onChange={lineAdded} /></td>
              <td><DateTime locale="ja" initialValue={aMonthLater.clone()} onClose={value => datetimeOnClose(null, 'answerDeadline', value)} onChange={lineAdded} /></td>
              <td><DateTime locale="ja" initialValue={aMonthLater.clone()} onClose={value => datetimeOnClose(null, 'viewDeadline', value)} onChange={lineAdded} /></td>
              <td><input type="checkbox" onChange={lineAdded} ref={refs_new.locked} /></td>
            </tr>
            }
            {sorted.map(enqueteId => {
              return <EnqueteLine key={enqueteId} userInfo={props.userInfo} enqueteId={enqueteId} enquetes={props.enquetes} groups={groups} />
            })}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmationPopup message={confirmationMessage} exec={ref_to_confirm.current} okMessage={ref_to_import_ok.current} closePopup={closeConfirmationPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
    </div>
  )
}

export default Enquetes
