export function isES() {
  return process.env.REACT_APP_ES_MODE && process.env.REACT_APP_ES_MODE === '1'
}

export function getErrorMessage(err, message) {
  if (err.errors) {
    // API.graphqlの場合
    return `${message}\n${err.errors.map(error => error.message).join('\n')}`
  }
  let happened = ''
  if (err.stack) {
    const stack = err.stack.split('\n')
    happened = stack.length > 1 ? stack[1] : ''
  }
  return `${message}\n${err.message}\n${happened.split('(')[0]}`
}

export const multiSelectI18n = {
  'allItemsAreSelected': 'すべて',
  'clearSearch': 'Clear Search',
  'noOptions': 'No options',
  'search': '検索',
  'selectAll': 'すべて選択',
  'selectSomeItems': '選択可能',
}
