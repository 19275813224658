/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sorter1
      sorter2
      sorter3
      email
      class
      groups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquetes {
        items {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMtMUserGroup = /* GraphQL */ `
  query GetMtMUserGroup($id: ID!) {
    getMtMUserGroup(id: $id) {
      id
      userId
      groupId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMtMUserGroups = /* GraphQL */ `
  query ListMtMUserGroups(
    $filter: ModelMtMUserGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMtMUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        groupId
        user {
          id
          sorter1
          sorter2
          sorter3
          email
          class
          deleted
          createdAt
          updatedAt
          __typename
        }
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnquete = /* GraphQL */ `
  query GetEnquete($id: ID!) {
    getEnquete(id: $id) {
      id
      groupId
      group {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      questions {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      title
      notice
      publish
      answerDeadline
      viewDeadline
      locked
      emailFrom
      emailSubject
      emailBody
      emailSendAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnquetes = /* GraphQL */ `
  query ListEnquetes(
    $filter: ModelEnqueteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnquetes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        questions {
          nextToken
          __typename
        }
        title
        notice
        publish
        answerDeadline
        viewDeadline
        locked
        emailFrom
        emailSubject
        emailBody
        emailSendAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteQuestion = /* GraphQL */ `
  query GetEnqueteQuestion($id: ID!) {
    getEnqueteQuestion(id: $id) {
      id
      enqueteId
      parentId
      children {
        items {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      overview
      description
      type
      necessity
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteQuestions = /* GraphQL */ `
  query ListEnqueteQuestions(
    $filter: ModelEnqueteQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteAnswer = /* GraphQL */ `
  query GetEnqueteAnswer($id: ID!) {
    getEnqueteAnswer(id: $id) {
      id
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      enqueteId
      enqueteQuestionId
      enqueteQuestion {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      answer
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listEnqueteAnswers = /* GraphQL */ `
  query ListEnqueteAnswers(
    $filter: ModelEnqueteAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          sorter1
          sorter2
          sorter3
          email
          class
          deleted
          createdAt
          updatedAt
          __typename
        }
        enqueteId
        enqueteQuestionId
        enqueteQuestion {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        answer
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteUserDeadline = /* GraphQL */ `
  query GetEnqueteUserDeadline($id: ID!) {
    getEnqueteUserDeadline(id: $id) {
      id
      enqueteId
      enquete {
        id
        groupId
        group {
          id
          name
          createdAt
          updatedAt
          __typename
        }
        questions {
          nextToken
          __typename
        }
        title
        notice
        publish
        answerDeadline
        viewDeadline
        locked
        emailFrom
        emailSubject
        emailBody
        emailSendAt
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      answerDeadline
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteUserDeadlines = /* GraphQL */ `
  query ListEnqueteUserDeadlines(
    $filter: ModelEnqueteUserDeadlineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteUserDeadlines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        enquete {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          sorter1
          sorter2
          sorter3
          email
          class
          deleted
          createdAt
          updatedAt
          __typename
        }
        answerDeadline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteUserAnswered = /* GraphQL */ `
  query GetEnqueteUserAnswered($id: ID!) {
    getEnqueteUserAnswered(id: $id) {
      id
      enqueteId
      userId
      later
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteUserAnswereds = /* GraphQL */ `
  query ListEnqueteUserAnswereds(
    $filter: ModelEnqueteUserAnsweredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteUserAnswereds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        later
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteEmailUser = /* GraphQL */ `
  query GetEnqueteEmailUser($id: ID!) {
    getEnqueteEmailUser(id: $id) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteEmailUsers = /* GraphQL */ `
  query ListEnqueteEmailUsers(
    $filter: ModelEnqueteEmailUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteEmailUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteEmailSent = /* GraphQL */ `
  query GetEnqueteEmailSent($id: ID!) {
    getEnqueteEmailSent(id: $id) {
      id
      enqueteId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteEmailSents = /* GraphQL */ `
  query ListEnqueteEmailSents(
    $filter: ModelEnqueteEmailSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteEmailSents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeneralSetting = /* GraphQL */ `
  query GetGeneralSetting($id: ID!) {
    getGeneralSetting(id: $id) {
      id
      notificationEmail
      notifiedEmails
      notifiedChatUrl
      notificationSubject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGeneralSettings = /* GraphQL */ `
  query ListGeneralSettings(
    $filter: ModelGeneralSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeneralSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notificationEmail
        notifiedEmails
        notifiedChatUrl
        notificationSubject
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupSetting = /* GraphQL */ `
  query GetGroupSetting($id: ID!) {
    getGroupSetting(id: $id) {
      id
      groupId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroupSettings = /* GraphQL */ `
  query ListGroupSettings(
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        notifiedEmails
        notifiedChatUrl
        notificationContentType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteSetting = /* GraphQL */ `
  query GetEnqueteSetting($id: ID!) {
    getEnqueteSetting(id: $id) {
      id
      enqueteId
      notifiedEmails
      notifiedChatUrl
      notificationContentType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnqueteSettings = /* GraphQL */ `
  query ListEnqueteSettings(
    $filter: ModelEnqueteSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enqueteId
        notifiedEmails
        notifiedChatUrl
        notificationContentType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsersByClass = /* GraphQL */ `
  query ListUsersByClass(
    $class: UserClass
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByClass(
      class: $class
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sorter1
        sorter2
        sorter3
        email
        class
        groups {
          nextToken
          __typename
        }
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupByName = /* GraphQL */ `
  query GetGroupByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        users {
          nextToken
          __typename
        }
        enquetes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEnqueteQuestionByQuestion = /* GraphQL */ `
  query ListEnqueteQuestionByQuestion(
    $enqueteId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteQuestionByQuestion(
      enqueteId: $enqueteId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        parentId
        children {
          nextToken
          __typename
        }
        overview
        description
        type
        necessity
        order
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEnqueteAnswerByUpdatedAt = /* GraphQL */ `
  query ListEnqueteAnswerByUpdatedAt(
    $userId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnqueteAnswerByUpdatedAt(
      userId: $userId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          sorter1
          sorter2
          sorter3
          email
          class
          deleted
          createdAt
          updatedAt
          __typename
        }
        enqueteId
        enqueteQuestionId
        enqueteQuestion {
          id
          enqueteId
          parentId
          overview
          description
          type
          necessity
          order
          createdAt
          updatedAt
          __typename
        }
        answer
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserDeadlinesByEnqueteUser = /* GraphQL */ `
  query ListUserDeadlinesByEnqueteUser(
    $enqueteId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteUserDeadlineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDeadlinesByEnqueteUser(
      enqueteId: $enqueteId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        enquete {
          id
          groupId
          title
          notice
          publish
          answerDeadline
          viewDeadline
          locked
          emailFrom
          emailSubject
          emailBody
          emailSendAt
          createdAt
          updatedAt
          __typename
        }
        userId
        user {
          id
          sorter1
          sorter2
          sorter3
          email
          class
          deleted
          createdAt
          updatedAt
          __typename
        }
        answerDeadline
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserAnsweredByEnqueteUser = /* GraphQL */ `
  query ListUserAnsweredByEnqueteUser(
    $enqueteId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteUserAnsweredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAnsweredByEnqueteUser(
      enqueteId: $enqueteId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        later
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEmailUsersByEnquete = /* GraphQL */ `
  query ListEmailUsersByEnquete(
    $enqueteId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteEmailUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailUsersByEnquete(
      enqueteId: $enqueteId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEmailUsersByUser = /* GraphQL */ `
  query ListEmailUsersByUser(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteEmailUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailUsersByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listEmailSentByEnquete = /* GraphQL */ `
  query ListEmailSentByEnquete(
    $enqueteId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteEmailSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailSentByEnquete(
      enqueteId: $enqueteId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupSettingByGroup = /* GraphQL */ `
  query GetGroupSettingByGroup(
    $groupId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGroupSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupSettingByGroup(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        notifiedEmails
        notifiedChatUrl
        notificationContentType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnqueteSettingByEnquete = /* GraphQL */ `
  query GetEnqueteSettingByEnquete(
    $enqueteId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEnqueteSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEnqueteSettingByEnquete(
      enqueteId: $enqueteId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enqueteId
        notifiedEmails
        notifiedChatUrl
        notificationContentType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
