import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  useHistory,
} from "react-router-dom"
import { MultiSelect } from 'react-multi-select-component'
import 'moment/locale/ja'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { useDropzone } from 'react-dropzone'
import { usePapaParse } from 'react-papaparse'
import ConfirmationPopup from './ConfirmationPopup'
import ErrorPopup from './ErrorPopup'
import {
  createMtMUserGroup,
  createUser,
  deleteMtMUserGroup,
  updateUser,
  deleteEnqueteEmailUser,
} from './graphql/mutations'
import {
  listEmailUser,
} from './db/data'
import {
  getErrorMessage,
  multiSelectI18n,
} from './util/common'
import {
  isEmail,
} from './util/validator'

export const SorterColumnNames = {
  sorter1: '会社名',
  sorter2: '案件名',
  sorter3: '担当者名',
}

const classArray = [
  { id: 's0', value: 'ADMIN',  label: '管理者'  },
  { id: 's3', value: 'AGGREGATOR', label: '集計者' },
  { id: 's6', value: 'VIEWER', label: '閲覧者' },
  { id: 'u1', value: 'ANSWERER', label: '回答者' },
]
// const classMap = classArray.reduce((obj, key) => ({...obj, [key.value]: key.label}), {})

export class UserClass {

  constructor(userClass) {
    const type = typeof(userClass)
    if (type === 'string') {
      this.userClass = userClass
    } else if (type === 'undefined') {
      this.userClass = ''
    } else if (type === 'object' && userClass.hasOwnProperty('class')) {
      this.userClass = userClass.class
      this.groups = userClass.groups.items
    } else {
      this.userClass = ''
    }
  }

  static DefaultUserClass = classArray.filter(cls => cls.id === 'u1')[0].value
  static FirstUserClass = classArray.filter(cls => cls.id === 's0')[0].value

  isAdmin() {
    if (this.userClass === classArray.filter(cls => cls.id === 's0')[0].value) {
      return true
    }
    return false
  }

  isAggregator() {
    if (this.userClass === classArray.filter(cls => cls.id === 's3')[0].value) {
      return true
    }
    return false
  }

  canAggregate() {
    if (classArray.filter(cls => ['s0', 's3'].includes(cls.id)).map(cls => cls.value).includes(this.userClass)) {
      return true
    }
    return false
  }

  isViewer() {
    if (this.userClass === classArray.filter(cls => cls.id === 's6')[0].value) {
      return true
    }
    return false
  }

  isStaff() {
    if (classArray.filter(cls => cls.id.startsWith('s')).map(cls => cls.value).includes(this.userClass)) {
      return true
    }
    return false
  }

  isAnswerer() {
    if (this.userClass === classArray.filter(cls => cls.id === 'u1')[0].value) {
      return true
    }
    return false
  }

  belongsToAllGroups() {
    if (this.groups.length === 0) {
      return true
    }
    return false
  }

  belongsToGroup(id) {
    if (this.belongsToAllGroups() || this.groups.some(group => group.groupId === id)) {
      return true
    }
    return false
  }

  getLabel() {
    const classInfo = classArray.filter(cls => cls.value === this.userClass)
    if (classInfo.length > 0) {
      return classInfo[0].label
    }
    return ''
  }

}

export function userSorter(a, b) {
  if (a.sorter1 === b.sorter1) {
    if (a.sorter2 === b.sorter2) {
      if (a.sorter3 === b.sorter3) {
        return 0
      }
      return a.sorter3 > b.sorter3 ? 1 : -1
    }
    return a.sorter2 > b.sorter2 ? 1 : -1
  }
  return a.sorter1 > b.sorter1 ? 1 : -1
}

const UserCsvColumns = [
  {key: 'state', header: '状態'},
  {key: 'sorter1', header: SorterColumnNames.sorter1},
  {key: 'sorter2', header: SorterColumnNames.sorter2},
  {key: 'sorter3', header: SorterColumnNames.sorter3},
  {key: 'email', header: 'メールアドレス'},
  {key: 'class', header: '権限'},
  {key: 'group', header: 'グループ'},
  {key: 'createdAt', header: 'ユーザー登録日時'},
]
const UserColumnNames = UserCsvColumns.reduce((obj, ele) => ({...obj, [ele.key]: ele.header}), {})
const UserCsvColumnLength = UserCsvColumns.length

const UserCsvStates = {
  add: '追加',
  modify: '変更',
  changeRole: '権限変更',
  removeGroup: 'グループ削除',
  delete: 'ユーザー削除',
}
const UserStateValues = Object.values(UserCsvStates)
const UserStateJoinedValue = UserStateValues.join(',')

export const notificationContentTypeOptions = [
  { value: 'SORTER1',  label: SorterColumnNames.sorter1 },
  { value: 'SORTER2', label: SorterColumnNames.sorter2 },
  { value: 'SORTER3', label: SorterColumnNames.sorter3 },
  { value: 'ANSWER', label: '回答内容' },
]

function Users(props) {
  const [sorted, setSorted] = React.useState([])
  const [isNewAdmin, setNewAdmin] = React.useState(false)
  const [isChanged, setChanged] = React.useState(false)
  const [isImporting, setImporting] = React.useState(false)
  const [isBlocked, setBlocked] = React.useState(false)
  const [showsDeleted, setShowsDeleted] = React.useState(false)
  const [updateMessage, setUpdateMessage] = React.useState('')
  const [confirmationMessage, setConfirmationMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const ref_new_email = React.useRef(null)
  const ref_new_sorter1 = React.useRef(null)
  const ref_new_sorter2 = React.useRef(null)
  const ref_new_sorter3 = React.useRef(null)
  const ref_new_class = React.useRef(UserClass.DefaultUserClass)
  const [newGroups, setNewGroups] = React.useState([])
  const [groupOptions, setGroupOptions] = React.useReducer((prev, next) => {
    // このページの処理でグループのIDと名前に変更が生じることはないので
    // 数が変わってなければ（実質、まだ空でなければ）これまでのものをそのまま流用して再描画を抑制する
    return prev.length === next.length ? prev : next
  }, [])
  React.useEffect(() => setGroupOptions(props.groups.map(group => ({label: group.name, value: group.id}))), [props.groups])
  const refs_users = React.useMemo(() => {
    return props.users.reduce((obj, key) => {
      const newObj = {...obj, [key.id]: {
        sorter1: React.createRef(),
        sorter2: React.createRef(),
        sorter3: React.createRef(),
        class: React.createRef(),
        groups: React.createRef()
      }}
      // <input>にrefとして与えているもの以外はここで値をセットする
      // refとして与えているものはここではなく<input>のdefaultValue等でセットする
      newObj[key.id].groups.current = groupOptions.filter(option => key.groups.items.some(group => group.groupId === option.value))
      return newObj
    }, {})
  }, [props.users, groupOptions])
  const ref_to_exec = React.useRef('')
  const ref_to_import = React.useRef({})
  const ref_to_process = React.useRef(null)
  const history = useHistory()
  const { readString } = usePapaParse()

  // 複雑なことをやっているが、新規と変更に対する後述の仕様を達成するための処理
  const [sorterState, sorterAction] = React.useReducer((state, action) => {
    if (action) {
      return true
    } else if (state) {
      const sortee = [...props.users]
      sortee.sort(userSorter)
      setSorted(sortee.map(user => user.id))
    } else if (sorted.length > 0) {
      // 変更では再訪問するまで位置は変わらない
      const newUserIds = props.users.map(user => user.id)
      const revised = sorted.filter(id => newUserIds.includes(id))
      // 新規は再訪問するまで上に積んでいく
      setSorted(newUserIds.filter(userId => !revised.includes(userId)).concat(revised))
    } else {
      setSorted(props.users.map(user => user.id))
    }
    return false
  }, false)

  React.useEffect(() => {
    sorterAction(true)
  }, [])

  React.useEffect(() => {
    if (props.users.length > 0) {
      sorterAction(false)
    }
  }, [props.users, sorterState])

  // 下のuseEffect等の警告避け
  // こういう風にしないとuseEffect等の依存する変数（最終引数の配列）の中にprops自体を含めないとならなくなり、イベント発生が多くなりすぎる
  const getGroups = props.getGroups
  const getUsers = props.getUsers

  // 未変更の修正がある場合のページ遷移するかどうかの確認
  // react-router v5にはあるがv6に（まだ）ない機能を使用しているので、当面react-routerをアップデートできない
  // history.blockが発動する度に「A history supports only one prompt at a time」という警告が出るが解決方法は不明
  // また、useHistoryが<Router>内でしか使えないのでこちらは各ページで処理を行っている
  const unblock = React.useMemo(() => history.block(isBlocked ? '更新を反映する前にページを離れようとしています。よろしいですか？' : true), [isBlocked, history])
  React.useEffect(() => () => unblock(), [unblock])

  // こちらは未変更の修正がある場合のページ再読み込みあるいはタブを閉じるかの確認
  React.useEffect(() => {
    window.onbeforeunload = isBlocked ? (e => e.returnValue = true) : null
  }, [isBlocked])

  function processUploadedUserFile(file) {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      readString(reader.result.trim(), {
        worker: true,
        header: true,
        complete: results => {
          processUploadedUser(results)
        },
      })
    }
    reader.onerror = event => catchError(event, 'ユーザー情報アップロード読み取り処理でエラー')
  }

  function processUploadedUser(csv) {
    try {
      _processUploadedUser(csv)
    } catch (err) {
      catchError(err, 'ユーザー情報アップロード処理でエラー')
    }
  }

  function _processUploadedUser(csv) {
    const headerRow = csv.meta.fields
    const rowCount = csv.data.length
    const errors = []
    if (headerRow.length === 0) {
      setErrorMessage('ファイルが空です。')
      return
    }
    if (rowCount === 0) {
      errors.push('ファイルに回答行がありません。')
    }
    const columnCount = headerRow.length
    const validColumns = validateImportingColumns(headerRow, columnCount, errors)
    if (validColumns.length === 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    const importedUsers = validateImportingUsers(csv, columnCount, validColumns, rowCount, errors)
    const mergedUsers = validateMergingUsers(importedUsers, errors)
    if (errors.length > 0) {
      setErrorMessage(errors.join('\n'))
      return
    }
    ref_to_import.current = mergedUsers
    ref_to_exec.current = execToImport
    setConfirmationMessage(`${mergedUsers.map(user => `${user.sorter1 ? `${user.sorter1} ` : ''}${user.sorter2 ? `${user.sorter2} ` : ''}${user.sorter3 ? `${user.sorter3}` : ''} (${user.email}) : ${getStateMessage(user)}`).join('\n')}`)
  }

  function getStateMessage(user) {
    if (user.creates) {
      return 'ユーザー作成'
    }
    if (user.restores) {
      return 'ユーザー復帰'
    }
    if (user.changesRole) {
      return '権限変更'
    }
    if (user.state === UserCsvStates.delete) {
      return '<warn>ユーザー削除</warn>'
    }
    if (user.modifies) {
      if (user.addingGroupIds.length > 0) {
        if (user.removingGroups.length > 0) {
          return '名前変更+グループ追加削除'
        }
        return '名前変更+グループ追加'
      }
      if (user.removingGroups.length > 0) {
        return '名前変更+グループ削除'
      }
      return '名前変更'
    }
    if (user.addingGroupIds.length > 0) {
      if (user.removingGroups.length > 0) {
        return 'グループ追加削除'
      }
      return 'グループ追加'
    } else if (user.removingGroups.length > 0) {
      return 'グループ削除'
    }
    return '変更なし'
  }

  function validateImportingColumns(headerRow, columnCount, errors) {
    const validColumns = []
    for (let i = 0; i < columnCount; i++) {
      if (i >= UserCsvColumnLength) {
        errors.push(`CSV内に余剰な${i+1}番目のカラム：${headerRow[i]} が存在します。`)
      } else if (headerRow[i] !== UserCsvColumns[i].header) {
        errors.push(`CSV内の${i+1}番目のカラム：${headerRow[i]} は ${UserCsvColumns[i].header} でなければなりません。`)
      } else {
        validColumns.push(i)
      }
    }
    // 「ユーザー登録日時」はなくても良い
    for (let i = columnCount; i < UserCsvColumnLength - 1; i++) {
      errors.push(`${UserCsvColumns[i].header} がCSVの${i+1}番目のカラムとして存在しません。`)
    }
    return validColumns
  }

  function validateImportingUsers(csv, columnCount, validColumns, rowCount, errors) {
    const groupNameId = props.groups.reduce((obj, ele) => ({...obj, [ele.name]: ele.id}), {})
    const importedUsers = {}
    for (let i = 0; i < rowCount; i++) {
      const userRow = csv.data[i]
      if (userRow.__parsed_extra) {
        errors.push(`${i+2}行目のカラム数が${columnCount + userRow.__parsed_extra.length}です。${columnCount}以下でなければなりません。`)
      }
      const rowData = {}
      for (const j of validColumns) {
        rowData[UserCsvColumns[j].key] = userRow[UserCsvColumns[j].header]
      }
      const hasValidEmail = validateRowEmail(rowData, i, errors)
      const classValue = validateRowClass(rowData, i, errors)
      const groupId = validateRowGroup(rowData, classValue, groupNameId, i, errors)
      validateRowOthers(rowData, i, errors)
      if (hasValidEmail) {
        if (importedUsers.hasOwnProperty(rowData.email)) {
          const userToUpdate = {...importedUsers[rowData.email]}
          validateComparingRowOthers(rowData, userToUpdate, classValue, i, errors)
          validateComparingRowState(rowData, userToUpdate, i, errors)
          validateComparingRowGroup(rowData, userToUpdate, classValue, groupId, i, errors)
          importedUsers[rowData.email] = userToUpdate
        } else {
          importedUsers[rowData.email] = {...rowData, addingGroupIds: [], removingGroupIds: []}
          importedUsers[rowData.email].class = classValue
          validateRowGroupByState(rowData, importedUsers[rowData.email], classValue, groupId, i, errors)
        }
      }
    }
    return importedUsers
  }

  function validateRowEmail(rowData, i, errors) {
    if (rowData.hasOwnProperty('email')) {
      if (!rowData.email) {
        errors.push(`${i+2}行目の${UserColumnNames.email}が空です。`)
      } else if (!isEmail(rowData.email)) {
        errors.push(`${i+2}行目の${UserColumnNames.email}は正しくありません。`)
      } else {
        return true
      }
    }
    return false
  }

  function validateRowClass(rowData, i, errors) {
    if (rowData.hasOwnProperty('class')) {
      const classData = classArray.filter(cls => cls.label === rowData.class)
      if (!rowData.class) {
        errors.push(`${i+2}行目の${UserColumnNames.class}が空です。`)
      } else if (classData.length === 0) {
        errors.push(`${i+2}行目の${UserColumnNames.class} ${rowData.class} は存在しません。`)
      } else {
        return classData[0].value
      }
    }
    return undefined
  }

  function validateRowGroup(rowData, classValue, groupNameId, i, errors) {
    if (rowData.hasOwnProperty('group')) {
      const userClass = new UserClass(classValue)
      if (rowData.group) {
        let hasError = false
        if (userClass && userClass.isAdmin()) {
          errors.push(`${i+2}行目の管理者ユーザーに${UserColumnNames.group}は設定できません。`)
          hasError = true
        }
        if (!groupNameId[rowData.group]) {
          errors.push(`${i+2}行目の${UserColumnNames.group} ${rowData.group} は存在しません。`)
          hasError = true
        }
        if (!hasError) {
          return groupNameId[rowData.group]
        }
      } else if (rowData.state !== UserCsvStates.delete && userClass && userClass.isAnswerer()) {
        errors.push(`${i+2}行目の回答者ユーザーの${UserColumnNames.group}が空です。`)
      }
    }
    return undefined
  }

  function validateRowOthers(rowData, i, errors) {
    if (rowData.hasOwnProperty('state') && !UserStateValues.includes(rowData.state)) {
      errors.push(`${i+2}行目の${UserColumnNames.state}の値は ${UserStateJoinedValue} のいずれかでなければなりません。`)
    }
    if (!rowData.sorter1 && !rowData.sorter2 && !rowData.sorter3) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter1}、${SorterColumnNames.sorter2}、${SorterColumnNames.sorter3}のいずれかに値を入力する必要があります。`)
    }
  }

  function validateComparingRowOthers(rowData, userToUpdate, classValue, i, errors) {
    if (rowData.hasOwnProperty('sorter1') && rowData.sorter1 !== userToUpdate.sorter1) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter1}がすでに指定された値と違います。`)
    }
    if (rowData.hasOwnProperty('sorter2') && rowData.sorter2 !== userToUpdate.sorter2) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter2}がすでに指定された値と違います。`)
    }
    if (rowData.hasOwnProperty('sorter3') && rowData.sorter3 !== userToUpdate.sorter3) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter3}がすでに指定された値と違います。`)
    }
    if (rowData.hasOwnProperty('class') && userToUpdate.class && classValue && classValue !== userToUpdate.class) {
      errors.push(`${i+2}行目の${UserColumnNames.class}がすでに指定された値と違います。`)
    }
  }

  function validateComparingRowState(rowData, userToUpdate, i, errors) {
    if (rowData.state && rowData.state !== userToUpdate.state) {
      if (userToUpdate.state === UserCsvStates.removeGroup
          && (rowData.state === UserCsvStates.add || rowData.state === UserCsvStates.modify)) {
        errors.push(`${i+2}行目の${UserColumnNames.state}の値 ${rowData.state} は同じユーザーに指定された ${userToUpdate.state} と一緒に使用できますが、先に ${rowData.state} を指定して下さい。`)
      } else if (!((userToUpdate.state === UserCsvStates.add || userToUpdate.state === UserCsvStates.modify)
          && rowData.state === UserCsvStates.removeGroup)) {
        errors.push(`${i+2}行目の${UserColumnNames.state}の値 ${rowData.state} は同じユーザーに指定された ${userToUpdate.state} と一緒に使用できません。`)
      }
    }
  }

  function validateComparingRowGroup(rowData, userToUpdate, classValue, groupId, i, errors) {
    if (rowData.hasOwnProperty('group')) {
      if (userToUpdate.addingGroupIds.includes(groupId) || userToUpdate.removingGroupIds.includes(groupId)) {
        errors.push(`${i+2}行目の${UserColumnNames.group}と同じ${UserColumnNames.group}がすでに同じユーザーに指定されています。`)
      } else if (userToUpdate.noGroup) {
        if (!!rowData.group) {
          errors.push(`${i+2}行目の空でない${UserColumnNames.group}がすでに空の${UserColumnNames.group}のユーザーに指定されています。`)
        } else {
          errors.push(`${i+2}行目の空の${UserColumnNames.group}は同じユーザーに2度指定することはできません。`)
        }
      } else if (!rowData.group) {
        errors.push(`${i+2}行目の空の${UserColumnNames.group}がすでに空でない${UserColumnNames.group}のユーザーに指定されています。`)
      }
      validateRowGroupByState(rowData, userToUpdate, classValue, groupId, i, errors)
    }
  }

  function validateRowGroupByState(rowData, userToUpdate, classValue, groupId, i, errors) {
    if (!rowData.hasOwnProperty('state') || !rowData.hasOwnProperty('group')) {
      return
    }
    const existingUser = props.users.filter(user => user.email === rowData.email)[0]
    const existingUserGroups = existingUser ? existingUser.groups.items.map(item => item.group.name) : []
    if (rowData.state === UserCsvStates.add) {
      validateRowValuesByAddState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors)
    } else if (rowData.state === UserCsvStates.changeRole) {
      validateRowValuesByChangeRoleState(rowData, userToUpdate, existingUser, classValue, groupId, i, errors)
    } else if (rowData.state === UserCsvStates.modify) {
      validateRowValuesByModifyState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors)
    } else if (rowData.state === UserCsvStates.delete) {
      validateRowValuesByDeleteState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, i, errors)
    } else if (rowData.state === UserCsvStates.removeGroup) {
      validateRowValuesByRemoveGroupState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors)
    }
  }

  function validateRowValuesByAddState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors) {
    validateRowValuesByState(rowData, false, true, true, existingUser, classValue, i, errors)
    if (groupId) {
      if (!existingUser || existingUser.deleted) {
        userToUpdate.discardsGroups = true
        userToUpdate.addingGroupIds.push(groupId)
      } else if (!existingUserGroups.includes(rowData.group)) {
        userToUpdate.addingGroupIds.push(groupId)
      }
    } else {
      userToUpdate.noGroup = true
      userToUpdate.discardsGroups = true
    }
  }

  function validateRowValuesByChangeRoleState(rowData, userToUpdate, existingUser, classValue, groupId, i, errors) {
    validateRowValuesByState(rowData, true, true, false, existingUser, classValue, i, errors)
    if (groupId) {
      userToUpdate.addingGroupIds.push(groupId)
    } else {
      userToUpdate.noGroup = true
    }
    userToUpdate.discardsGroups = true
  }

  function validateRowValuesByModifyState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors) {
    validateRowValuesByState(rowData, true, false, true, existingUser, classValue, i, errors)
    if (groupId) {
      if (!existingUserGroups.includes(rowData.group)) {
        userToUpdate.addingGroupIds.push(groupId)
      }
    } else {
      userToUpdate.noGroup = true
    }
  }

  function validateRowValuesByDeleteState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, i, errors) {
    validateRowValuesByState(rowData, true, true, true, existingUser, classValue, i, errors)
    if (rowData.group) {
      if (!existingUserGroups.includes(rowData.group)) {
        errors.push(`${i+2}行目の状態は${UserCsvStates.delete}ですが、その場合${UserColumnNames.group}は空欄か所属しているグループである必要があります。`)
      }
    } else {
      userToUpdate.noGroup = true
    }
    if (rowData.email === props.userInfo.email) {
      errors.push(`${i+2}行目の状態は${UserCsvStates.delete}ですが、その場合自分自身は指定できません。`)
    }
  }

  function validateRowValuesByRemoveGroupState(rowData, userToUpdate, existingUser, existingUserGroups, classValue, groupId, i, errors) {
    if (userToUpdate.state === UserCsvStates.modify) {
      // 「グループ削除」より前の行に「変更」がある場合は各名前はチェックしない
      validateRowValuesByState(rowData, true, false, true, existingUser, classValue, i, errors)
    } else {
      validateRowValuesByState(rowData, true, true, true, existingUser, classValue, i, errors)
    }
    if (rowData.group) {
      if (groupId) {
        if (!existingUserGroups.includes(rowData.group)) {
          errors.push(`${i+2}行目の所属していない${UserColumnNames.group}である ${rowData.group} を${UserCsvStates.removeGroup}の時に使用することはできません。`)
        } else if (!userToUpdate.removingGroupIds.includes(groupId)) {
          // addingGroupIdsと違って要素数でチェックしている箇所があるので重複は排除する
          userToUpdate.removingGroupIds.push(groupId)
        }
      }
    } else {
      userToUpdate.noGroup = true
      errors.push(`${i+2}行目の状態は${UserCsvStates.removeGroup}ですが、その場合${UserColumnNames.group}を指定する必要があります。`)
    }
  }

  function validateRowValuesByState(rowData, checksExisting, checksNames, checksClass, existingUser, classValue, i, errors) {
    if (checksExisting) {
      if (!existingUser) {
        errors.push(`${i+2}行目の${UserColumnNames.email}は存在しません。状態には${UserCsvStates.add}のみが使用できます。`)
      } else if (existingUser.deleted) {
        errors.push(`${i+2}行目の${UserColumnNames.email}は削除済みです。状態には${UserCsvStates.add}のみが使用できます。追加ではユーザーの復帰と所属${UserColumnNames.group}の指定を行うことができ、${SorterColumnNames.sorter1}、${SorterColumnNames.sorter2}、${SorterColumnNames.sorter3}、${UserColumnNames.class}の変更は後からしかできません。`)
      }
    }
    if (checksNames && existingUser && rowData.hasOwnProperty('sorter1') && rowData.sorter1 !== existingUser.sorter1) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter1}が既存の値と違います。変更する場合、状態には${UserCsvStates.modify}を使用して下さい。`)
    }
    if (checksNames && existingUser && rowData.hasOwnProperty('sorter2') && rowData.sorter2 !== existingUser.sorter2) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter2}が既存の値と違います。変更する場合、状態には${UserCsvStates.modify}を使用して下さい。`)
    }
    if (checksNames && existingUser && rowData.hasOwnProperty('sorter3') && rowData.sorter3 !== existingUser.sorter3) {
      errors.push(`${i+2}行目の${SorterColumnNames.sorter3}が既存の値と違います。変更する場合、状態には${UserCsvStates.modify}を使用して下さい。`)
    }
    if (checksClass && existingUser && rowData.hasOwnProperty('class') && classValue && classValue !== existingUser.class) {
      errors.push(`${i+2}行目の${UserColumnNames.class}が既存の値と違います。変更する場合、状態には${UserCsvStates.changeRole}を使用して下さい。`)
    }
  }

  function validateMergingUsers(importedUsers, errors) {
    const existingUserEmails = props.users.map(user => user.email)
    const mergingUsers = [...props.users]
    const mergedUsers = []
    for (const importedUser of Object.values(importedUsers)) {
      const existingUserIndex = existingUserEmails.indexOf(importedUser.email)
      if (existingUserIndex !== -1) {
        const curUser = mergingUsers[existingUserIndex]
        importedUser.id = curUser.id
        setRemovingGroups(curUser, importedUser, errors)
        setUpdatingOparation(curUser, importedUser)
        mergingUsers[existingUserIndex] = {...importedUser}
      } else {
        importedUser.creates = true
        mergingUsers.push({...importedUser})
      }
      mergedUsers.push({...importedUser})
    }
    // user.deletedは元々削除済みのユーザー、user.state === UserCsvStates.deleteは今回削除されるユーザー
    if (mergingUsers.filter(user => !user.deleted && user.state !== UserCsvStates.delete && user.class === 'ADMIN').length === 0) {
      errors.push('管理者ユーザーが1人もいません。')
    }
    return mergedUsers
  }

  function setRemovingGroups(curUser, importedUser, errors) {
    const curGroups = curUser.groups.items
    if (!importedUser.noGroup && importedUser.addingGroupIds.length === 0 && curGroups.length > 0 && curGroups.length === importedUser.removingGroupIds.length) {
      errors.push(`${importedUser.email}の所属${UserColumnNames.group}を1つも残らないようにすることはできません。`)
    } else if (importedUser.discardsGroups) {
      importedUser.removingGroups = curGroups.map(g => g.id)
    } else {
      importedUser.removingGroups = []
      for (const groupId of importedUser.removingGroupIds) {
        importedUser.removingGroups.push(curGroups.filter(g => g.groupId === groupId)[0].id)
      }
    }
  }

  function setUpdatingOparation(curUser, importedUser) {
    if (curUser.deleted) {
      importedUser.restores = true
    } else if (curUser.sorter1 !== (importedUser.sorter1 ? String(importedUser.sorter1) : '') ||
        curUser.sorter2 !== (importedUser.sorter2 ? String(importedUser.sorter2) : '') ||
        curUser.sorter3 !== (importedUser.sorter3 ? String(importedUser.sorter3) : '')) {
      importedUser.modifies = true
    } else if (curUser.class !== importedUser.class) {
      importedUser.changesRole = true
    }
  }

  async function execToImport() {
    await _execToImport().catch(err => catchError(err, 'ユーザー取り込み処理でエラー'))
  }

  async function _execToImport() {
    setImporting(true)
    setBlocked(true)
    setConfirmationMessage('')
    for (const user of ref_to_import.current) {
      if (user.creates) {
        await importNewUser(user)
      } else {
        if (user.restores) {
          await importModifiedUser(user, false)
        } else if (user.state === UserCsvStates.delete) {
          await importModifiedUser(user, true)
        } else if (user.changesRole || user.modifies) {
          await importModifiedUser(user)
        }
        await importModifiedUserGroups(user)
      }
    }
    setChanged(false)
    setBlocked(false)
    clearNewUser()
    getCurrent()
    setImporting(false)
  }

  async function importNewUser(user) {
    const input = {}
    input.email = user.email
    input.sorter1 = user.sorter1 ? user.sorter1 : ''
    input.sorter2 = user.sorter2 ? user.sorter2 : ''
    input.sorter3 = user.sorter3 ? user.sorter3 : ''
    input.class = user.class
    await API.graphql(graphqlOperation(createUser, {input: input})).then(async (res) => {
      for (const groupId of user.addingGroupIds) {
        await API.graphql(graphqlOperation(createMtMUserGroup, {input: {userId: res.data.createUser.id, groupId: groupId}})).catch(err => catchError(err, 'ユーザーグループ登録（取り込み）時にエラー'))
      }
    }).catch(err => catchError(err, 'ユーザー登録（取り込み）時にエラー'))
  }

  async function importModifiedUser(user, deleted) {
    const input = {}
    input.id = user.id
    input.sorter1 = user.sorter1 ? user.sorter1 : ''
    input.sorter2 = user.sorter2 ? user.sorter2 : ''
    input.sorter3 = user.sorter3 ? user.sorter3 : ''
    input.class = user.class
    if (typeof(deleted) === 'boolean') {
      input.deleted = deleted
    }
    await API.graphql(graphqlOperation(updateUser, {input: input})).catch(err => catchError(err, 'ユーザー更新（取り込み）時にエラー'))
  }

  async function importModifiedUserGroups(user) {
    for (const removing of user.removingGroups) {
      await API.graphql(graphqlOperation(deleteMtMUserGroup, {input: {id: removing}})).catch(err => catchError(err, 'ユーザーグループ削除（ユーザー取り込み更新）時にエラー'))
    }
    for (const groupId of user.addingGroupIds) {
      await API.graphql(graphqlOperation(createMtMUserGroup, {input: {userId: user.id, groupId: groupId}})).catch(err => catchError(err, 'ユーザーグループ登録（ユーザー取り込み更新）時にエラー'))
    }
  }

  async function downloadUsers() {
    await _downloadUsers().catch(err => catchError(err, 'ユーザー情報ダウンロード処理でエラー'))
  }

  async function _downloadUsers() {
    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')
    worksheet.columns = UserCsvColumns
    worksheet.addRows(getUsersCsvBody())
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), await workbook.csv.writeBuffer()],
      {type: "text/csv;charset=utf-8"})
    saveAs(blob, 'ユーザー.csv')
  }

  function getUsersCsvBody() {
    const body = []
    const users = [...props.users].sort(userSorter)
    for (const user of users) {
      const userClass = new UserClass(user)
      const base = {
        state: user.deleted ? UserCsvStates.delete : '',
        sorter1: user.sorter1,
        sorter2: user.sorter2,
        sorter3: user.sorter3,
        email: user.email,
        class: userClass.getLabel(),
        createdAt: moment(user.createdAt).format('YYYY/MM/DD HH:mm:ss'),
      }
      for (const group of user.groups.items) {
        body.push({...base, group: group.group.name})
      }
      if (user.groups.items.length === 0) {
        body.push(base)
      }
    }
    return body
  }

  function UserFile() {
    // 以下の変数名は変えられなさそうなので名前空間を分けるために独立した関数の中に置く
    const onDrop = React.useCallback(acceptedFiles => acceptedFiles.map(processUploadedUserFile), []);
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop, noClick: true, maxFiles: 1, disabled: isImporting })
    return (
      <div>
        <div className="user-file-download"><button className="admin-misc-button" onClick={downloadUsers} disabled={isImporting}>CSVダウンロード</button></div>
        <div className="user-file-upload-container">
          <div {...getRootProps({className: 'user-file-upload'})}>
            <input {...getInputProps()} />
            <button className="admin-misc-button" onClick={open} disabled={isImporting}>CSVファイル選択</button>
            {isImporting ? <span> 取り込み中です</span> : (isDragActive ? <span> </span> : <span> ここにCSVファイルをドロップできます</span>)}
          </div>
        </div>
      </div>
    )
  }

  async function update() {
    await _update().catch(err => catchError(err, 'ユーザー更新処理でエラー'))
  }

  async function _update() {
    setUpdateMessage('')
    const users = [...props.users]
    let adminIncluded = false
    for (const user of users) {
      if (!refs_users[user.id].sorter1.current.value && !refs_users[user.id].sorter2.current.value && !refs_users[user.id].sorter3.current.value) {
        setErrorMessage(`${SorterColumnNames.sorter1}、${SorterColumnNames.sorter2}、${SorterColumnNames.sorter3}のいずれかに値を入力する必要があります。`)
        return
      }
      const newUserClass = new UserClass(refs_users[user.id].class.current.value)
      if (newUserClass.isAnswerer() && refs_users[user.id].groups.current.length === 0) {
        setErrorMessage(`回答者は少なくとも1つの${UserColumnNames.group}に所属させて下さい。`)
        return
      }
      if (newUserClass.isAdmin()) {
        adminIncluded = true
      }
    }
    if (!adminIncluded) {
      setErrorMessage('少なくとも1人を管理者にして下さい。')
      return
    }
    setChanged(false)
    setBlocked(false)
    for (const user of users) {
      const newSorter1 = refs_users[user.id].sorter1.current.value
      const newSorter2 = refs_users[user.id].sorter2.current.value
      const newSorter3 = refs_users[user.id].sorter3.current.value
      const newClass = refs_users[user.id].class.current.value
      if (user.sorter1 !== newSorter1 || user.sorter2 !== newSorter2 || user.sorter3 !== newSorter3 || user.class !== newClass) {
        await API.graphql(graphqlOperation(updateUser, {input: {id: user.id, sorter1: newSorter1, sorter2: newSorter2, sorter3: newSorter3, class: newClass}})).catch(err => catchError(err, 'ユーザー更新時にエラー'))
      }
      const newUserClass = new UserClass(newClass)
      const selectedGroupIds = newUserClass.isAdmin() ? [] : refs_users[user.id].groups.current.map(group => group.value)
      const curGroups = user.groups.items
      const oldGroups = curGroups.filter(cGroup => !selectedGroupIds.some(sGroupId => sGroupId === cGroup.groupId))
      const newGroupIds = selectedGroupIds.filter(sGroupId => !curGroups.some(cGroup => sGroupId === cGroup.groupId))
      for (const oldGroup of oldGroups) {
        await API.graphql(graphqlOperation(deleteMtMUserGroup, {input: {id: oldGroup.id}})).catch(err => catchError(err, 'ユーザーグループ削除（更新）時にエラー'))
      }
      for (const newGroupId of newGroupIds) {
        await API.graphql(graphqlOperation(createMtMUserGroup, {input: {userId: user.id, groupId: newGroupId}})).catch(err => catchError(err, 'ユーザーグループ登録（更新）時にエラー'))
      }
    }
    getCurrent()
    setUpdateMessage('更新が完了しました')
  }

  function add() {
    try {
      _add()
    } catch (err) {
      catchError(err, 'ユーザー登録処理でエラー')
    }
  }

  function _add() {
    const newEmail = ref_new_email.current.value
    const newSorter1 = ref_new_sorter1.current.value
    const newSorter2 = ref_new_sorter2.current.value
    const newSorter3 = ref_new_sorter3.current.value
    const newClass = ref_new_class.current.value
    const newUserClass = new UserClass(newClass)
    if (!newEmail) {
      setErrorMessage(`${UserColumnNames.email}は空にできません。`)
      return
    } else if (!isEmail(newEmail)) {
      setErrorMessage(`${UserColumnNames.email} ${newEmail} は正しい${UserColumnNames.email}ではありません。`)
      return
    } else if (props.users.find(user => user.email === newEmail)) {
      setErrorMessage(`ユーザー ${newEmail} は既に登録されています。`)
      return
    } else if (!newSorter1 && !newSorter2 && !newSorter3) {
      setErrorMessage(`${SorterColumnNames.sorter1}、${SorterColumnNames.sorter2}、${SorterColumnNames.sorter3}のいずれかに値を入力する必要があります。`)
      return
    } else if (newUserClass.isAnswerer() && newGroups.length === 0) {
      setErrorMessage(`回答者は少なくとも1つの${UserColumnNames.group}に所属させて下さい。`)
      return
    }
    if (!isChanged) {
      setBlocked(false)
    }
    API.graphql(graphqlOperation(createUser, {input: {
      email: newEmail, sorter1: newSorter1, sorter2: newSorter2, sorter3: newSorter3, class: newClass
    }})).then(addUserGroups).catch(err => catchError(err, 'ユーザー登録時にエラー'))
  }

  async function addUserGroups(res) {
    try {
      const addedUser = res.data.createUser
      const addedUserClass = new UserClass(addedUser.class)
      const userGroups = addedUserClass.isAdmin() ? [] : newGroups.map(group => group.value)
      addedUser.groups = {items: userGroups.map(group => {return {group: group}})}
      for (const group of userGroups) {
        await API.graphql(graphqlOperation(createMtMUserGroup, {input: {userId: addedUser.id, groupId: group}})).catch(err => catchError(err, 'ユーザーグループ登録時にエラー'))
      }
      clearNewUser()
      getCurrent()
    } catch (err) {
      catchError(err, 'ユーザーグループ登録処理でエラー')
    }
  }

  function clearNewUser() {
    ref_new_email.current.value = ''
    ref_new_sorter1.current.value = ''
    ref_new_sorter2.current.value = ''
    ref_new_sorter3.current.value = ''
    ref_new_class.current.value = UserClass.DefaultUserClass
    setNewGroups([])
  }

  function changeNewUserClass() {
    const userClass = new UserClass(ref_new_class.current.value)
    setNewAdmin(userClass.isAdmin())
    setBlocked(true)
  }

  const lineAdded = React.useCallback(() => {
    setBlocked(true)
    setUpdateMessage('')
  }, [])

  const lineModified = React.useCallback(() => {
    setChanged(true)
    lineAdded()
  }, [lineAdded])

  const execToChangeClass = React.useCallback(async () => {
    try {
      const info = ref_to_process.current
      refs_users[info.id].class.current.value = info.newClass
      setConfirmationMessage('')
      lineModified()
    } catch (err) {
      catchError(err, 'ユーザー権限変更処理でエラー')
    }
  }, [lineModified, refs_users])

  const setOriginalClass = React.useCallback((id, oriClass, newClass) => {
    ref_to_process.current = {id: id, oriClass: oriClass, newClass: newClass}
    ref_to_exec.current = execToChangeClass
    refs_users[id].class.current.value = oriClass
  }, [execToChangeClass, refs_users])

  const changeExistingUserClass = React.useCallback((id, email, oriClass) => {
    const oriUserClass = new UserClass(oriClass)
    const newClass = refs_users[id].class.current.value
    const newUserClass = new UserClass(newClass)
    if (newUserClass.isAdmin() && !oriUserClass.isAdmin()) {
      setOriginalClass(id, oriClass, newClass)
      setConfirmationMessage(`ユーザー ${email} を管理者にして良いですか？`)
    } else if (newUserClass.isAggregator() && !oriUserClass.canAggregate()) {
      setOriginalClass(id, oriClass, newClass)
      setConfirmationMessage(`ユーザー ${email} を集計者にして良いですか？`)
    } else if (newUserClass.isViewer() && !oriUserClass.isStaff()) {
      setOriginalClass(id, oriClass, newClass)
      setConfirmationMessage(`ユーザー ${email} を閲覧者にして良いですか？`)
    } else {
      lineModified()
    }
  }, [setOriginalClass, lineModified, refs_users])

  const changeSelectedNewGroups = event => {
    setNewGroups(event)
    lineAdded()
  }

  const getCurrent = React.useCallback(() => {
    getGroups([])
    getUsers([])
  }, [getGroups, getUsers])

  const execToRemove = React.useCallback(async () => {
    try {
      setChanged(false)
      setBlocked(false)
      const id = ref_to_process.current
      setConfirmationMessage('')
      await listEmailUser(id).then(async (emails) => {
        for (const email of Object.values(emails)) {
          await API.graphql(graphqlOperation(deleteEnqueteEmailUser, {input: {id: email.id}})).catch(err => catchError(err, '送信先ユーザー削除（ユーザー削除）時にエラー'))
        }
      })
      await API.graphql(graphqlOperation(updateUser, {input: {id: id, deleted: true}})).catch(err => catchError(err, 'ユーザー削除時にエラー'))
      getCurrent()
    } catch (err) {
      catchError(err, 'ユーザー削除処理でエラー')
    }
  }, [getCurrent])

  const execToRestore = React.useCallback(async () => {
    try {
      setChanged(false)
      setBlocked(false)
      const id = ref_to_process.current
      setConfirmationMessage('')
      await API.graphql(graphqlOperation(updateUser, {input: {id: id, deleted: false}})).catch(err => catchError(err, 'ユーザー復帰時にエラー'))
      getCurrent()
    } catch (err) {
      catchError(err, 'ユーザー復帰処理でエラー')
    }
  }, [getCurrent])

  const remove = React.useCallback((id, email, restore) => {
    ref_to_process.current = id
    ref_to_exec.current = restore ? execToRestore : execToRemove
    setConfirmationMessage(`ユーザー ${email} を${restore ? '復帰させて': '削除して'}良いですか？`)
  }, [execToRemove, execToRestore])

  function toggleShowsDeleted() {
    setShowsDeleted(!showsDeleted)
  }

  function closeConfirmationPopup() {
    setConfirmationMessage('')
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  // メモ化された関数の中ではuseStateは使用できないので独立した関数とする
  const UserLineGroups = props => {
    const [selectedGroups, setSelectedGroups] = React.useState(refs_users[props.id].groups.current)
    const changeSelectedGroups = event => {
      refs_users[props.id].groups.current = event
      setSelectedGroups(event)
      lineModified()
    }
    return (
      <MultiSelect overrideStrings={multiSelectI18n} options={groupOptions} value={selectedGroups} onChange={changeSelectedGroups} />
    )
  }

  // onChangeイベントでフォーカスを失わないようにするためにはuseCallbackを使ってメモ化する必要がある
  // 依存する変数（第2引数の[]に指定する変数）もuseCallbackやuseMemoでメモ化する
  const UserLine = React.useCallback(props => {
    const user = props.users.filter(user => user.id === props.id)[0]
    const userClass = new UserClass(user.class)
    const trClassName = !showsDeleted && user.deleted ? 'display-none' : ''
    const lineButtonClassName = user.deleted ? 'admin-line-button-secondary' : 'admin-line-button'
    const sorter1 = refs_users[props.id].sorter1.current ? refs_users[props.id].sorter1.current.value : user.sorter1
    const sorter2 = refs_users[props.id].sorter2.current ? refs_users[props.id].sorter2.current.value : user.sorter2
    const sorter3 = refs_users[props.id].sorter3.current ? refs_users[props.id].sorter3.current.value : user.sorter3
    const classVal = refs_users[props.id].class.current ? refs_users[props.id].class.current.value : user.class

    return (
      <tr className={trClassName}>
        <td>
          {props.users.length > 1 && props.userInfo.id !== props.id &&
          <button className={lineButtonClassName} onClick={() => remove(props.id, user.email, user.deleted)}>{user.deleted ? '復帰' : '削除'}</button>
          }
        </td>
        <td><input className="admin-users-sorter" type="text" defaultValue={sorter1} onChange={lineModified} ref={refs_users[props.id].sorter1} /></td>
        <td><input className="admin-users-sorter" type="text" defaultValue={sorter2} onChange={lineModified} ref={refs_users[props.id].sorter2} /></td>
        <td><input className="admin-users-sorter" type="text" defaultValue={sorter3} onChange={lineModified} ref={refs_users[props.id].sorter3} /></td>
        <td>{user.email}</td>
        <td>
          <select defaultValue={classVal} onChange={() => changeExistingUserClass(props.id, user.email, user.class)} ref={refs_users[props.id].class}>
            { classArray.map(cl => <option key={cl.value} value={cl.value}>{cl.label}</option>) }
          </select>
        </td>
        <td>
          { !userClass.isAdmin() &&
          <UserLineGroups id={props.id} user={user} />
          }
        </td>
      </tr>
    )
  }, [remove, lineModified, changeExistingUserClass, refs_users, showsDeleted])

  return (
    <div>
      <h1>ユーザー管理</h1>
      <p><button className="admin-update" disabled={!isChanged} onClick={update}>変更反映</button> {updateMessage}</p>
      <UserFile />
      <div><input type="checkbox" name={'show_deleted'} value={showsDeleted} onChange={() => toggleShowsDeleted()} /> 削除済みユーザーも画面に表示する</div>
      <div className="admin-table-outer">
        <table className="admin-table">
          <tbody>
          <tr>
            <th></th>
            <th>{SorterColumnNames.sorter1}</th>
            <th>{SorterColumnNames.sorter2}</th>
            <th>{SorterColumnNames.sorter3}</th>
            <th>{UserColumnNames.email}</th>
            <th>{UserColumnNames.class}</th>
            <th>{UserColumnNames.group}</th>
          </tr>
          <tr>
            <td className="admin-move"><button className="admin-line-button" onClick={add}>追加</button></td>
            <td><input className="admin-users-sorter" type="text" ref={ref_new_sorter1} onChange={lineAdded} /></td>
            <td><input className="admin-users-sorter" type="text" ref={ref_new_sorter2} onChange={lineAdded} /></td>
            <td><input className="admin-users-sorter" type="text" ref={ref_new_sorter3} onChange={lineAdded} /></td>
            <td><input type="email" ref={ref_new_email} onChange={lineAdded} /></td>
            <td>
              <select defaultValue={UserClass.DefaultUserClass} ref={ref_new_class} onChange={changeNewUserClass}>
                { classArray.map(cl => <option key={cl.value} value={cl.value}>{cl.label}</option>) }
              </select>
            </td>
            <td>
              { !isNewAdmin &&
              <MultiSelect overrideStrings={multiSelectI18n} options={groupOptions} value={newGroups} onChange={changeSelectedNewGroups} />
              }
            </td>
          </tr>
          {sorted.map(userId =>
            <UserLine key={userId} userInfo={props.userInfo} users={props.users} id={userId} />
          )}
          </tbody>
        </table>
      </div>
      <ConfirmationPopup message={confirmationMessage} exec={ref_to_exec.current} closePopup={closeConfirmationPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} />
    </div>
  )
}

export default Users
