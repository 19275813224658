import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  createEnqueteAnswer,
  deleteEnqueteAnswer,
} from '../graphql/mutations'
import {
  listEnqueteAnswerByUpdatedAt,
  listEnqueteQuestionByQuestion,
  listEnqueteUserDeadlines,
  listEnqueteUserAnswereds,
  listEmailUsersByEnquete,
  listEmailUsersByUser,
  listEmailSentByEnquete,
} from '../graphql/queries'

// 共通部として括り出すのに他と同じようにappからpropsで貰って実行するとuseEffect内だからか重い（重かった。今もそうかどうか？）のでここに置く
export async function getQuestions(enqueteId, nextToken) {
  if (!enqueteId) {
    return []
  }
  const questions = []
  await API.graphql(graphqlOperation(listEnqueteQuestionByQuestion, {enqueteId: enqueteId, sortDirection: 'ASC', nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEnqueteQuestionByQuestion
    Array.prototype.push.apply(questions, data.items)
    if (data.nextToken) {
      await getQuestions(enqueteId, data.nextToken).then(nextQuestions => Array.prototype.push.apply(questions, nextQuestions))
    }
  })
  return questions
}

export async function getArrangedQuestions(enqueteId) {
  return await getQuestions(enqueteId).then(arrangeQuestions)
}

export function arrangeQuestions(items) {
  return {items: items, arranged: arrangeQs(items)}
}

function arrangeQs(items) {
  const arranged = []
  items.forEach(question => {
    question.children = []
    if (!question.parentId) {
      arranged.push(question)
    } else {
      const parent = getParent(arranged, question, null)
      // 過去のバグで親のいない選択肢ができる場合があるのでここで排除
      if (parent && parent.children) {
        parent.children.push(question)
      }
    }
  })
  return arranged
}

function getParent(arranged, question, parent) {
  if (parent || arranged.length === 0) {
    return parent
  }
  const parents = arranged.filter(p => p.id === question.parentId)
  if (parents.length > 0) {
    return parents[0]
  }
  return arranged.reduce((obj, key) => getParent(key.children, question, obj), parent)
}

export async function getArrangedAnswers(userIds, enqueteIds) {
  return await getAnswers(userIds).then(answers => arrangeAnswers(answers, enqueteIds))
}

export async function getAnswers(userIds, nextToken) {
  if (userIds.length === 0) {
    return []
  }
  const answers = []
  const userId = userIds[0]
  await API.graphql(graphqlOperation(listEnqueteAnswerByUpdatedAt, {userId: userId, sortDirection: 'ASC', nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEnqueteAnswerByUpdatedAt
    Array.prototype.push.apply(answers, data.items)
    if (data.nextToken) {
      await getAnswers(userIds.slice(0, 1), data.nextToken).then(nextAnswers => Array.prototype.push.apply(answers, nextAnswers))
    }
    if (userIds.length > 1) {
      await getAnswers(userIds.slice(1)).then(nextAnswers => Array.prototype.push.apply(answers, nextAnswers))
    }
  })
  return answers
}

function arrangeAnswers(answers, enqueteIds) {
  const arranged = {}
  answers.forEach(answer => {
    if (!enqueteIds.includes(answer.enqueteQuestion.enqueteId)) {
      return
    }
    if (!arranged[answer.enqueteQuestion.enqueteId]) {
      arranged[answer.enqueteQuestion.enqueteId] = {}
    }
    if (!arranged[answer.enqueteQuestion.enqueteId][answer.userId]) {
      arranged[answer.enqueteQuestion.enqueteId][answer.userId] = {}
    }
    arranged[answer.enqueteQuestion.enqueteId][answer.userId][answer.enqueteQuestionId] = answer
  })
  return arranged
}

export async function getUserDeadlines() {
  return await getUserDeadlinesByFilter({})
}

export async function getUserDeadlinesByUser(userId) {
  return await getUserDeadlinesByFilter({filter: {userId: {eq: userId}}})
}

async function getUserDeadlinesByFilter(filter, nextToken) {
  const deadlines = {}
  await API.graphql(graphqlOperation(listEnqueteUserDeadlines, {...filter, nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEnqueteUserDeadlines
    data.items.forEach(deadline => {
      if (!deadlines.hasOwnProperty(deadline.enqueteId)) {
        deadlines[deadline.enqueteId] = {}
      }
      deadlines[deadline.enqueteId][deadline.userId] = deadline
    })
    if (data.nextToken) {
      await getUserDeadlinesByFilter(filter, data.nextToken).then(nextDeadlines => {
        Object.keys(nextDeadlines).forEach(enqueteId => {
          if (!deadlines.hasOwnProperty(enqueteId)) {
            deadlines[enqueteId] = {}
          }
          Object.assign(deadlines[enqueteId], nextDeadlines[enqueteId])
        })
      })
    }
  })
  return deadlines
}

export async function getAnsweredDatetimesByEnquete(enqueteId) {
  return await getAnsweredDatetimesByFilter({filter: {enqueteId: {eq: enqueteId}}})
}

async function getAnsweredDatetimesByFilter(filter, nextToken) {
  const datetimes = {}
  await API.graphql(graphqlOperation(listEnqueteUserAnswereds, {...filter, nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEnqueteUserAnswereds
    data.items.forEach(answered => {
      if (!datetimes.hasOwnProperty(answered.enqueteId)) {
        datetimes[answered.enqueteId] = {}
      }
      datetimes[answered.enqueteId][answered.userId] = answered
    })
    if (data.nextToken) {
      await getAnsweredDatetimesByFilter(filter, data.nextToken).then(nextDatetimes => {
        Object.keys(nextDatetimes).forEach(enqueteId => {
          if (!datetimes.hasOwnProperty(enqueteId)) {
            datetimes[enqueteId] = {}
          }
          Object.assign(datetimes[enqueteId], nextDatetimes[enqueteId])
        })
      })
    }
  })
  return datetimes
}

export async function registerAnswers(enqueteId, userId, curAnswers, newAnswers) {
  if (curAnswers[enqueteId] && curAnswers[enqueteId][userId]) {
    const oldAnswers = {...curAnswers[enqueteId][userId]}
    Object.values(oldAnswers).forEach(answer => deleteAnswer(answer))
  }
  if (!curAnswers[enqueteId]) {
    curAnswers[enqueteId] = {}
  }
  curAnswers[enqueteId][userId] = {}
  for (const answer of newAnswers) {
    await createAnswer(curAnswers, enqueteId, userId, answer)
  }
}

function deleteAnswer(answer) {
  API.graphql(graphqlOperation(deleteEnqueteAnswer, {input: {id: answer.id}}))
}

async function createAnswer(curAnswers, enqueteId, userId, answer) {
  await API.graphql(graphqlOperation(createEnqueteAnswer, {input: {
    userId: userId, enqueteId: enqueteId, enqueteQuestionId: answer.question, answer: answer.answer
  }})).then(res => {
    const addedAnswer = res.data.createEnqueteAnswer
    curAnswers[enqueteId][userId][addedAnswer.enqueteQuestionId] = addedAnswer
  })
}

export async function listEmailUsers(enqueteId, nextToken) {
  if (!enqueteId) {
    return []
  }
  const emails = []
  await API.graphql(graphqlOperation(listEmailUsersByEnquete, {enqueteId: enqueteId, nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEmailUsersByEnquete
    Array.prototype.push.apply(emails, data.items)
    if (data.nextToken) {
      await listEmailUsers(enqueteId, data.nextToken).then(nextEmails => Array.prototype.push.apply(emails, nextEmails))
    }
  })
  return emails
}

export async function listEmailUser(userId, nextToken) {
  const emails = []
  await API.graphql(graphqlOperation(listEmailUsersByUser, {userId: userId, nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEmailUsersByUser
    Array.prototype.push.apply(emails, data.items)
    if (data.nextToken) {
      await listEmailUser(userId, data.nextToken).then(nextEmails => Array.prototype.push.apply(emails, nextEmails))
    }
  })
  return emails
}

export async function listEmailSent(enqueteId, nextToken) {
  if (!enqueteId) {
    return []
  }
  const emails = []
  await API.graphql(graphqlOperation(listEmailSentByEnquete, {enqueteId: enqueteId, nextToken: nextToken})).then(async (res) => {
    const data = res.data.listEmailSentByEnquete
    Array.prototype.push.apply(emails, data.items)
    if (data.nextToken) {
      await listEmailSent(enqueteId, data.nextToken).then(nextEmails => Array.prototype.push.apply(emails, nextEmails))
    }
  })
  return emails
}
