import './App.css'
import AddIcon from './images/add.png'
import UpIcon from './images/up.png'
import DownIcon from './images/down.png'
import EditIcon from './images/edit.png'
import RemoveIcon from './images/remove.png'
import React from 'react'
import EditPopup from './EditPopup'
import ErrorPopup from './ErrorPopup'
import deepcopy from 'lodash/cloneDeep'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  Link,
  useHistory,
} from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import {
  listUserDeadlinesByEnqueteUser,
  listUserAnsweredByEnqueteUser,
} from './graphql/queries'
import {
  createEnqueteQuestion,
  deleteEnqueteQuestion,
  createEnqueteUserAnswered,
  updateEnqueteUserAnswered,
} from './graphql/mutations'
import {
  arrangeQuestions,
  getArrangedAnswers,
  getArrangedQuestions,
  registerAnswers,
} from './db/data'
import { UndoContext } from './App'
import { UserClass } from './Users'
import {
  getErrorMessage,
} from './util/common'

function Answer(props) {
  const [questions, setQuestions] = React.useState([])
  const [answers, setAnswers] = React.useState({})
  const [hasAnswers, setHasAnswers] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [necessity, setNecessity] = React.useState(false)
  const [userAnswerDeadline, setUserAnswerDeadline] = React.useState(null)
  const [answeredDatetimes, setAnsweredDatetimes] = React.useState(null)
  const [edit, setEdit] = React.useState(false)
  const [questionItem, setQuestionItem] = React.useState(false)
  const [saveMessage, setSaveMessage] = React.useState('')
  const [submitMessage, setSubmitMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errorId, setErrorId] = React.useState('')
  const history = useHistory()
  const {setUndo, setRedo} = React.useContext(UndoContext)
  const ref_mount = React.useRef(true)

  const refs_questions = React.useMemo(() => {
    if (!questions || !questions.items) {
      return {}
    }
    return questions.items.reduce((obj, key) => {
      if (!key.overview) {
        return obj
      }
      const newObj = {...obj, [key.id]: React.createRef()}
      // ラジオボタンやチェックボックスはinputタグのrefプロパティで制御できない（？）ので、初期値をセットしておく
      if (['RADIO', 'CHECKBOX'].includes(key.type)) {
        const questionAnswer = answers[key.enqueteId] && answers[key.enqueteId][props.userInfo.id] ? answers[key.enqueteId][props.userInfo.id][key.id] : null
        if (questionAnswer) {
          newObj[key.id].current = key.type === 'CHECKBOX' ? questionAnswer.answer.split('\n') : questionAnswer.answer
        }
      }
      return newObj
    }, {})
  }, [props.userInfo, questions, answers])

  const refs_anchors = React.useMemo(() => {
    if (!questions || !questions.items) {
      return {}
    }
    return questions.items.reduce((obj, key) => {
      if (!key.overview) {
        return obj
      }
      return {...obj, [key.id]: React.createRef()}
    }, {})
  }, [questions])

  React.useEffect(() => {
    async function getData() {
      if (!props.enquete.id) {
        return
      }
      async function getUserDeadline(enqueteId, userId) {
        if (!enqueteId || !userId) {
          return null
        }
        return await API.graphql(graphqlOperation(listUserDeadlinesByEnqueteUser, {
          enqueteId: enqueteId,
          userId: {eq: userId},
          limit: 1,
        })).then(res => {
          const items = res.data.listUserDeadlinesByEnqueteUser.items
          if (items.length > 0) {
            return items[0].answerDeadline
          }
          return null
        }).catch(err => catchError(err, 'ユーザー回答期限取得時にエラー'))
      }
      async function getAnsweredDatetime(enqueteId, userId) {
        if (!enqueteId || !userId) {
          return null
        }
        return await API.graphql(graphqlOperation(listUserAnsweredByEnqueteUser, {
          enqueteId: enqueteId,
          userId: {eq: userId},
          limit: 1,
        })).then(res => {
          const items = res.data.listUserAnsweredByEnqueteUser.items
          if (items.length > 0) {
            return items[0]
          }
          return null
        }).catch(err => catchError(err, 'ユーザー回答時刻取得時にエラー'))
      }
      // DBからの取得はそれなりに時間が掛かる処理なので、ここに到達するまでに
      // ページから離脱している（Answerコンポーネントの廃棄が行われている）可能性がある
      // ページ離脱後にsetXxx関数を呼ぶのはメモリリークとなりwarning対象になるので
      // ref_mountを使って呼ばれないようにしておく
      const userClass = new UserClass(props.userInfo)
      const enqueteIds = props.enquetes ? props.enquetes.map(enquete => enquete.id) : []
      if (userClass.isAnswerer()) {
        await getUserDeadline(props.enquete.id, props.userInfo.id).then(res => {
          if (ref_mount.current) {
            setUserAnswerDeadline(res)
          }
        }).catch(err => catchError(err, 'ユーザー回答期限取得時にエラー'))
        await getAnsweredDatetime(props.enquete.id, props.userInfo.id).then(res => {
          if (ref_mount.current) {
            setAnsweredDatetimes(res)
          }
        }).catch(err => catchError(err, 'ユーザー回答時刻取得時にエラー'))
      }
      if (userClass.isAnswerer() || userClass.isViewer()) {
        await getArrangedQuestions(props.enquete.id).then(res => {
          if (ref_mount.current) {
            setQuestions(res)
          }
        }).catch(err => catchError(err, 'アンケート質問取得時にエラー'))
      }
      if (userClass.isAnswerer()) {
        await getArrangedAnswers([props.userInfo.id], enqueteIds).then(res => {
          if (ref_mount.current) {
            setAnswers(res)
          }
        }).catch(err => catchError(err, 'ユーザー回答取得時にエラー'))
      }
    }
    getData().catch(err => catchError(err, 'ユーザー回答情報取得処理でエラー'))
  }, [props.userInfo, props.enquetes, props.enquete])

  React.useEffect(() => {
    if (new UserClass(props.userInfo).isStaff()) {
      setQuestions(props.questions)
    }
  }, [props.userInfo, props.questions])

  React.useEffect(() => {
    if (new UserClass(props.userInfo).isAdmin()) {
      setHasAnswers(props.enqueteAnswers && Object.keys(props.enqueteAnswers).length > 0)
    }
  }, [props.userInfo, props.enqueteAnswers])

  React.useEffect(() => {
    ref_mount.current = true
    return () => ref_mount.current = false
  }, [])

  React.useEffect(() => {
    setEdit(props.edit)
  }, [props.edit])

  React.useEffect(() => {
    setSubmitMessage('')
    setNecessity(false)
    window.scrollTo(0, 0)
  }, [props.enquete])

  function radioButtonOnChange(id, value) {
    // ラジオボタンはinputタグのrefプロパティで制御できない（？）ので、onChangeイベントでセットしておく
    refs_questions[id].current = value
  }

  function checkBoxOnChange(id, value, optionValues, event) {
    // チェックボックスも同様、inputタグのrefプロパティで制御できない（？）ので、onChangeイベントでセットしておく
    if (!refs_questions[id].current && event.target.checked) {
      refs_questions[id].current = [value]
      return
    }
    if (refs_questions[id].current.includes(value)) {
      if (!event.target.checked) {
        refs_questions[id].current = refs_questions[id].current.filter(o => o !== value)
      }
    } else if (event.target.checked) {
      const values = [...refs_questions[id].current, value]
      refs_questions[id].current = optionValues.filter(o => values.includes(o))
    }
  }

  async function downloadLinkOnClick(event) {
    await _downloadLinkOnClick(event).catch(err => catchError(err, 'アンケートイメージダウンロード処理でエラー'))
  }

  async function _downloadLinkOnClick(event) {
    event.preventDefault()
    const workbook = new ExcelJS.Workbook()
    workbook.addWorksheet('sheet1')
    const worksheet = workbook.getWorksheet('sheet1')
    const titleCell = worksheet.getCell('A1')
    titleCell.value = props.enquete.title
    const noticeCell = worksheet.getCell('A2')
    noticeCell.value = props.enquete.notice
    let row = 4
    questions.items.forEach(question => {
      if (question.type === 'HEADER') {
        const headerCell = worksheet.getCell(`C${row}`)
        headerCell.value = question.description
        row+=2
      } else if (question.type !== 'OPTION') {
        if (question.necessity) {
          const necessityCell = worksheet.getCell(`B${row}`)
          necessityCell.value = '＊必須'
        }
        const descriptionCell = worksheet.getCell(`C${row++}`)
        descriptionCell.value = question.description
        question.children.forEach(option => {
          const optionCell = worksheet.getCell(`D${row++}`)
          optionCell.value = option.description
        })
        row++
      }
    })
    const blob = new Blob([await workbook.xlsx.writeBuffer()], {type: "application/octet-binary"})
    saveAs(blob, `アンケート_${props.enquete.group.name}_${props.enquete.title}.xlsx`)
  }
  async function submit(notAnsweredEnqueteIds, event) {
    await _submit(notAnsweredEnqueteIds, event).catch(err => catchError(err, '回答送信時にエラー'))
  }

  async function _submit(notAnsweredEnqueteIds, event) {
    event.preventDefault()
    setNecessity(false)
    setLoading(true)
    setSubmitMessage('送信中です。')
    const newAnswers = []
    for (const [id, ref] of Object.entries(refs_questions)) {
      if (ref.current) {
        const value = ref.current.value ? ref.current.value : ref.current
        if (value) {
          if (typeof(value) === 'string') {
            newAnswers.push({question: id, answer: value})
            continue
          } else if (typeof(value) === 'object' && Array.isArray(value)) {
            const joinedValue = value.join('\n')
            if (joinedValue.length > 0) {
              newAnswers.push({question: id, answer: joinedValue})
              continue
            }
          }
        }
      }
      if (questions.items.some(question => question.id === id && question.necessity)) {
        setErrorMessage('未回答の回答必須の設問があります。ご入力をお願いいたします。')
        setSubmitMessage('')
        setErrorId(id)
        setNecessity(true)
        setLoading(false)
        return
      }
    }
    const curAnswers = deepcopy(answers)
    await registerAnswers(props.enquete.id, props.userInfo.id, curAnswers, newAnswers).catch(err => catchError(err, '回答更新時にエラー'))
    if (answeredDatetimes && answeredDatetimes.later) {
      // 現在laterは使用されていないのでここには来ない
      const now = moment().toISOString(false)
      await API.graphql(graphqlOperation(updateEnqueteUserAnswered, {input: {id: answeredDatetimes.id, later: false, createdAt: now, updatedAt: now}})).then(res => {
        setAnsweredDatetimes(res.data.updateEnqueteUserAnswered)
      }).catch(err => catchError(err, '回答情報初回更新時にエラー'))
    } else if (answeredDatetimes) {
      await API.graphql(graphqlOperation(updateEnqueteUserAnswered, {input: {id: answeredDatetimes.id}})).then(res => {
        setAnsweredDatetimes(res.data.updateEnqueteUserAnswered)
      }).catch(err => catchError(err, '回答情報更新時にエラー'))
    } else {
      await API.graphql(graphqlOperation(createEnqueteUserAnswered, {input: {enqueteId: props.enquete.id, later: false, userId: props.userInfo.id}})).then(res => {
        setAnsweredDatetimes(res.data.createEnqueteUserAnswered)
      }).catch(err => catchError(err, '回答情報登録時にエラー'))
    }
    API.put('enquetesfrom2021h1gateway', '/publish/answered', {
      body: {
        user: {
          sorter1: props.userInfo.sorter1,
          sorter2: props.userInfo.sorter2,
          sorter3: props.userInfo.sorter3,
        },
        enqueteTitle: props.enquete.title,
        enqueteId: props.enquete.id,
        groupId: props.enquete.groupId,
        answers: newAnswers.map(a => ({question: questions.items.filter(i => i.id === a.question)[0].overview, answer: a.answer})),
      },
    }).catch(err => catchError(err, '回答通知時にエラー'))
    setAnswers(curAnswers)
    setLoading(false)
    setSubmitMessage('<red>送信されました。アンケートへのご協力ありがとうございました。\n本アンケートに再度メールやアンケートサイトからご回答を送信されると、全てのご回答が上書きされますのでご了承ください。</red>')
    if (notAnsweredEnqueteIds.length > 0) {
      props.setEnquete(props.enquetes.filter(enquete => enquete.id === notAnsweredEnqueteIds[0])[0])
    }
  }

  function enterToEdit() {
    try {
      setUndo(null)
      setRedo(null)
      props.questionsHistory.undo.splice(0, props.questionsHistory.undo.length, deepcopy(questions.items))
      history.push('/edit')
    } catch (err) {
      catchError(err, 'アンケート編集移行処理でエラー')
    }
  }

  async function save() {
    await _save().catch(err => catchError(err, 'アンケート保存時にエラー'))
  }

  async function _save() {
    setLoading(true)
    setSaveMessage('保存中です')
    await deleteQuestions().catch(err => catchError(err, 'アンケート質問削除（更新）時にエラー'))
    await saveQuestions().catch(err => catchError(err, 'アンケート質問追加（更新）時にエラー'))
    setLoading(false)
    setSaveMessage('')
    initEdit()
    history.push('/answer')
  }

  async function saveQuestions() {
    const newQuestions = deepcopy(questions.items)
    const nextQuestions = []
    const idMap = {}
    let order = 0
    for (const question of newQuestions) {
      // 過去のバグで親のいない選択肢ができる場合があるのでここで排除
      if (question.type === 'OPTION' && !question.parentId) {
        continue
      }
      question.order = order++
      const oldId = question.id
      if (!question.id.includes('-')) {
        delete question.id
      }
      delete question.children
      delete question.createdAt
      delete question.updatedAt
      delete question.__typename
      if (question.parentId) {
        question.parentId = idMap[question.parentId]
      } else {
        delete question.parentId
      }
      if (!question.overview) {
        delete question.overview
      }
      await API.graphql(graphqlOperation(createEnqueteQuestion, {input: question})).then(res => {
        idMap[oldId] = res.data.createEnqueteQuestion.id
        nextQuestions.push(res.data.createEnqueteQuestion)
      }).catch(err => catchError(err, 'アンケート質問追加時にエラー'))
    }
    setQuestions(arrangeQuestions(nextQuestions))
  }

  async function deleteQuestions() {
    for (const question of props.questionsHistory.undo[0]) {
      await API.graphql(graphqlOperation(deleteEnqueteQuestion, {input: {id: question.id}})).catch(err => catchError(err, 'アンケート質問削除時にエラー'))
    }
  }

  function restore() {
    if (props.questionsHistory.undo.length > 0) {
      setItemsToQuestions(props.questionsHistory.undo[0])
    }
    initEdit()
    history.push('/answer')
  }

  function up(id, parentId) {
    move(id, parentId, true)
  }

  function down(id, parentId) {
    move(id, parentId, false)
  }

  function move(id, parentId, isUp) {
    const questionIds = questions.items.map(item => item.id)
    const movedIds = getMovedIds(getSiblingIds(parentId), id, isUp)
    if (movedIds.length === 0) {
      return
    }
    const nextIndex = movedIds.length === 2 ? questionIds.length : questionIds.indexOf(movedIds[2])
    const secondIndex = questionIds.indexOf(movedIds[0])
    const firstIndex = questionIds.indexOf(movedIds[1])
    const newItems = []
    for (let i = 0; i < secondIndex; i++) {
      newItems.push(questions.items[i])
    }
    for (let i = firstIndex; i < nextIndex; i++) {
      newItems.push(questions.items[i])
    }
    for (let i = secondIndex; i < firstIndex; i++) {
      newItems.push(questions.items[i])
    }
    for (let i = nextIndex; i < questionIds.length; i++) {
      newItems.push(questions.items[i])
    }
    applyEdit(newItems)
  }

  function getSiblingIds(parentId) {
    if (parentId === null) {
      return questions.arranged.map(item => item.id)
    }
      return questions.items.filter(item => item.parentId === parentId).map(item => item.id)
  }

  function getMovedIds(siblingIds, id, isUp) {
    const prevIndex = siblingIds.indexOf(id) - (isUp ? 1 : 0)
    if (prevIndex < 0 || prevIndex + 2 > siblingIds.length) {
      return []
    } else if (prevIndex + 2 === siblingIds.length) {
      return [siblingIds[prevIndex], siblingIds[prevIndex + 1]]
    }
    return [siblingIds[prevIndex], siblingIds[prevIndex + 1], siblingIds[prevIndex + 2]]
  }

  function remove(question) {
    try {
      const toRemove = [question]
      addChildrenToRemove(toRemove, question)
      const newItems = questions.items.filter(item => !toRemove.includes(item))
      applyEdit(newItems)
    } catch (err) {
      catchError(err, 'アンケート編集削除処理でエラー')
    }
  }

  function addChildrenToRemove(toRemove, question) {
    question.children.forEach(child => {
      toRemove.push(child)
      addChildrenToRemove(toRemove, child)
    })
  }

  function add(id, parentId, bottom) {
    // bottomプロパティがあるかどうかで新規かどうか判定する
    setQuestionItem({id: id, parentId: parentId, bottom: bottom})
  }

  function modify(item) {
    setQuestionItem(item)
  }

  function undo() {
    try {
      if (props.questionsHistory.undo.length < 2) {
        return
      }
      props.questionsHistory.redo.push(deepcopy(props.questionsHistory.undo.pop()))
      const newItems = props.questionsHistory.undo.slice(-1)[0]
      setItemsToQuestions(newItems)
      if (props.questionsHistory.undo.length < 2) {
        setUndo(null)
      }
      if (props.questionsHistory.redo.length === 1) {
        setRedo(() => redo)
      }
    } catch (err) {
      catchError(err, 'アンケート編集アンドゥ処理でエラー')
    }
  }

  function redo() {
    try {
      if (props.questionsHistory.redo.length === 0) {
        return
      }
      const newItems = props.questionsHistory.redo.pop()
      props.questionsHistory.undo.push(deepcopy(newItems))
      setItemsToQuestions(newItems)
      if (props.questionsHistory.redo.length === 0) {
        setRedo(null)
      }
      if (props.questionsHistory.undo.length === 2) {
        setUndo(() => undo)
      }
    } catch (err) {
      catchError(err, 'アンケート編集リドゥ処理でエラー')
    }
  }

  function applyEdit(newItems) {
    props.questionsHistory.redo.splice(0)
    props.questionsHistory.undo.push(newItems)
    setItemsToQuestions(newItems)
    setUndo(() => undo)
    setRedo(null)
  }

  function setItemsToQuestions(items) {
    // 次にアンケート詳細ページに行った時にDBから質問が取得し直されるように
    // keyForQuestionsに適当な文字列をセットする
    props.setKeyForQuestions('edit')
    props.setQuestions(arrangeQuestions(items))
  }

  function initEdit() {
    props.questionsHistory.undo.splice(0)
    props.questionsHistory.redo.splice(0)
  }

  function modifyQuestionItem(base, options) {
    try {
      _modifyQuestionItem(base, options)
    } catch (err) {
      catchError(err, 'アンケート編集保存処理でエラー')
    }
  }

  function _modifyQuestionItem(base, options) {
    const type = base.type.current && base.type.current.value ? base.type.current.value : 'HEADER'
    if (typeof(questionItem) === 'object') {
      const questionIds = questions.items.map(item => item.id)
      const id = questionItem.id
      let index = id === null ? questionIds.length : questionIds.indexOf(id)
      let replaceCount = 0
      let newId = id
      const parentId = questionItem.parentId
      if (questionItem.hasOwnProperty('bottom')) {
        if (id !== null && questionItem.bottom) {
          index = getNextIndex(id, index)
        }
        // 仮IDかどうか'-'を含まないことで判断するので含まないようにする
        newId = moment().valueOf().toString()
      } else if (type === 'HEADER') {
        // 子持ちのHEADERの場合、種類を変更不可。従ってHEADERの場合は常に変更対象は自分だけ
        replaceCount = 1
      } else {
        replaceCount = getNextIndex(id, index) - index
      }
      const newItems = deepcopy(questions.items)
      const created = createItem(base, options, type, newId, parentId)
      newItems.splice(index, replaceCount, created, ...created.children)
      applyEdit(newItems)
    }
    setQuestionItem(false)
  }

  function getNextIndex(id, idIndex) {
    const length = questions.items.length
    const parentIds = [id]
    for (let index = idIndex + 1; index < length; index++) {
      if (!parentIds.some(parentId => parentId === questions.items[index].parentId)) {
        return index
      }
      parentIds.push(questions.items[index].id)
    }
    return length
  }

  function createItem(base, options, type, id, parentId) {
    const item = {
      id: id,
      enqueteId: props.enquete.id,
      parentId: parentId,
      type: type,
      description: base.description.current.value,
      necessity: false,
      children: [],
    }
    if (type !== 'HEADER') {
      item.necessity = base.necessity.current.checked
      item.overview = base.overview.current.value
    }
    if (['RADIO', 'CHECKBOX'].includes(type)) {
      item.children = options.map(option => ({
        id: option.id,
        enqueteId: props.enquete.id,
        parentId: id,
        type: 'OPTION',
        description: option.ref.current.value,
        necessity: false,
        children: [],
      }))
    }
    return item
  }

  function closeEditPopup() {
    setQuestionItem(false)
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  function closeErrorPopup() {
    setErrorMessage('')
  }

  function scroll() {
    if (refs_anchors[errorId]) {
      refs_anchors[errorId].current.scrollIntoView()
    }
  }

  function clearEnquete() {
    props.setEnquete({})
  }

  function ProcessSubmitMessage(props) {
    if (!props.message) {
      return null
    }
    const redMatch = props.message.match(/<red>(.+?)<\/red>/s)
    if (redMatch) {
      return (
        <span className="color-red">{redMatch[1].split(/(\n)/).map((m, i) => {
          if (m === '\n') {
            return <React.Fragment key={i}><br /></React.Fragment>
          } else {
            return <React.Fragment key={i}>{m}</React.Fragment>
          }
        })}</span>
      )
    }
    return (
      <span>{props.message}</span>
    )
  }

  function QuestionRoute(props) {
    const first = props.index === 0 ? true : false
    const last = props.index === props.count - 1 ? true : false
    const depth = props.depth === 3 ? 3 : props.depth + 1
    switch (props.question.type) {
      // これ以外の種類を追加するかは要望次第
      case 'HEADER':
        return <QuestionHeader key={props.question.id} question={props.question} answers={props.answers} first={first} last={last} depth={depth} />
      case 'RADIO':
        return <QuestionRadioButton key={props.question.id} question={props.question} answers={props.answers} first={first} last={last} />
      case 'CHECKBOX':
        return <QuestionCheckBox key={props.question.id} question={props.question} answers={props.answers} first={first} last={last} />
      case 'TEXTAREA':
        return <QuestionTextarea key={props.question.id} question={props.question} answers={props.answers} first={first} last={last} depth={depth} />
      default:
        return null
    }
  }

  function QuestionHeader(props) {
    const childrenCount = props.question.children ? props.question.children.length : 0
    const className = `answer-header answer-depth-${props.depth}`
    return (
      <div>
        {edit &&
        <div className="edit-buttons">
          <img src={AddIcon} className="" alt="追加" onClick={e => add(props.question.id, props.question.parentId, false, e)} />
        </div>
        }
        <div className={className}>
          <div className="answer-item">
            <div className="answer-description">{props.question.description}</div>
            {edit &&
            <div className="edit-buttons">
              {!props.first && <img src={UpIcon} className="" alt="上へ" onClick={e => up(props.question.id, props.question.parentId, e)} />}
              {!props.last && <img src={DownIcon} className="" alt="下へ" onClick={e => down(props.question.id, props.question.parentId, e)} />}
              <img src={EditIcon} className="" alt="編集" onClick={e => modify(props.question, e)} />
              <img src={RemoveIcon} className="" alt="削除" onClick={e => remove(props.question, e)} />
            </div>
            }
          </div>
          {props.question.children &&
            props.question.children.map((question, index) => <QuestionRoute key={question.id} question={question} answers={props.answers} index={index} count={childrenCount} depth={props.depth} />)
          }
          {edit &&
          <div className="edit-buttons">
            <img src={AddIcon} className="" alt="追加" onClick={e => add(props.question.id, props.question.id, true, e)} />
          </div>
          }
        </div>
      </div>
    )
  }

  function QuestionRadioButton(props) {
    const value = refs_questions[props.question.id] && refs_questions[props.question.id].current ? refs_questions[props.question.id].current : null
    const notice = necessity && props.question.necessity && !value
    return (
      <div>
        {edit &&
        <div className="edit-buttons">
          <img src={AddIcon} className="" alt="追加" onClick={e => add(props.question.id, props.question.parentId, false, e)} />
        </div>
        }
        <div className="answer-radio">
          <div className="answer-scroll" ref={refs_anchors[props.question.id]} />
          <div className="answer-item">
            <div className="answer-description">{props.question.description} {props.question.necessity && <span className="color-red">＊必須</span>}</div>
            {edit &&
            <div className="edit-buttons">
              {!props.first && <img src={UpIcon} className="" alt="上へ" onClick={e => up(props.question.id, props.question.parentId, e)} />}
              {!props.last && <img src={DownIcon} className="" alt="下へ" onClick={e => down(props.question.id, props.question.parentId, e)} />}
              <img src={EditIcon} className="" alt="編集" onClick={e => modify(props.question, e)} />
              <img src={RemoveIcon} className="" alt="削除" onClick={e => remove(props.question, e)} />
            </div>
            }
          </div>
          {notice && props.question.children &&
            props.question.children.map(child => <div key={child.id}><label className="answer-necessity"><input type="radio" name={child.parentId} value={child.description} defaultChecked={value === child.description} onChange={() => radioButtonOnChange(props.question.id, child.description)} /><label>{child.description}</label></label></div>)
          }
          {!notice && props.question.children &&
            props.question.children.map(child => <div key={child.id}><label><input type="radio" name={child.parentId} value={child.description} defaultChecked={value === child.description} onChange={() => radioButtonOnChange(props.question.id, child.description)} /><label>{child.description}</label></label></div>)
          }
        </div>
      </div>
    )
  }

  function QuestionCheckBox(props) {
    const optionValues = props.question.children ? props.question.children.map(child => child.description) : []
    const value = refs_questions[props.question.id] && refs_questions[props.question.id].current ? refs_questions[props.question.id].current : []
    const notice = necessity && props.question.necessity && value.length === 0
    return (
      <div>
        {edit &&
        <div className="edit-buttons">
          <img src={AddIcon} className="" alt="追加" onClick={e => add(props.question.id, props.question.parentId, false, e)} />
        </div>
        }
        <div className="answer-radio">
          <div className="answer-scroll" ref={refs_anchors[props.question.id]} />
          <div className="answer-item">
            <div className="answer-description">{props.question.description} {props.question.necessity && <span className="color-red">＊必須</span>}</div>
            {edit &&
            <div className="edit-buttons">
              {!props.first && <img src={UpIcon} className="" alt="上へ" onClick={e => up(props.question.id, props.question.parentId, e)} />}
              {!props.last && <img src={DownIcon} className="" alt="下へ" onClick={e => down(props.question.id, props.question.parentId, e)} />}
              <img src={EditIcon} className="" alt="編集" onClick={e => modify(props.question, e)} />
              <img src={RemoveIcon} className="" alt="削除" onClick={e => remove(props.question, e)} />
            </div>
            }
          </div>
          {notice && props.question.children &&
            props.question.children.map(child => <div key={child.id}><label className="answer-necessity"><input type="checkbox" name={child.parentId} value={child.description} defaultChecked={value.includes(child.description)} onChange={e => checkBoxOnChange(props.question.id, child.description, optionValues, e)} /><label>{child.description}</label></label></div>)
          }
          {!notice && props.question.children &&
            props.question.children.map(child => <div key={child.id}><label><input type="checkbox" name={child.parentId} value={child.description} defaultChecked={value.includes(child.description)} onChange={e => checkBoxOnChange(props.question.id, child.description, optionValues, e)} /><label>{child.description}</label></label></div>)
          }
        </div>
      </div>
    )
  }

  function QuestionTextarea(props) {
    const value = refs_questions[props.question.id] && refs_questions[props.question.id].current && refs_questions[props.question.id].current.value != null ? refs_questions[props.question.id].current.value :
      (props.answers[props.question.id] && props.answers[props.question.id].answer ? props.answers[props.question.id].answer : '')
    const notice = necessity && props.question.necessity && !value
    const className = `answer-textarea answer-depth-${props.depth}`
    return (
      <div>
        {edit &&
        <div className="edit-buttons">
          <img src={AddIcon} className="" alt="追加" onClick={e => add(props.question.id, props.question.parentId, false, e)} />
        </div>
        }
        <div className={className}>
          <div className="answer-scroll" ref={refs_anchors[props.question.id]} />
          <div className="answer-item">
            <div className="answer-description">{props.question.description} {props.question.necessity && <span className="color-red">＊必須</span>}</div>
            {edit && <div className="edit-buttons">
              {!props.first && <img src={UpIcon} className="" alt="上へ" onClick={e => up(props.question.id, props.question.parentId, e)} />}
              {!props.last && <img src={DownIcon} className="" alt="下へ" onClick={e => down(props.question.id, props.question.parentId, e)} />}
              <img src={EditIcon} className="" alt="編集" onClick={e => modify(props.question, e)} />
              <img src={RemoveIcon} className="" alt="削除" onClick={e => remove(props.question, e)} />
            </div>
          }
          </div>
          {notice &&
            <TextareaAutosize className="answer-necessity" style={{ width: "90%" }} defaultValue={value} ref={refs_questions[props.question.id]} />
          }
          {!notice &&
            <TextareaAutosize style={{ width: "90%" }} defaultValue={value} ref={refs_questions[props.question.id]} />
          }
        </div>
      </div>
    )
  }

  const userClass = new UserClass(props.userInfo.class)
  const answerDeadline = userAnswerDeadline ? Date.parse(userAnswerDeadline) :
    (props.enquete.answerDeadline ? Date.parse(props.enquete.answerDeadline) : null)
  const userAnswers = userClass.isAnswerer() && answers[props.enquete.id] && answers[props.enquete.id][props.userInfo.id] ?
    answers[props.enquete.id][props.userInfo.id] : {}
  const arrangedCount = questions && questions.arranged ? questions.arranged.length : 0
  const now = new Date()
  const enqueteIdsToAnswer = props.enquetes ? props.enquetes.filter(enquete => Date.parse(enquete.answerDeadline) > now).map(enquete => enquete.id) : []
  const answeredEnqueteIds = Object.keys(answers)
  const notAnsweredEnqueteIds = enqueteIdsToAnswer.filter(id => !answeredEnqueteIds.includes(id) && id !== props.enquete.id)
  return (
    <div>
      {!props.enquete.title && <div>現在、回答をお願いするアンケートはございません。</div>}
      {props.enquete.title && <h1>{props.enquete.title}</h1>}
      {userClass.canAggregate() && questions.arranged && questions.arranged.length > 0 && <p><a href="#!" onClick={downloadLinkOnClick}>アンケートイメージのダウンロード</a></p>}
      {userClass.isAdmin() && !hasAnswers && !edit && <div><button onClick={enterToEdit}>アンケートの編集</button></div>}
      {userClass.isAdmin() && edit &&
      <div className="edit-modes">
        <button onClick={save} disabled={loading}>アンケートを保存して終了</button>
        <button onClick={restore} disabled={loading}>アンケートを編集前に戻して終了</button>
        {saveMessage}
      </div>
      }
      {userClass.canAggregate() && <p><Link to="/">アンケート詳細に戻る</Link></p>}
      {userClass.isAdmin() && questions.arranged && questions.arranged.length > 0 && <p><Link to="/additions">アンケート送信設定に戻る</Link></p>}
      {userClass.isStaff() && <p><Link to="/" onClick={clearEnquete}>アンケート一覧に戻る</Link></p>}
      {userClass.isAnswerer() && props.enquete.title && <p>最終回答送信日時： {answeredDatetimes ? moment(answeredDatetimes.updatedAt).format('YYYY/MM/DD HH:mm:ss') : '-'}</p>}
      {userClass.isAnswerer() && answerDeadline && answerDeadline > now && <p>回答締め切りは{moment(answerDeadline).format('YYYY/MM/DD HH:mm')}です。それまでは回答を修正して再送信も可能です。</p>}
      {userClass.isAnswerer() && answerDeadline && answerDeadline <= now && <p>回答期間は終了致しました。現在、閲覧のみ可能です。</p>}
      {props.enquete.notice && <p className="answer-notice">{props.enquete.notice}</p>}
      <form onSubmit={e => submit(notAnsweredEnqueteIds, e)}>
        {questions && questions.arranged && questions.arranged.map((question, index) => <QuestionRoute key={question.id} question={question} answers={userAnswers} index={index} count={arrangedCount} depth={0} />)}
        {edit &&
        <div className="edit-buttons">
          <img src={AddIcon} className="" alt="追加" onClick={e => add(null, null, true, e)} />
        </div>
        }
        {userClass.isAnswerer() && answerDeadline && answerDeadline > now &&
        <p>
          <button className="answer-submit" type="submit" disabled={loading}>
            {notAnsweredEnqueteIds.length > 0 &&
            <span>次のアンケートへ</span>
            }
            {notAnsweredEnqueteIds.length === 0 &&
            <span>送信</span>
            }
          </button>
          {submitMessage.split(/(<red>.+?<\/red>)/s).map((m, i) => <ProcessSubmitMessage key={i} message={m} />)}
        </p>
        }
      </form>
      {userClass.isAdmin() && questions.arranged && arrangedCount > 0 && !hasAnswers && !edit && <div><button onClick={enterToEdit}>アンケートの編集</button></div>}
      {userClass.isAdmin() && arrangedCount > 0 && edit &&
      <div className="edit-modes">
        <button onClick={save} disabled={loading}>アンケートを保存して終了</button>
        <button onClick={restore} disabled={loading}>アンケートを編集前に戻して終了</button>
        {saveMessage}
      </div>
      }
      {userClass.canAggregate() && arrangedCount > 0 && <p><Link to="/">アンケート詳細に戻る</Link></p>}
      <EditPopup open={questionItem} timestamp={moment().valueOf()} type={questionItem.type ? questionItem.type : 'HEADER'} exec={modifyQuestionItem} closePopup={closeEditPopup} />
      <ErrorPopup message={errorMessage} closePopup={closeErrorPopup} onAfterClose={scroll} />
    </div>
  )
}

export default Answer
