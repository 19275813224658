import React from 'react'
import Modal from 'react-modal'

const modalStyle = {
  overlay: {
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)"
  },
  content: {
    whiteSpace: 'pre-line',
    position: "absolute",
    top: "5rem",
    left: "5rem",
    right: "5rem",
    bottom: "5rem",
    backgroundColor: '#eeafee',
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

function ErrorPopup(props) {
  return (
    <Modal isOpen={!!props.message} onRequestClose={props.closePopup} onAfterClose={props.onAfterClose} ariaHideApp={false} style={modalStyle}>
      <div>{props.message}</div>
      <div>
        <button className="admin-popup-button" onClick={props.closePopup}>close</button>
      </div>
    </Modal>
  )
}

export default ErrorPopup
