import React from 'react'
import Modal from 'react-modal'
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'
import AddIcon from './images/add.png'
import RemoveIcon from './images/remove.png'
import {
  getErrorMessage,
} from './util/common'

const modalStyle = {
  overlay: {
    position: "fixed",
    zIndex: 2,
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,1,0,0.5)"
  },
  content: {
    whiteSpace: 'pre-line',
    position: "absolute",
    top: "20%",
    left: "5rem",
    right: "5rem",
    bottom: "20%",
    backgroundColor: '#effcfc',
    borderLeftColor: '#5bb7ae',
    borderLeftWidth: "8px",
    borderRadius: "0.25rem",
    padding: "1.5rem"
  }
}

function EditPopup(props) {
  const [type, setType] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  // refの変更を強制的に描画したい時に使うもの
  const [toggle, changeToggle] = React.useState(false)

  React.useEffect(() => {
    // props.timestampはないとprops.typeが前と変わらないと再描画されないので
    // 再描画を起こすためだけに使用され値自体は毎度変わるものなら何でも良い
    setType(props.type)
    setErrorMessage('')
  }, [props.timestamp, props.type])

  const refs = {
    type: React.useRef('HEADER'),
    description: React.useRef(''),
    necessity: React.useRef(false),
    overview: React.useRef(''),
  }

  const refs_options = React.useMemo(() => {
    const options = []
    if (props.open.children) {
      props.open.children.forEach(child => {
        const ref = React.createRef()
        ref.current = child.description
        options.push({id: child.id, ref: ref})
      })
    }
    return options
  }, [props.open])

  function changeType() {
    setType(refs.type.current.value)
    setErrorMessage('')
  }

  function addOption(index) {
    try {
      // 仮IDかどうか'-'を含まないことで判断するので含まないようにする
      refs_options.splice(index, 0, {id: moment().valueOf().toString(), ref: React.createRef()})
      changeToggle(!toggle)
    } catch (err) {
      catchError(err, 'アンケート編集オプション追加処理でエラー')
    }
  }

  function removeOption(index) {
    try {
      refs_options.splice(index, 1)
      changeToggle(!toggle)
    } catch (err) {
      catchError(err, 'アンケート編集オプション削除処理でエラー')
    }
  }

  function exec(event) {
    try {
      setErrorMessage('')
      // 「項目名」が存在していなければrefs.overview.currentもないのでチェック対象にならない
      if (refs.overview.current && !refs.overview.current.value) {
        setErrorMessage('項目名は空にできません。')
        return
      }
      props.exec(refs, refs_options, event)
    } catch (err) {
      catchError(err, 'アンケート編集保存処理でエラー')
    }
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  const description = refs.description.current && refs.description.current.value ? refs.description.current.value : (props.open.description ? props.open.description : '')
  const overview = refs.overview.current && refs.overview.current.value ? refs.overview.current.value : (props.open.overview ? props.open.overview : '')
  const necessity = refs.necessity.current && refs.necessity.current.value ? refs.necessity.current.value : (props.open.necessity ? props.open.necessity : false)

  return (
    <Modal isOpen={!!props.open} onRequestClose={props.closePopup} ariaHideApp={false} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false} style={modalStyle}>
      <table className="edit-table">
        <tbody>
          {(type !== 'HEADER' || !props.open.children || props.open.children.length === 0) &&
          <tr>
            <th>種類</th>
            <td>
              <select value={type} onChange={changeType} ref={refs.type}>
                <option key='HEADER' value='HEADER'>セクション</option>
                <option key='RADIO' value='RADIO'>ラジオボタン式設問</option>
                <option key='CHECKBOX' value='CHECKBOX'>チェックボックス式設問</option>
                <option key='TEXTAREA' value='TEXTAREA'>記述式設問</option>
              </select>
            </td>
          </tr>
          }
          <tr>
            <th>設問</th>
            <td className="edit-popup-text"><TextareaAutosize minRows={2} style={{ width: "100%" }} defaultValue={description} ref={refs.description} /></td>
          </tr>
          {type !== 'HEADER' &&
          <tr>
            <th>必須</th>
            <td><input type="checkbox" defaultChecked={necessity} ref={refs.necessity} /></td>
          </tr>
          }
          {type !== 'HEADER' &&
          <tr>
            <th>項目名</th>
            <td><input className="edit-popup-text" type="text" defaultValue={overview} ref={refs.overview} /></td>
          </tr>
          }
          {['RADIO', 'CHECKBOX'].includes(type) &&
          <tr>
            <th>選択肢</th>
            <td>
              {refs_options.map((option, index) =>
              <div key={option.id}>
                <img src={AddIcon} className="edit-table-icon" alt="追加" onClick={e => addOption(index, e)} />
                <br />
                <input className="edit-popup-text" type="text" defaultValue={option.ref.current && typeof(option.ref.current.value) === 'string' ? option.ref.current.value : option.ref.current} ref={option.ref} />
                <img src={RemoveIcon} className="edit-table-icon" alt="削除" onClick={e => removeOption(index, e)} />
              </div>
              )}
              <div><img src={AddIcon} className="edit-table-icon" alt="追加" onClick={e => addOption(refs_options.length, e)} /></div>
            </td>
          </tr>
          }
        </tbody>
      </table>
      <div className="">
        {errorMessage && <p className="color-red pre-wrap">{errorMessage}</p>}
        <button className="admin-popup-button" onClick={exec}>OK</button> <button className="admin-popup-button" onClick={props.closePopup}>止める</button>
      </div>
    </Modal>
  )
}

export default EditPopup
