import React from 'react'
import {
  API,
  graphqlOperation,
} from 'aws-amplify'
import {
  createGeneralSetting,
  updateGeneralSetting,
} from './graphql/mutations'
import {
  getErrorMessage,
} from './util/common'
import {
  isEmail,
  isURL,
} from './util/validator'

export const OUR_DOMAIN = '@simpline.co.jp'

function Settings(props) {
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState()
  const [errorMessage, setErrorMessage] = React.useState()

  const ref_notification_email = React.useRef('')
  const ref_notified_emails = React.useRef('')
  const ref_notified_chat = React.useRef('')
  const ref_notified_subject = React.useRef('')

  React.useEffect(() => {
    if (props.settings) {
      ref_notification_email.current.value = props.settings.notificationEmail
      ref_notified_emails.current.value = props.settings.notifiedEmails
      ref_notified_chat.current.value = props.settings.notifiedChatUrl
      ref_notified_subject.current.value = props.settings.notificationSubject
    }
  }, [props.settings])

  async function submit(event) {
    await _submit(event).catch(err => catchError(err, '全般設定更新処理でエラー'))
  }

  async function _submit(event) {
    event.preventDefault()
    setMessage('')
    setErrorMessage('')
    let message = ''
    const senderEmail = ref_notification_email.current.value
    if (senderEmail.length > 0 && !isEmail(senderEmail)) {
      message += `通知の送信元メールアドレス ${senderEmail} は正しいメールアドレスではありません。\n`
    } else if (!senderEmail.endsWith(OUR_DOMAIN)) {
      message += `通知の送信元メールアドレス ${senderEmail} は${OUR_DOMAIN}で終わる必要があります。\n`
    }
    const emails = ref_notified_emails.current.value.split(/ *, */).filter(a => a.length > 0)
    for (const email of emails) {
      if (!isEmail(email)) {
        message += `通知先メールアドレス ${email} は正しいメールアドレスではありません。\n`
      }
    }
    const chatURL = ref_notified_chat.current.value
    if (chatURL.length > 0) {
      if (!isURL(chatURL)) {
        message += `通知先Google Chat Webhook URL ${chatURL} は正しいURLではありません。\n`
      }
    }
    if (message.length > 0) {
      setErrorMessage(message)
      return
    }
    setLoading(true)
    if (props.settings) {
      API.graphql(graphqlOperation(updateGeneralSetting, {input: {id: props.settings.id, notificationEmail: senderEmail, notifiedEmails: ref_notified_emails.current.value, notifiedChatUrl: chatURL, notificationSubject: ref_notified_subject.current.value}})).then(res => {
        const updatedSettings = res.data.updateGeneralSetting
        props.setSettings(updatedSettings)
        setMessage('設定が変更されました。')
      }).catch(err => catchError(err, '全般設定更新時にエラー'))
    } else {
      await API.graphql(graphqlOperation(createGeneralSetting, {input: {notificationEmail: senderEmail, notifiedEmails: ref_notified_emails.current.value, notifiedChatUrl: chatURL, notificationSubject: ref_notified_subject.current.value}})).then(res => {
        const addedSettings = res.data.createGeneralSetting
        props.setSettings(addedSettings)
        setMessage('設定が変更されました。')
      }).catch(err => catchError(err, '全般設定登録時にエラー'))
    }
    setLoading(false)
  }

  function catchError(err, message) {
    console.error(err)
    setErrorMessage(getErrorMessage(err, message))
  }

  return (
    <div>
      <h1>全般設定</h1>
      <form onSubmit={submit}>
        <h3>通知設定</h3>
        <p>
          <span className="bold">通知の送信元メールアドレス</span><br />{OUR_DOMAIN}のメールアドレスのみ有効です<br />下の「通知先メールアドレス」と両方設定していないとメールでの通知は行われません<br />
          <input type="email" ref={ref_notification_email} />
        </p>
        <p>
          <span className="bold">通知先メールアドレス</span><br />,区切りで複数指定できます<br />
          <input type="text" className={'admin-long-input'} ref={ref_notified_emails} />
        </p>
        <p>
          <span className="bold">通知先Google Chat Webhook URL</span><br />
          <input type="url" className={'admin-long-input'} ref={ref_notified_chat} />
        </p>
        <p>
          <span className="bold">通知タイトル</span><br />
          <input type="text" className={'admin-long-input'} ref={ref_notified_subject} />
        </p>
        <p><button type="submit" disabled={loading}>適用</button></p>
      </form>
      {message && <p>{message}</p>}
      {errorMessage && <p className="color-red pre-wrap">{errorMessage}</p>}
    </div>
  )
}

export default Settings
