export function isDatetime(value) {
	return typeof(value) !== 'string'
}

export function isEmail(value) {
  return value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
}

export function isURL(value) {
  try {
    new URL(value)
  } catch (ex) {
    if (ex instanceof TypeError) {
      return false
    }
    throw ex
  }
  return true
}

